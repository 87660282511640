import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import "./Edit.scss";
import ReaisInput from "../../ReaisInput";

function VeiculosEditModal({ show, id, price, putPriceVeiculo }) {
    const [loading, setLoading] = useState(true)
    const [priceLocal, setPriceLocal] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState("");

    const handleChange = (value) => {
        setPriceLocal(value)
    }


    useEffect(() => {
        if (price && loading) {
            setPriceLocal(price)
            setLoading(false)
        }
    }, [price])

    useEffect(() => {
        console.log("[SELECTED_ID_PRICE]", id)
        console.log("[SELECTED_CURRENT_PRICE]", price)
    }, [id, price])

    const handleClose = () => {
        setShowModal(false);
    };


    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    return (
        <>

            <Modal show={showModal} >
                <Modal.Header >
                    <Modal.Title>Edição rápida</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>por quanto quer vender?</Form.Label>
                            <ReaisInput value={priceLocal} handleChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={() => { putPriceVeiculo(priceLocal, id, handleClose) }} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default VeiculosEditModal;
