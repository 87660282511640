import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';


// CSS
import "./NewBlindagens.scss";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

function NewBlindagens({ showBlindagens, handleAlert }) {

    const { token } = useAuth()
    const [showModalBlindagens, setShowModalBlindagens] = useState(false);

    const handleClose = () => {
        setShowModalBlindagens(false);
    };

    useEffect(() => {
        if (showBlindagens) {
            setShowModalBlindagens(true);
        }
    }, [showBlindagens])

    const [popupData, setPopupData] = useState({
        opcoes: '',
        atributo:  ''
    })


    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }

    const postBlindagens = async () => {
        const dataToSend = {
            ...popupData,
            opcoes: [popupData.opcoes],
            atributo: 'tipo_blindagem'
        };
       await api.post(`/api/atributos`, dataToSend, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    handleClose()
                }
            }
        })
    }

    return (
        <>

            <Modal show={showModalBlindagens} className="NewModelo">
                <Modal.Header >
                    <Modal.Title>Cadastro de Blindagens</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Nome da Blindagem</Form.Label>
                            <Form.Control value={popupData["opcoes"]} onChange={(e) => { handlePopupData(e.target.value, "opcoes") }} type="text" placeholder="Informe o tipo de blindagem" ></Form.Control>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={postBlindagens}>
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
            
        </>
    );
};

export default NewBlindagens;
