import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import api from "../../../services/apiIdz";
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"
import Searce from '../../../components/Estetica/Searce';
import Filter from '../../../components/Estetica/Filter';
import MarcasList from '../../../components/Estetica/MarcasList';
import ModalMarcasNew from '../../../components/Estetica/ModalMarcasNew';
import { useAuth } from '../../../context/useAuth';
import Alerts from '../../../components/Alerts';
import ModalDeleteMarca from '../../../components/Estetica/ModalDeleteMarca';

//utils
import { isNew } from '../../../utils/dates';

//styles
import './Marcas.scss';


//Imagens 
import logo from '../../Dashboard/img/logo-idzcar.png'

function EsteticaMarcas() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const navigate = useNavigate();

    const { token } = useAuth()

    const [showMarcas, setShowModalMarcas] = useState(false);
    const [showMarcasEdit, setShowModalMarcasEdit] = useState(false);
    const [showDelete, setShowModalMarcasDelete] = useState(false)

    const showModalMarcas = () => {
        setShowModalMarcas(true);
    };
    const closeModalMarcas = () => {
        setShowModalMarcas(false);
    };

    const showModalMarcasEdit = (id) => {
        setShowModalMarcasEdit(true);
        setSelectedId(id)
    };
    const closeModalMarcasEdit = () => {
        setShowModalMarcasEdit(false);
        setSelectedId(null)
    };

    const [selectedId, setSelectedId] = useState(null)

    const showModalMarcasDelete = (id) => {
        setShowModalMarcasDelete(true);
        setSelectedId(id)
    };
    const closeModalMarcasDelete = () => {
        setSelectedId(null)
        setShowModalMarcasDelete(false);
    };


    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const [marcas, setMarcas] = useState([])
    const [readyFilter, setReadyFilter] = useState(false)
    const getMarca = async () => {
        await api.get(`/api/marcas-estetica`).then(res => {
            if (res.status == 200) {
                console.log(res.data)
                setMarcas(res.data)
                setReadyFilter(true)
            }
        })
    }

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalMarcasDelete(false);
        getMarca()
    }

    useEffect(() => {
        getMarca()
    }, [])


    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])


    const handleFiltered = (rawData) => {

        console.log("before filter", rawData)

        let tmpMarcaList

        console.log("Status", status, typeof status)
        console.log("Search", search, typeof search)

        if (status != null && status != '') {
            if (status == "news") {
                console.log("FILTERING ONLY SEARCH WITH NEW MARCA")
                tmpMarcaList = rawData.filter(marca_d => marca_d.titulo.toLowerCase().includes(search) && isNew(marca_d))
            } else {
                console.log("FILTERING ONLY SEARCH AND STATUS CODE")
                tmpMarcaList = rawData.filter(marcas_d => marcas_d.titulo.toLowerCase().includes(search) && marcas_d.status == status)
            }
        } else {
            console.log("FILTERING ONLY SEARCH")
            tmpMarcaList = rawData.filter(marca_d => marca_d.titulo.toLowerCase().includes(search))
        }
        console.log("after filter", tmpMarcaList)

        setFiltered(tmpMarcaList)
    }

    useEffect(() => {
        // if (readyFilter) {
        handleFiltered(marcas)
        //}

    }, [search, status, readyFilter, marcas])

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Leads">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="tabler:user-star" ></Icon></i>
                                <h2>Marcas <br />
                                    <span>Desscrição</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableEstetica'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsEstetica'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={showModalMarcas}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listEstetica'>
                                        <Row>
                                            <MarcasList marcas={filtered} showModalMarcasDelete={showModalMarcasDelete} showModalMarcasEdit={showModalMarcasEdit} />
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>


                <ModalMarcasNew showMarcas={showMarcas} token={token} getMarca={getMarca} closeModalMarcas={closeModalMarcas} handleAlert={handleAlert} />
                <ModalDeleteMarca
                    deleteId={selectedId}
                    show={showDelete}
                    handleClose={closeModalMarcasDelete}
                    handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                    handleAlert={handleAlert}
                />
            </div>
        </>
    );
}
export default EsteticaMarcas;