import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';

// Estilos
import "./RegrasPreco.scss";


// Componentes
import IdzFileUpload from '../../Veiculos/idzFileUpload';
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';
import ReaisInput from '../../../components/ReaisInput';
import InputMask from 'react-input-mask';

function RegrasPreco({ show, RegraPrecoAlertas, closeModalRegraPreco, objCampos, selectedId, getRegraPreco, servicoId, handleEndRegraPreco, editingRegraPreco, selectedRegraPrecoId, getServico }) {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isRegraPreco, setIsRegraPreco] = useState(false);


    useEffect(() => {
        if (show) {
            setShowModal(true);
            console.log(show)
            handlePopupData(parseInt(servicoId), 'servico')
        }
    }, [show])

    const [optionsVariacao, setOptionsVariacao] = useState([])

    useEffect(() => {
        if (objCampos) {
            console.log("objCampos['opcionais']", objCampos["opcionais"])
            if (objCampos['opcionais']) {
                let aux = objCampos['opcionais'].map(op => {
                    return ({
                        value: op.id,
                        label: op.titulo
                    })
                })
                setOptionsVariacao(aux)
            }
        }
    }, [objCampos])


    const [popupData, setPopupData] = useState({
        servico: servicoId,
        opcional: null,
        tpveiculo: null,
        preco: '',
        precos: []
    });





    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    const clearData = () => {
        setPopupData({
            servico: servicoId,
            opcional: null,
            tpveiculo: null,
            preco: ''
        })
        setFields([
            { marca: null, preco: '' }
        ])
    }

    const handleClose = () => {
        setShowModal(false);
        closeModalRegraPreco()
        handleEndRegraPreco()
        clearData()
    };


    const RegrasPrecoToggle = () => {
        setIsRegraPreco(!isRegraPreco);
    };

    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }

    const handleImagem = (image) => {
        handlePopupData(image, 'imagem')
    }

    const postRegrasPreco = async () => {
        await api.post(`/api/opcional-preco`, popupData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                RegraPrecoAlertas("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    RegraPrecoAlertas("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    getServico()
                    handleClose()
                }
            }
        })
    }

    useEffect(() => {
        console.log(popupData)
    }, [popupData])

    const [tipoVeiculo, setTipoVeiculo] = useState([]);

    useEffect(() => {
        getTipoVeiculo();
    }, []);

    const getTipoVeiculo = async () => {
        try {
            const res = await api.get(`/api/tipo-veiculo`);
            console.log('Resposta da API tipo-veiculo:', res); // Log da resposta da API
            if (res.status === 200) {
                console.log("Tipo Veiculo: ", res.data)
                const tipoVeiculoFormatado = res.data.map(item => ({
                    label: item.titulo, // Ajuste para usar 'nome' ou outro campo adequado
                    value: item.id
                }));
                setTipoVeiculo(tipoVeiculoFormatado);
            }
        } catch (error) {
            console.error('Erro ao buscar tipos de veículo:', error);
        }
    };

    // useState()
    // useEffect(()=>{
    //     if(tipoVeiculo && optionsVariacao){
    //         console.log("Dropdowns TipoVeiculo e Options Variacao")
    //         console.log(tipoVeiculo,optionsVariacao)
    //         console.log(popupData)
    //         console.log(popupData['tpveiculo'],popupData['opcional'])
    //     }
    // },[tipoVeiculo,optionsVariacao,popupData])


    const getCurrentVariacaoRegraPreco = async () => {
        await api.get(`/api/opcional-preco/${selectedRegraPrecoId}`).then(r => {
            if (typeof r.data === 'string') {
                RegraPrecoAlertas("Campos inválidos", r.data, "danger");
            } else {
                if (r.status === 200) {
                    const precos = r.data.precos || [];
                    console.log("Retorno da api",precos)

                    let initialFields = [];

                    if (precos.length > 0) {
                        initialFields = precos.map(preco => ({
                            marca: preco.marca,
                            preco: preco.preco
                        }));
                    } else {
                        // Caso de precos vazio ou marca zero
                        initialFields = [{ marca: null, preco: '' }];
                    }

                    setPopupData({
                        servico: r.data['servico_id'],
                        tpveiculo: r.data['categoria_id'],
                        opcional: r.data['opcional_id'],
                        precos: r.data['precos'] || [],
                        status: r.data['status']
                    });
                    setFields(initialFields);
                }
            }
        });
    };

    const putRegrasPreco = async () => {


        // Verificar e preencher o campo `precos` antes de enviar
        const precosAtualizados = marcas.map(marca => {
            const precoAtual = popupData.precos.find(preco => preco.marca === marca.id);
            return {
                marca: marca.id,
                preco: precoAtual ? precoAtual.preco : ''
            };
        });

        const dadosParaEnviar = {
            ...popupData,
            precos: fields.map(field => ({
                marca: field.marca ? field.marca.value : 0,
                preco: field.preco
            }))
        };

        console.log("dadosParaEnviar",dadosParaEnviar);
        console.log("Popup Data",popupData)

        await api.put(`/api/opcional-preco/${selectedRegraPrecoId}`, popupData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                RegraPrecoAlertas("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    RegraPrecoAlertas("Sucesso", "Os dados foram editados com êxito.", "success")
                    getServico()
                    handleClose()
                }
            }
        })
    }

    useEffect(() => {
        if (editingRegraPreco) {
            getCurrentVariacaoRegraPreco();
        } else {
            clearData();
        }
    }, [editingRegraPreco]);


    // Marca

    const [marcas, setMarcas] = useState([])
    const getMarca = async () => {
        await api.get(`/api/marcas-estetica`).then(res => {
            if (res.status == 200) {
                console.log("marcas", res.data)
                setMarcas(res.data)
            }
        })
    }

    useEffect(() => {
        getMarca()
    }, []);

    const handleDropdownChange = (index, selectedOption) => {
        const newFields = [...fields];
        newFields[index].marca = selectedOption;

        // Atualizar o estado do popupData também
        setPopupData(prevData => {
            const precosAtualizados = prevData.precos.map(preco =>
                preco.marca === (newFields[index].marca ? newFields[index].marca.value : 0) ? { ...preco, marca: selectedOption.value } : preco
            );

            // Se o preço da marca não estiver na lista, adicione-o
            if (!precosAtualizados.find(preco => preco.marca === (newFields[index].marca ? newFields[index].marca.value : 0))) {
                precosAtualizados.push({ marca: (newFields[index].marca ? newFields[index].marca.value : 0), preco: newFields[index].preco });
            }

            return {
                ...prevData,
                precos: precosAtualizados
            };
        });

        setFields(newFields);
    };

    const dropdownOptions = marcas.map((marca) => ({
        value: marca.id,
        label: marca.titulo
    }));

    const [fields, setFields] = useState([
        { marca: null, preco: '' }
    ]);

    useEffect(() => {

        let popupAux = { ...popupData }
        popupAux["precos"] = fields
        setPopupData(popupAux)

    }, [fields])

    const handleInputChange = (index, event) => {
        const value = event.target.value.toString();
        const newFields = [...fields];
        newFields[index].preco = value;

        // Atualiza o estado do popupData também
        setPopupData(prevData => {
            const precosAtualizados = (prevData.precos || []).map(preco =>
                preco.marca === (newFields[index].marca ? newFields[index].marca.value : 0) ? { ...preco, preco: value } : preco
            );

            // Se o preço da marca não estiver na lista, adicione-o
            if (!precosAtualizados.find(preco => preco.marca === (newFields[index].marca ? newFields[index].marca.value : 0))) {
                precosAtualizados.push({ marca: (newFields[index].marca ? newFields[index].marca.value : 0), preco: value });
            }

            return {
                ...prevData,
                precos: precosAtualizados
            };
        });

        setFields(newFields);
    };

    const handleAddField = () => {
        if (fields.length < marcas.length) {
            setFields([...fields, { marca: null, preco: '' }]);
        }
    };

    const handleRemoveField = (index) => {
        const newFields = fields.filter((_, i) => i !== index);
        setFields(newFields);
    };



    return (
        <>

            <Modal show={showModal} className="ModalProduto">
                <Modal.Header >
                    <Modal.Title>REGRA DE PREÇO</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label>Tipo de Veiculo</Form.Label>
                                <Dropdown
                                    value={popupData["tpveiculo"]} onChange={(e) => { handlePopupData(e.value, "tpveiculo") }}
                                    options={tipoVeiculo} // Alterei 'variacao' para 'tipoVeiculo' para corresponder ao estado correto
                                    optionLabel="label"
                                    placeholder="Escolha o tipo de veículo"
                                    className='Dropdown'
                                />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label className='Label'>
                                    <Form.Label>Variação</Form.Label>
                                    <Dropdown value={popupData["opcional"]} onChange={(e) => { handlePopupData(e.value, "opcional") }} options={optionsVariacao} optionLabel="label" placeholder="Escolha a Variação" className='Dropdown' />
                                </Form.Label>
                            </Form.Label>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Label>Preço</Form.Label>
                            {fields.map((field, index) => {
                                //console.log(field.marca)

                                return (
                                    <Row key={index}>
                                        {fields.length > 1 && (
                                            <Col xs={12} sm={12} md={6} lg={5}>
                                                <Form.Label className='Label'>
                                                    <div>
                                                        <Dropdown
                                                            options={dropdownOptions}
                                                            optionLabel="label"
                                                            placeholder="Marca"
                                                            className='Dropdown'
                                                            value={field.marca}
                                                            onChange={(e) => handleDropdownChange(index, e.target.value)}
                                                        />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                        )}
                                        <Col xs={12} sm={12} md={fields.length > 1 ? 7 : 12} lg={fields.length > 1 ? 7 : 12}>
                                            <Form.Label className='Label'>
                                                <div className="FormControl Add">
                                                    <ReaisInput
                                                        className='Inputs'
                                                        value={field.preco}
                                                        handleChange={(value) => handleInputChange(index, { target: { value } })}
                                                        placeholder="Preço"
                                                    />

                                                </div>
                                                {index === 0 ? (
                                                    <Button
                                                        className='Btns Add'
                                                        onClick={handleAddField}
                                                        disabled={fields.length >= marcas.length}
                                                    >
                                                        <Icon icon="ic:baseline-plus"></Icon>
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className='Btns Remove'
                                                        onClick={() => handleRemoveField(index)}
                                                    >
                                                        <Icon icon="ic:baseline-minus"></Icon>
                                                    </Button>
                                                )}
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Group className="mb-4">
                                <Form.Check
                                    className='Label'
                                    type="switch"
                                    label={isRegraPreco ? "Ativo" : "Desativado"}
                                    name="status"
                                    onChange={(e) => {
                                        RegrasPrecoToggle();
                                        handlePopupData(e.target.checked ? 'Ativo' : 'Inativo', "status")
                                    }}
                                ></Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="text-center">
                        {
                            editingRegraPreco ?
                                <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={putRegrasPreco}>
                                    Salvar Edição
                                </Button> :
                                <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={postRegrasPreco}>
                                    Salvar
                                </Button>
                        }
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Form.Group>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default RegrasPreco;
