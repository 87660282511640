import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";

import api from '../../services/apiIdz';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/Veiculos/Searce';
import Filter from '../../components/Veiculos/Filter';
import ViewVeiculoModal from '../../components/AnaliseVaiculos/ViewVeiculo';

//styles
import './CampraVeiculos.scss';
import Alerts from '../../components/Alerts';

//Imagens 
import logo from '../Dashboard/img/logo-idzcar.png'

function Veiculos() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [veicles, setVeicles] = useState()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    async function getVeiculos() {
        setReadyFilter(false)
        await api.get(`/api/vendaseucarro`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(async (res) => {
            if (res.data) {
                handleVeicleList(res.data)
            }
        })
    }
    const navigate = useNavigate();

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filteredArticles, setFilteredArticles] = useState(null);
    const [searchName, setSearchName] = useState('');

    const handleFilterChange = (filter, nameFilter) => {
        setFilteredArticles(filter);
        setSearchName(nameFilter);
    };

    const [readyFilter, setReadyFilter] = useState(false)

    const [filtered, setFiltered] = useState([])
    const handleFiltered = (rawData) => {

        console.log(rawData)

        let tmpCarList = rawData.sort((a, b) => {
          }).filter(car_d => car_d.modelo?.toLowerCase().includes(search) && car_d.status.toLowerCase().includes(status))

        setFiltered(tmpCarList)
    }

    const handleVeicleList = (rawData) => {
        console.log("rawData", rawData)
        setVeicles(rawData);
        setReadyFilter(true)
    }


    useEffect(() => {
        if (readyFilter) {
            console.log(veicles)
            console.log(search, status.name)
            handleFiltered(veicles)
        }
    }, [search, status, readyFilter])



    useEffect(() => {
        console.log(`[${status}]`)
        getVeiculos()
    }, [status])


    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    const [show, setShowModal] = useState(false);

    const showModalEdit = () => {
        setShowModal(!show);
    };

    const closeModalView = () => {
        setShowModal(false);
    };


    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content CampraVeiculos">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="fa-solid:check" /></i>
                                <h2> Análise de Veículos <br />
                                <span>Administre veículos enviados e envie ofertas aos clientes</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableVeiculos'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsVeiculos'>
                                        <Filter handleStatus={handleStatus} onFilterChange={handleFilterChange} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listVeiculos'>
                                        <Row>
                                            {
                                                filtered?.map((car, index) => {
                                                    return (
                                                        <Col xs={6} sm={12} md={3} lg={3} key={index}>
                                                            <div className='Veiculo'>
                                                                <div className='imagem'>
                                                                    {car.status === "Visualizado" ? (
                                                                        <span><i></i> Visualizado</span>
                                                                    ) : car.status === "Pendente" ? (
                                                                        <span className="danger"><i></i> Não Visualizado</span>
                                                                    ) : (
                                                                        <span className="sold"><i></i> Proposta Enviada</span>
                                                                    )}
                                                                    <img className='thumbnail' src={"https://api.idzcar.com.br" + car.imagem_frente}
                                                                    />
                                                                </div>
                                                                <h2>{car.modelo}</h2>
                                                                <h6>{car.ano_modelo} | {car.cor} | KM {car.quilometragem}</h6>
                                                                <hr />
                                                                <h3><span>Valor desejado</span> {car.preco?.toUpperCase()}</h3>

                                                                <div className='acoes'>
                                                                    <button className='Btns ButtonPrimary' onClick={() => {showModalEdit()}}>Visualizar</button> 
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
                <ViewVeiculoModal show={show} closeModalView={closeModalView} />
            </div>
        </>
    );
}
export default Veiculos;