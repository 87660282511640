import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Dropdown } from 'primereact/dropdown';
import InputMask from 'react-input-mask';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"

//styles
import './Dados.scss';
import Alerts from '../../components/Alerts';
import { useAuth } from '../../context/useAuth';
import api from '../../services/apiIdz';
import { useMediaQuery } from 'react-responsive';

//Imagens 
import logo from '../Dashboard/img/logo-idzcar.png'

function Dados() {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [menu, setMenu] = useState(false);
    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    const [inputs, setInputs] = useState([{ categoria: '', destinatario: '', whatsapp: '', }]);
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();
    const { token } = useAuth()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const [configData, setConfigData] = useState({
        razao_social: '',
        cnpj: '',
        telefone: '',
        endereco: '',
        instagram: '',
        youtube: '',
        facebook: '',
        linkedin: '',
        whatsapp: [],
        nome_fantasia: '',
        email: '',
        cep: '',
        endnum: '',
        bairro: '',
        complemento: '',
        horario: '',
        id_cidade: null,
        id_estado: null,
        orcamento: [
            { dois_fatores: '' },
            { exibir_preco: '' }
        ],
        estetica: [
            { dois_fatores: '' },
            { exibir_preco: '' }
        ]
        
    })

    useEffect(() => {
        console.log(configData)
    }, [configData])

    const handleConfigData = (value, field) => {
        setConfigData({
            ...configData,
            [field]: value
        })
    }

    const [estados, setEstados] = useState([])

    const getEstados = async () => {
        await api.get(`/api/estados`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            if (r.status == 200) {
                //console.log("[ESTADOS]", r.data)
                let aux = []
                r.data.forEach(item => {
                    aux.push({ label: item.est_nome + `(${item.est_uf})`, value: item.id })
                });
                setEstados(aux)
            }
        })
    }

    const [cidades, setCidades] = useState([])

    const getCidades = async (id) => {
        await api.get(`/api/estados/${id}?cidades=1`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            if (r.status == 200) {
                console.log("[CIDADES]", r.data)
                let aux = []
                r.data.cidades.forEach(item => {
                    aux.push({ label: item.cid_nome, value: item.id })
                });
                setCidades(aux)
            }
        })
    }

    useEffect(() => {
        if (configData["id_estado"]) {
            getCidades(configData["id_estado"])
        }
    }, [configData["id_estado"]])

    const getData = async () => {
        await api.get(`/api/dados-gerais/1`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            if (r.status == 200) {
                console.log("getData", r.data)

                setConfigData(r.data)

                if (typeof r.data.whatsapp != "string") {
                    setInputs(r.data.whatsapp)
                }

                setAtivoDoisFatores(r.data.orcamento.dois_fatores === 1);
                setAtivoOrcamento(r.data.orcamento.exibir_preco === 1);
                setAtivoDoisFatoresEstetica(r.data.estetica.dois_fatores === 1);
                setAtivoEstetica(r.data.estetica.exibir_preco === 1);
            }
        })
    }

    useEffect(() => {
        getData()
        getEstados()
    }, [])

    const putData = async () => {
        await api.put(`/api/dados-gerais/1`, configData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            // console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                }
            }
        })
    }

    const handleInputsDynamic = (value, index, field) => {
        if (inputs) {
            const inputData = [...inputs]
            inputData[index][field] = value
            setInputs(inputData)
        }
    }

    const handleAddInput = () => {
        if (inputs.length < 5) {
            setInputs([...inputs, { categoria: '', destinatario: '', whatsapp: '' }]);
        }
    };


    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const [optionsChumbado, setOptionsChumbado] = useState([
        { label: 'Veiculos', value: 'Veiculos' },
        { label: 'Blindagem', value: 'Blindagem' },
        { label: 'Estética', value: 'Estetica' },
        { label: 'Funilaria', value: 'Funilaria' },
        { label: 'Geral', value: 'Geral' }
    ])

    const [options, setOptions] = useState([
        { label: 'Veiculos', value: 'Veiculos' },
        { label: 'Blindagem', value: 'Blindagem' },
        { label: 'Estética', value: 'Estetica' },
        { label: 'Funilaria', value: 'Funilaria' },
        { label: 'Geral', value: 'Geral' }
    ])

    useEffect(() => {

        if (inputs.length > 0) {
            let aux = [...optionsChumbado]; // Copia os opcionais para um novo array
            inputs.forEach((dinamic) => {
                const itemIndex = aux.findIndex(d => d.value.toLowerCase() === dinamic.categoria.toLowerCase());
                if (itemIndex !== -1) {
                    aux.splice(itemIndex, 1); // Remove o item do array de opcionais
                }
            });
            setOptions(aux);
        }
    }, [inputs]);



    const handleDropdownChange = (selected) => {
        setSelectedOption(selected);
    };

    useEffect(() => {
        console.log(inputs)
        if (inputs) {
            handleConfigData(inputs, "whatsapp")
        }
    }, [inputs])

    const [ativoDoisFatores, setAtivoDoisFatores] = useState();
    const [ativoOrcamento, setAtivoOrcamento] = useState();

    const [ativoDoisFatoresEstetica, setAtivoDoisFatoresEstetica] = useState();
    const [ativoEstetica, setAtivoEstetica] = useState();

    const handleToggleDoisFatores = () => {
        const newDoisFatores = !ativoDoisFatores;
        setAtivoDoisFatores(newDoisFatores);
        const updatedConfigData = {
            ...configData,
            orcamento: {
                ...configData.orcamento,
                dois_fatores: newDoisFatores ? 1 : 0
            }
        };
        setConfigData(updatedConfigData);
    };
    
    const handleToggleOrcamento = () => {
        const newExibirPreco = !ativoOrcamento;
        setAtivoOrcamento(newExibirPreco);
        const updatedConfigData = {
            ...configData,
            orcamento: {
                ...configData.orcamento,
                exibir_preco: newExibirPreco ? 1 : 0
            }
        };
        setConfigData(updatedConfigData);
    };

    const handleToggleDoisFatoresEstetica = () => {
        const newDoisFatoresEstetica = !ativoDoisFatoresEstetica;
        setAtivoDoisFatoresEstetica(newDoisFatoresEstetica);
        const updatedConfigData = {
            ...configData,
            estetica: {
                ...configData.estetica,
                dois_fatores: newDoisFatoresEstetica ? 1 : 0
            }
        };
        setConfigData(updatedConfigData);
    };
    
    const handleToggleEstetica = () => {
        const newExibirPrecoEstetica = !ativoEstetica;
        setAtivoEstetica(newExibirPrecoEstetica);
        const updatedConfigData = {
            ...configData,
            estetica: {
                ...configData.estetica,
                exibir_preco: newExibirPrecoEstetica ? 1 : 0
            }
        };
        setConfigData(updatedConfigData);
    };

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Dados">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="icon-park-outline:config"></Icon></i>
                                <h2>Dados <br />
                                    <span>Informações da organização.</span></h2>
                                <Alert /> 
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableDados'>
                            <Row className='justify-content-center'>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <div className='HeaderForm'>
                                        <h2>Dados e links</h2>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} className='text-right'>
                                    <Form.Group className="FormDados">
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <h5>Dados básicos</h5>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={4}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        {/* <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["cnpj"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "cnpj")}
                                                            placeholder="CNPJ"
                                                        /> */}

                                                        <InputMask
                                                            className='Inputs'
                                                            style={{ width: '100%' }}
                                                            value={configData["cnpj"]}
                                                            mask="99.999.999/9999-99"
                                                            maskChar="_"
                                                            onChange={(e) => handleConfigData(e.target.value, "cnpj")}
                                                            placeholder="CNPJ"
                                                        />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={4}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["razao_social"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "razao_social")}
                                                            placeholder="Razão Social" />
                                                    </div>
                                                </Form.Label>
                                            </Col>

                                            <Col xs={12} sm={12} md={12} lg={4}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["nome_fantasia"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "nome_fantasia")}
                                                            placeholder="Nome fantasia" />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={3}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        {/* <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["telefone"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "telefone")}
                                                            placeholder="Telefone" /> */}
                                                        <InputMask
                                                            className='Inputs'
                                                            style={{ width: '100%' }}
                                                            mask="(99) 9999-9999"
                                                            maskChar="_"
                                                            value={configData["telefone"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "telefone")}
                                                            placeholder="Telefone"
                                                        />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={5}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["endereco"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "endereco")}
                                                            placeholder="Endereço" />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={2}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["endnum"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "endnum")}
                                                            placeholder="Número" />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={2}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["complemento"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "complemento")}
                                                            placeholder="Complemento" />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={3}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["bairro"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "bairro")}
                                                            placeholder="Bairro" />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={3}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        {/* <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["cep"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "cep")}
                                                            placeholder="CEP" /> */}
                                                        <InputMask
                                                            className='Inputs'
                                                            style={{ width: '100%' }}
                                                            mask="99999-999"
                                                            maskChar="_"
                                                            value={configData["cep"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "cep")}
                                                            placeholder="CEP" />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={3}>
                                                <Form.Label className='Label'>
                                                    <div className="">
                                                        <Dropdown
                                                            filter
                                                            optionLabel="label"
                                                            placeholder="Selecione o Estado"
                                                            className='Dropdown'
                                                            value={configData["id_estado"]}
                                                            onChange={(e) => { handleConfigData(e.target.value, "id_estado") }}
                                                            options={estados}
                                                        />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={3}>
                                                <Form.Label className='Label'>
                                                    <div className="">
                                                        <Dropdown
                                                            filter
                                                            optionLabel="label"
                                                            placeholder="Selecione a Cidade"
                                                            className='Dropdown'
                                                            value={configData["id_cidade"]}
                                                            onChange={(e) => { handleConfigData(e.target.value, "id_cidade") }}
                                                            options={cidades}
                                                        />
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["horario"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "horario")}
                                                            placeholder="Horário de atendimento" />
                                                    </div>
                                                </Form.Label>
                                            </Col>

                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <h5>Redes sociais</h5>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["instagram"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "instagram")}
                                                            placeholder="Instagram" />
                                                        <i><Icon icon="ic:baseline-link"></Icon></i>
                                                    </div>

                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["youtube"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "youtube")}
                                                            placeholder="Youtube" />
                                                        <i><Icon icon="ic:baseline-link"></Icon></i>
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["facebook"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "facebook")}
                                                            placeholder="Facebook" />
                                                        <i><Icon icon="ic:baseline-link"></Icon></i>
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6}>
                                                <Form.Label className='Label'>
                                                    <div className="FormControl Full">
                                                        <Form.Control
                                                            className='Inputs'
                                                            type="text"
                                                            value={configData["linkedin"]}
                                                            onChange={(e) => handleConfigData(e.target.value, "linkedin")}
                                                            placeholder="Linkedin" />
                                                        <i><Icon icon="ic:baseline-link"></Icon></i>
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <h5>WhatsApp e e-mails por Sessão</h5>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={10}>
                                                {inputs.map((input, index) => (
                                                    <Row>
                                                        <Col xs={12} sm={12} md={6} lg={3}>
                                                            <Form.Label className='Label'>
                                                                <div className="">
                                                                    <Dropdown
                                                                        options={options}
                                                                        optionLabel="label"
                                                                        placeholder={inputs[index]["categoria"] ? inputs[index]["categoria"] : "Categoria"}
                                                                        className='Dropdown'
                                                                        value={inputs[index]["categoria"]}
                                                                        onChange={(e) => handleInputsDynamic(e.target.value, index, "categoria")}
                                                                    />
                                                                </div>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={5}>
                                                            <Form.Label className='Label'>
                                                                <div className="">
                                                                <Form.Label className='Label'>
                                                                    <div className="FormControl Full">
                                                                        <Form.Control
                                                                            className='Inputs'
                                                                            type="text"
                                                                            value={inputs[index]["destinatario"]}
                                                                            onChange={(e) => handleInputsDynamic(e.target.value, index, "destinatario")}
                                                                            placeholder="E-mail para receber leads" />
                                                                    </div>
                                                                </Form.Label>
                                                                </div>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={4}>
                                                            <Form.Label key={index + 1} className='Label'>
                                                                <div className="FormControl">

                                                                    <InputMask
                                                                        className='Inputs add'
                                                                        style={{ width: '100%' }}
                                                                        mask="(99) 9 9999-9999"
                                                                        maskChar="_"
                                                                        placeholder="Número do WhatsApp"
                                                                        value={inputs[index]["whatsapp"]}
                                                                        onChange={(e) => handleInputsDynamic(e.target.value, index, "whatsapp")}
                                                                    />

                                                                </div>
                                                                {index === 0 && (
                                                                    <Button data-tooltip-id="my-tooltip-link" className='Btns Add' onClick={handleAddInput}><Icon icon="ic:baseline-plus"></Icon></Button>
                                                                )}
                                                                {(index !== 0) && (
                                                                    <Button data-tooltip-id="my-tooltip-remover" className='Btns Remove' onClick={() => handleRemoveInput(index)}><Icon icon="ic:baseline-minus"></Icon></Button>
                                                                )}
                                                            </Form.Label>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <h5>Congigurações de Segurança Blindagem</h5>
                                                <div className='statusDoisFatores'>
                                                    <Form.Check
                                                        className='Label'
                                                        type="switch"
                                                        label={ativoDoisFatores ? "Autenticação de dois fatores ativa na blindagem" : "Autenticação de dois fatores desativada na blindagem"}
                                                        checked={ativoDoisFatores}
                                                        onChange={handleToggleDoisFatores}
                                                    ></Form.Check>
                                                </div>
                                                <div className='statusDoisFatores'>
                                                    <Form.Check
                                                        className='Label'
                                                        type="switch"
                                                        label={ativoOrcamento ? "Exibir preço no blindagem" : "Não exibir preço no blindagem"}
                                                        checked={ativoOrcamento}
                                                        onChange={handleToggleOrcamento}
                                                    ></Form.Check>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <h5>Congigurações de Segurança Estética</h5>
                                                <div className='statusDoisFatores'>
                                                    <Form.Check
                                                        className='Label'
                                                        type="switch"
                                                        label={ativoDoisFatoresEstetica ? "Autenticação de dois fatores ativa na estética" : "Autenticação de dois fatores desativada na estética"}
                                                        checked={ativoDoisFatoresEstetica}
                                                        onChange={handleToggleDoisFatoresEstetica}
                                                    ></Form.Check>
                                                </div>
                                                <div className='statusDoisFatores'>
                                                    <Form.Check
                                                        className='Label'
                                                        type="switch"
                                                        label={ativoEstetica ? "Exibir preço na estética" : "Não exibir preço na estética"}
                                                        checked={ativoEstetica}
                                                        onChange={handleToggleEstetica}
                                                    ></Form.Check>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} className='acoes'>
                                                <Button className='Btns ButtonPrimaryLight' onClick={putData}>Salvar</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-adicionar" place="bottom" content="Adicionar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-pausar" place="bottom" content="Pausar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-ativar" place="bottom" content="Ativar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
        </>
    );
}
export default Dados;