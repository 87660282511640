import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import Accordion from 'react-bootstrap/Accordion';
import api from "../../../services/apiIdz";

import "./ViewVeiculo.scss";

function ViewLead({ show, closeModalView }) {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show]);


    const handleClose = () => {
        setShowModal(false);
        closeModalView()
    };

    return (
        <Modal show={showModal} className="ModalLead">
            <Modal.Header>
                <Modal.Title><Icon icon="material-symbols:dashboard-customize"></Icon> Você tem um novo Lead!<span>novo lead</span></Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <i className='flutu'><Icon icon="mdi:bell"></Icon></i>
                <Form>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <div className='boxModalLead'>
                            
                                <h4>Dados de Contato</h4>
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value="" disabled />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value="" disabled />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value="" disabled />
                                </Form.Group>

                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className='navegacao'>
                            <div className='boxModalLead'>
                                <h4>Dados do Veiculo</h4>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Group className="text-center">
                                <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                    Fechar
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ViewLead;