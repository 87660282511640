import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from 'react-responsive';
import api from '../../services/apiIdz';

// Bibliotecas
import { VictoryChart, VictoryBar, VictoryTheme, VictoryArea, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer } from "victory";

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"

//style
import './Dashboard.scss';
import 'react-tooltip/dist/react-tooltip.css'
import ViewLead from '../../components/Leads/ViewLead';
import { useAuth } from '../../context/useAuth';

//Imagens 
import logo from '../Dashboard/img/logo-idzcar.png'

function Dashboard() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    

    // Graficos

    const [period, setPeriod] = useState(isMobile ? 'semanal' : 'mensal');
    const [dashboardData, setDashboardData] = useState({
        periodo: '',
        grafico: {},
        veiculos: {},
        blindagem: {},
        estetica: {},
    });

    const { token } = useAuth()

    const [showView, setshowView] = useState(false);
    const [currentLead, setCurrentLead] = useState({})

    const getCurrentLead = async (id) => {
        await api.get(`/api/leads/${id}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'Token': token
            }
        }).then(r => {
            console.log(r)
            if (r.data) {
                setCurrentLead(r.data)
            }
        })
    }

    const setVisualizado = async (id) => {
        let stat = 'Visualizado'
        await api.put(`/api/leads/${id}`, {
            status: stat,
        }, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'Token': token
            }
        }).then(r => {
            console.log(r)
            if (r.data) {
                console.log(`status de ${id} alterado para ${stat}`)
                getLeads()
            }
        })
    }
    const showModalView = (id) => {
        setshowView(true);
        getCurrentLead(id)
    };
    const closeModalView = () => {
        setshowView(false);
        setCurrentLead({})
    };

    const fetchData = async () => {
        try {
            const response = await api.get(`/api/statistic-dashboard?periodo=${period}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY
                }
            });

            // Se os dados forem recebidos com sucesso
            if (response && response.data) {
                setDashboardData(response.data);
                console.log("dashboardData", dashboardData)
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [period]);


    const handleFilterDash = (selectedPeriod) => {
        setPeriod(selectedPeriod);
    };


    const [data, setData] = useState([]);
    useEffect(() => {
        if (dashboardData && dashboardData.grafico) {
            const formattedData = Object.keys(dashboardData.grafico).map(day => ({ day, value: dashboardData.grafico[day] }));
            setData(formattedData);
            console.log("data:", data)
        }
    }, [dashboardData]);


    // Leads

    const [isInterval, setInterval] = useState("");


    async function filterDash(interval) {
        setInterval(interval);
        console.log(interval)
    }

    const sampleData = [
        { mes: 'JAN', value: 200 },
        { mes: 'FEV', value: 202 },
        { mes: 'MAR', value: 180 },
        { mes: 'ABR', value: 180 },
        { mes: 'MAI', value: 120 },
        { mes: 'JUN', value: 180 },
        { mes: 'JUL', value: 400 },
        { mes: 'AGO', value: 530 },
        { mes: 'SET', value: 100 },
        { mes: 'OUT', value: 560 },
        { mes: 'NOV', value: 330 }
    ];


    const [leads, setLeads] = useState([]);

    const getLeads = async () => {
        try {
            const response = await api.get(`/api/leads`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY
                }
            });
            setLeads(response.data);

        } catch (error) {
            console.error('Erro ao obter os leads:', error);
        }
    };

    const iconMapping = {
        '1': 'wpf:security-checked',
        '2': 'fa-solid:car',
        '3': 'mdi:tools',
        '4': 'mdi:stars'
    };

    useEffect(() => {
        getLeads();
    }, []);

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };


    return (
        <>
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Dashboard">

                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderDashboard'>
                                <i><Icon icon="material-symbols:dashboard-customize" ></Icon></i>
                                <h2>Seja bem-vindo ao seu Dashboard <br />
                                    <span>Abaixo você encontra os dados mais importantes da sua conta</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='GraphicDashboard'>
                                <div className='HeaderGraphic'>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <h3>NÚMERO DE ACESSOS</h3>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <ul>
                                                <li>
                                                    <a onClick={() => { handleFilterDash("diario") }} className={`${period === 'diario' ? 'active' : ''}`}>Hoje</a>
                                                    <a onClick={() => { handleFilterDash("semanal") }} className={`${period === 'semanal' ? 'active' : ''}`}>Semana</a>
                                                    <a onClick={() => { handleFilterDash("mensal") }} className={`${period === 'mensal' ? 'active' : ''}`}>Mensal</a>
                                                    <a onClick={() => { handleFilterDash("anual") }} className={`${period === 'anual' ? 'active' : ''}`}>Último Ano</a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='Graphic'>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <div className='BarGraphic'>
                                                {data.every(item => item.value === 0) ? (
                                                    <p>Nenhum registro encontrado</p>
                                                ) : (
                                                    <VictoryChart width={800} theme={VictoryTheme.material} domainPadding={22}>
                                                        <VictoryBar
                                                            data={data}
                                                            x="day"
                                                            y="value"
                                                            cornerRadius={13}
                                                            style={{
                                                                data: {
                                                                    fill: 'url(#gradient)'
                                                                },
                                                            }}
                                                        />
                                                    </VictoryChart>
                                                )}
                                                <svg>
                                                    <defs>
                                                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                                            <stop offset="20%" stopColor="#4B4D53" />
                                                            <stop offset="100%" stopColor="#202124" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <Row>
                                                {dashboardData ? (
                                                    <>
                                                        <Col xs={6} sm={12} md={12} lg={6}>
                                                            <div className='CardGraphic'>
                                                                <h4>Veículos</h4>
                                                                {dashboardData.veiculos ? (
                                                                    <>
                                                                        <h2>{dashboardData.veiculos.valor}</h2>
                                                                        <i><Icon className="icons" icon="fa-solid:car" /></i>
                                                                        <hr />
                                                                        {dashboardData.veiculos.percent != "-%" && <span>{dashboardData.veiculos.percent}</span>}
                                                                    </>
                                                                ) : (
                                                                    <p>Dados de veículos não encontrados</p>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={12} md={12} lg={6}>
                                                            <div className='CardGraphic'>
                                                                <h4>Blindagem</h4>
                                                                {dashboardData.blindagem ? (
                                                                    <>
                                                                        <h2>{dashboardData.blindagem.valor}</h2>
                                                                        <i><Icon className="icons" icon="wpf:security-checked" /></i>
                                                                        <hr />
                                                                        {dashboardData.blindagem.percent != "-%" && <span>{dashboardData.blindagem.percent}</span>}
                                                                    </>
                                                                ) : (
                                                                    <p>Dados de blindagem não encontrados</p>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={12} md={12} lg={6}>
                                                            <div className='CardGraphic'>
                                                                <h4>Estética</h4>
                                                                {dashboardData.estetica ? (
                                                                    <>
                                                                        <h2>{dashboardData.estetica.valor}</h2>
                                                                        <i><Icon className="icons" icon="mdi:stars" /></i>
                                                                        <hr />
                                                                        {dashboardData.estetica.percent != "-%" && <span>{dashboardData.estetica.percent}</span>}
                                                                    </>
                                                                ) : (
                                                                    <p>Dados de estética não encontrados</p>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={12} md={12} lg={6}>
                                                            <div className='CardGraphic'>
                                                                <h4>Funilaria</h4>
                                                                {dashboardData.funilaria ? (
                                                                    <>
                                                                        <h2>{dashboardData.funilaria.valor}</h2>
                                                                        <i><Icon className="icons" icon="mdi:tools" /></i>
                                                                        <hr />
                                                                        {dashboardData.funilaria.percent != "-%" && <span>{dashboardData.funilaria.percent}</span>}
                                                                    </>
                                                                ) : (
                                                                    <p>Dados de funilaria não encontrados</p>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <p>Dados não disponíveis</p>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='LeadsDashboard'>
                                <div className='HeaderLeads'>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3>LEADS</h3>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='Leads'>
                                    <Row>
                                        {leads.slice(0, 6).map((lead, index) => (
                                            <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                <div className='ItemLeads'>
                                                    <i data-tooltip-id={`my-tooltip-${lead.tipo}`}>
                                                        {/* Usa o ícone correspondente ao tipo de lead do mapeamento */}
                                                        <Icon className="icons" icon={iconMapping[lead.tipo]} />
                                                    </i>
                                                    <h3>{lead.nome}</h3>
                                                    <h5 className="push">{lead.dtinc} | {lead.hora}</h5>
                                                    <span className={`${lead.status ? 'active' : 'disable'}`}></span>
                                                    <button onClick={() => { showModalView(lead.id) }}><Icon icon="ic:baseline-plus" /></button>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <a href="/leads"><button className='SeeAll'>Veja Todo</button></a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <ReactTooltip id="my-tooltip-security" place="bottom" content="Blindados" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-stars" place="bottom" content="Estética" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-tools" place="bottom" content="Funilaria" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-car" place="bottom" content="Veiculo" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ViewLead show={showView} setVisualizado={setVisualizado} closeModalView={closeModalView} currentLead={currentLead} getLeads={getLeads} token={token}></ViewLead>
                </Container>
            </div>
        </>

    );
}

export default Dashboard;
