import React, { useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useAuth } from '../../context/useAuth';
import api from '../../services/apiIdz';

//Components
import Alerts from '../../components/Alerts';
import ShowPassword from '../../components/ShowPassword';
import PasswordRecovery from '../../components/PasswordRecovery';

//CSS
import './Login.scss';

//Imagens 
import logo from './img/logo-login.svg'

function Login() {

  const [showPassword, setShowPassword] = useState(false);
  const { checkLogin, loading } = useAuth()


  useEffect(() => {
    if (loading) {
      checkLogin()
    }
  }, [loading])

  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isVisible, setIsVisible] = useState(false);
  const [isTitle, setTitle] = useState('');
  const [initDescription, setDescription] = useState('');
  const [initType, setType] = useState('');

  const handleAlert = (title, description, type) => {
    setIsVisible(true)
    setTitle(title);
    setDescription(description);
    setType(type);
  }

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setTimeout(() => {
          setIsVisible(false);
        }, 200);
      }, 8000);
    }
  }, [isVisible])

  const handleLogin = async (email, password) => {
    try {

      const response = await api.post('/api/login', {
        "usuario": email,
        "senha": password
      }, {
        headers: {
          "Authorization": process.env.REACT_APP_API_KEY
        }
      });

      console.log(typeof response.data)

      if (response.data && typeof response.data == 'string') {
        handleAlert('Credenciais inválidas', response.data, 'danger')
      } else {
        console.log(response.data)
        setIsLoggedIn(true);
        window.localStorage.setItem('auth_data', JSON.stringify(response.data))
      }
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        handleAlert('Credenciais inválidas', 'Erro de conexão. Verifique sua conexão de rede.', 'danger')
      } else {
        handleAlert('Credenciais inválidas', 'Verifique os dados e novamente', 'danger')
      }
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsVisible(false);

    const usernameInput = document.getElementById('username');
    const passwordInput = document.getElementById('password');
    const iconMail = document.getElementById('mail');
    const iconLock = document.getElementById('lock');

    iconMail.classList.remove('iconWrong');
    iconLock.classList.remove('iconWrong');
    usernameInput.classList.remove('usernameWrong');
    passwordInput.classList.remove('passwordWrong');

    if (usernameInput.value.trim() === '') {
      usernameInput.classList.add('usernameWrong');
      iconMail.classList.add('iconWrong');
      handleAlert('Credenciais inválidas', 'Por favor, informe seu email', 'danger')
      return;
    }

    if (passwordInput.value.trim() === '') {
      passwordInput.classList.add('passwordWrong');
      iconLock.classList.add('iconWrong');
      handleAlert('Credenciais inválidas', 'Por favor, informe uma senha', 'danger')
      return;
    }

    if (usernameInput.value !== '' && passwordInput.value !== '') {
      //setIsLoggedIn(true);
      handleLogin(usernameInput.value, passwordInput.value)
    }
    else {
      usernameInput.classList.add('usernameWrong');
      passwordInput.classList.add('passwordWrong');
      iconMail.classList.add('iconWrong');
      iconLock.classList.add('iconWrong');
      handleAlert('Credenciais inválidas', 'Preencha todos os campos', 'danger')
    }
  };

  if (isLoggedIn) {
    console.log("logged")
    window.location.href = window.location.origin + "/dashboard"
  }

  const handlePasswordRecovery = () => {
    setShowPasswordRecovery(true);
  };

  if (showPasswordRecovery) {
    return <PasswordRecovery />;
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="page-login">
      {isVisible &&
        <Alerts title={isTitle} description={initDescription} type={initType} />
      }
      <div className='container-fluid'>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className='logo'>

          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className='forms'>
            <div className='form'>
              <div className='title'>
                <i><img src={logo} /></i>
                <h4>Painel do usuário</h4>
                <h3>Faça Login para acessar sua conta</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <input
                    className='login'
                    type="text"
                    id="username"
                    placeholder='Digite seu email'
                    value={username}
                    onChange={handleUsernameChange}
                  />
                  <span className='icon' id="mail">
                    <Icon id='mail' icon="line-md:email" />
                  </span>
                </div>
                <div>

                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <span className="icon" id="lock">
                    <Icon id="lock " icon="bx:lock" />
                  </span>
                  <ShowPassword isVisible={showPassword} toggleVisibility={togglePasswordVisibility} />
                </div>


                <button className='Btns ButtonPrimary' type="submit">Acessar</button>
                <div onClick={handlePasswordRecovery} className="passwordRecovery">
                  Esqueceu a sua senha?
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Login;