import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Alert.scss";
import { Icon } from '@iconify/react';
import api from "../../services/api";
import { useAuth } from "../../context/useAuth";

function Alert() {
    const [isHovered, setIsHovered] = useState(false);
    const [notificationAmount, setNotificationAmount] = useState(1)

    const { user } = useAuth()

    async function getNotifications() {
        // await api.get(`/posts/status/pending`, { withCredentials: true }).then((res) => {
        //     setNotificationAmount(res.data.currentMonth)
        // })
    }

    useEffect(() => {
        if (user) {
            getNotifications()
        }
    }, [user])

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    return (

        notificationAmount == 0 ? <div className='alert-notification'>
            <span className="status">{notificationAmount}</span>
            <div className={`background ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <Icon icon="mdi:bell-outline" color="#FFFFFF" />
            </div>
            {isHovered && (
                <div className={"message-box ${isHovered ? 'hovered' : ''}"} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <div className="message-content">
                        <p> Você tem <span> {notificationAmount} atualização</span></p>
                        <p> que precisam de revisão.</p>
                        <Link className="button" to={"/my-articles"}>Revisar</Link>
                    </div>
                </div>
            )}
        </div> : <></>

    );
}

export default Alert;
