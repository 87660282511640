import React, { useRef, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import api from '../../../services/apiIdz';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Form from 'react-bootstrap/Form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Importa o CSS

import balistica01 from '../Novo/img/balistica01.png';
import balistica02 from '../Novo/img/balistica02.png';
import balistica03 from '../Novo/img/balistica03.png';
import balistica04 from '../Novo/img/balistica04.png';
import balistica05 from '../Novo/img/balistica05.png';
import balistica06 from '../Novo/img/balistica06.png';

import armamento01 from '../Novo/img/armamento01.png';
import armamento02 from '../Novo/img/armamento02.png';
import armamento03 from '../Novo/img/armamento03.png';
import armamento04 from '../Novo/img/armamento04.png';
import armamento05 from '../Novo/img/armamento05.png';
import armamento06 from '../Novo/img/armamento06.png';

import './Imprimir.scss';

const MyComponentToPrint = React.forwardRef((props, ref) => {
    const valorTotal = props.proposta?.Pagamento.valorTotal;

    let valorNumerico = 0;
    let valorEmTexto = '---';

    if (valorTotal && valorTotal !== '---') {
        valorNumerico = parseFloat(valorTotal.replace(/[^\d,]/g, '').replace(',', '.'));
        valorEmTexto = numeroParaTexto(valorNumerico);
    }

    function numeroParaTexto(valor) {
        const unidade = ["", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove"];
        const dezena = ["", "dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"];
        const centena = ["", "cem", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"];
        const especial = ["dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"];

        function converter(valor) {
            if (valor < 10) return unidade[valor];
            if (valor < 20) return especial[valor - 10];
            if (valor < 100) return dezena[Math.floor(valor / 10)] + (valor % 10 !== 0 ? " e " + unidade[valor % 10] : "");
            if (valor < 1000) return centena[Math.floor(valor / 100)] + (valor % 100 !== 0 ? " e " + converter(valor % 100) : "");
            if (valor < 1000000) return converter(Math.floor(valor / 1000)) + " mil" + (valor % 1000 !== 0 ? " e " + converter(valor % 1000) : "");
            return converter(Math.floor(valor / 1000000)) + " milhões" + (valor % 1000000 !== 0 ? " e " + converter(valor % 1000000) : "");
        }

        const valorTexto = converter(valor);
        return valorTexto.charAt(0).toUpperCase() + valorTexto.slice(1);
    }

    console.log("Valor em texto:", valorEmTexto);

    const balistica = [
        { id: 1, text1: 'I', img1: balistica01, img2: armamento01, text2: '.22 LRHV Lead <br/> .38 Special RN Lead', text3: '15 - 16,5 cm <br/> 6 - 6,5 in <br/>  15 - 16,5 cm <br/> 6 - 6,5 in', text4: '320 +/- 12m/s <br/> 1050 +/- 40ft/s <br/> 259 +/- 15m/s <br/> 850 +/- 50ft/s', text5: '133,12 J <br/> 342,12 J', text6: '5' },
        { id: 2, text1: 'II - A', img1: balistica02, img2: armamento02, text2: '9mm FMJ <br/> .357 Mag JSP', text3: '10 - 12 cm <br/> 4 - 4,75 in <br/> 10 - 12 cm  <br/>4 - 4,75 in', text4: '332 +/- 12m/s <br/> 1050 +/- 40ft/s <br/> 281 +/- 15m/s <br/> 1250 +/- 50ft/s', text5: ' <br/> ', text6: '5' },
        { id: 3, text1: 'II', img1: balistica03, img2: armamento03, text2: '9mm FMJ <br/> .357 Mag JSP', text3: '10 - 12 cm <br/> 4 - 4,75 in  <br/> 15 - 16,5 cm <br/> 6 - 6,5 in', text4: '358 +/- 12m/s <br/> 1175 +/- 40ft/s <br/> 425 +/- 15m/s <br/> 1395 +/- 50ft/s', text5: '440,9 9J <br/> 740 J', text6: '5' },
        { id: 4, text1: 'III - A', img1: balistica04, img2: armamento04, text2: '24 - 26 cm <br/> 9,5 - 10,25 in <br/> 14 - 16 cm <br/> 5,5 - 6,25 in', text4: '426 +/- 15m/s <br/> 1400 +/- 50ft/s <br/> 426 +/- 15m/s <br/> 1400 +/- 50ft/s', text5: '512,66 J <br/> 921 J', text6: '5' },
        { id: 5, text1: 'III', img1: balistica05, img2: armamento05, text2: '7,62 x 51 FJ NB <br/> .308 Winchester FMJ', text3: '56 cm <br/> 22 in', text4: '838 +/- 15m/s <br/> 2750 +/- 50ft/s', text5: '3405 J', text6: '5' },
        { id: 6, text1: 'IV', img1: balistica06, img2: armamento06, text2: '30-06 AP', text3: '56 cm <br/> 22 in', text4: '868 +/- 15m/s <br/> 2850 +/- 50ft/s', text5: '4068,5 J', text6: '5' },
    ];

    return (
        <div ref={ref} className='pdf'>
            <div className='capa'>
                <h6>Nº{props.proposta?.proposta || '---'}</h6>
            </div>
            <div className='paginas timbrado3'>
                <div className='data'>
                    <ul>
                        <li><strong>Data:</strong> {props.proposta?.data || '---'}</li>
                        <li><strong>A/C Sr(a):</strong> {props.proposta?.Comprador.nomeCliente || '---'}</li>
                    </ul>
                    <h3>Muito obrigado Por <strong>confiar a sua segurança</strong> no nosso trabalho.</h3>
                    <p>A 1021 conta com a experiência de 15 anos em blindagem automotiva. Em 2018 fizemos o projeto de blindagem para um conceituado fabricante automotivo que entrou neste segmento de blindagem elevando o patamar de segurança em relação ao mercado no quesito materiais de última geração, aplicação, deformação e colagem.</p>
                    <p>Criamos técnicas de instalação exclusivas desenvolvidas em exaustivos teste balísticos em materiais opacos e vidros para maior segurança do usuário.</p>
                </div>
            </div>
            <div className='paginas timbrado2'>
                <h1><i></i>proposta<br /><strong>comercial</strong></h1>
                <h6>Nº{props.proposta?.proposta || '---'}</h6>

                <h2>Dados do Contratante</h2>
                <div className="campos">
                    <div className='label'>Nome</div>
                    <div className='campo'>{props.proposta?.Comprador.nomeCliente || '---'}</div>

                    <div className='label'>Telefone</div>
                    <div className='campo'>{props.proposta?.Comprador.telefone || '---'}</div>
                </div>

                <h2>Dados da Contratada</h2>
                <div className="campos">
                    <div className='meio'>
                        <div className='label'>Nome</div>
                        <div className='campo'>{props.proposta?.Contratada.nomeContratada || '---'}</div>
                    </div>

                    <div className='meio'>
                        <div className='label'>CNPJ</div>
                        <div className='campo'>{props.proposta?.Contratada.cnpjContratada || '---'}</div>
                    </div>

                    <div className='meio'>
                        <div className='label'>Endereço</div>
                        <div className='campo'>{props.proposta?.Contratada.enderecoContratada || '---'}</div>
                    </div>

                    <div className='metade'>
                        <div className='label'>Bairro</div>
                        <div className='campo'>{props.proposta?.Contratada.bairroContratada || '---'}</div>
                    </div>

                    <div className='metade'>
                        <div className='label'>Cidade / UF</div>
                        <div className='campo'>{props.proposta?.Contratada.cidadeContratada || '---'}, {props.proposta?.Contratada.estadoContratada || '---'}</div>
                    </div>
                </div>

                <h2>Dados do Veículo</h2>

                <div className="campos">
                    <div className='metade'>
                        <div className='label'>Marca</div>
                        <div className='campo'>{props.proposta?.Veiculo.marcaTitulo || '---'}</div>
                    </div>

                    <div className='meio'>
                        <div className='label'>Modelo</div>
                        <div className='campo'>{props.proposta?.Veiculo.modeloTitulo || '---'}</div>
                    </div>

                    <div className='metade'>
                        <div className='label'>Ano / Fab.</div>
                        <div className='campo'>{props.proposta?.Veiculo.anoFabricacao || '---'}/{props.proposta?.Veiculo.anoModelo || '---'}</div>
                    </div>

                    <div>
                        <div className='label'>Tipo de Blindagem</div>
                        <div className='campo'>UDURA + {props.proposta?.Blindagem.vidro || '---'}</div>
                    </div>
                </div>

                <div className='valores'>
                    <h4>Valor para execução da blindagem</h4>
                    <h3>{valorTotal} <span>({valorEmTexto})</span></h3>
                    <hr />
                    <h4>Forma de pagamento</h4>
                    <h3>{props.proposta?.Pagamento.entrada || '---'} de entrada + {props.proposta?.Pagamento.parcelas || '---'} vezes</h3><span>(Transferência / TED)</span>
                </div>
            </div>
            <div className='paginas timbrado3 lista'>
                <h1><i></i>Tipo<br /><strong>de blindagem</strong></h1>
                <h4>Apresentamos as características do produto escolhido e sua composição técnica:</h4>

                <h3>UDURA + {props.proposta?.Blindagem.vidro || '---'}</h3>

                <h5>MATERIAIS</h5>
                <ul>
                    <ul>
                        <li>Nível III-A</li>
                        <li>Vidros {props.proposta?.Blindagem.vidro || '---'}</li>
                        <li>Placas de Udura 29 camadas</li>
                        <li>A blindagem será feita em 20 dias corridos ou seja até a chegada do kit de vidro na fábrica você ficará usando o carro, (tempo médio de entrega do kit de vidro 35 a 40 dias corridos) ou seja só iremos retirar seu carro quando o kit de vidro estiver na nossa empresa</li>
                    </ul>
                </ul>
                <h5>ÍTENS INCLUSOS</h5>
                <ul>
                    <ul>
                        <li>Overlap de portas integrado aos vidros (SteelGlass)</li>
                        <li>Blindagem de paralamas (Padrão)</li>
                    </ul>
                </ul>

                <h5>GARANTIA</h5>
                <ul>
                    <ul>
                        <li>Vidros 10 anos contra delaminação</li>
                        <li> Placa de Udura 10 anos</li>
                    </ul>
                </ul>

                <h5>PÓS-VENDA</h5>
                <ul>
                    <ul>
                        <li>Revisão corretiva até 6 meses gratuita</li>
                        <li>Atendimento à domicílio, se necessário</li>
                        <li>Fazemos toda documentação de exército, laudo Inmetro, laudo ECV, declaração de blindagem, todas as taxas e uma segunda documentação para inclusão de blindagem serão feitas a um custo definido no orçamento e serão pagos à parte na entrega do veículo blindado.</li>
                    </ul>
                </ul>
            </div>
            <div className='paginas timbrado2'>
                <h1><i></i>NÍVEL DE<br /><strong>PROTEÇÃO BALÍSTICA</strong></h1>
                <p>A blindagem proposta utiliza vidros blindados e materiais, que estam em conformidade com a norma Brasileira NBR 15000/05 protege contra os seguintes tipos de armamentos:</p>
                <div className='protecao'>
                    <table>
                        <thead>
                            <tr>
                                <th>Níveis de Blindagem</th>
                                <th>Projéteis</th>
                                <th>Armamento</th>
                                <th>Tipo de Munição</th>
                                <th>Comprimento do Calibre</th>
                                <th>Velocidade Referência</th>
                                <th>Energia Cinética</th>
                                <th>Disparos por painel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {balistica.map((row) => (
                                <tr key={row.id}>
                                    <td>{row.text1}</td>
                                    <td><img src={row.img1} alt={`Imagem ${row.id} - 1`} width="50" /></td>
                                    <td><img src={row.img2} alt={`Imagem ${row.id} - 2`} width="50" /></td>
                                    <td dangerouslySetInnerHTML={{ __html: row.text2 }}></td>
                                    <td dangerouslySetInnerHTML={{ __html: row.text3 }}></td>
                                    <td dangerouslySetInnerHTML={{ __html: row.text4 }}></td>
                                    <td dangerouslySetInnerHTML={{ __html: row.text5 }}></td>
                                    <td>{row.text6}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h5 class="ajuste-mobile-h5">PESO DA BLINDAGEM</h5>
                <ul><li>A instalação do kit de blindagem de acordo com o nível III-A da Norma NBR 15000/05 varia de acordo com a carroceria de cada modelo.</li></ul>

                <h5>CHECKLIST DE ENTRADA</h5>
                <ul><li>No ato da entrega do veículo na blindadora será efetuado o check list, verificando em qual estado de conservação se encontra o mesmo, bem como os itens inclusos.</li></ul>

                <h5>CHECKLIST DE SAÍDA</h5>
                <ul><li>No ato da entrega do veículo deverá ser efetuado o check list, verificando se houve avaria durante o processo de instalação do kit de blindagem, caso haja alguma avaria, a 1021 BLINDAGENS se responsabilizará pelo devido reparo.</li></ul>
            </div>
            <div className='paginas timbrado2'>
                <h5>GARANTIA</h5>
                <ul><li>O tempo de garantia é informado nas informações complementares desta proposta, que começará valer a partir da data de emissão da Nota Fiscal, abrangendo todos os componentes utilizados na blindagem.</li></ul>

                <h5>LEGISLAÇÃO</h5>
                <ul><li>De acordo com o Decreto Federal 3.665 de 20/11/2000, os veículos blindados são produtos controlados pelo Ministério da Defesa Exército Brasileiro. A 1021 MOTORS, conforme Certificado de Registro 500885 - SFPC/2, encontra-se devidamente certificada, de modo a garantir a total regularidade do veículo quanto a sua blindagem.</li></ul>

                <h5>DOCUMENTOS</h5>
                <ul><li>Os documentos abaixo relacionados fazem parte da entrega do veiculo blindado:</li>
                    <ul>
                        <li>Manual do usuário com Certificado de Garantia;</li>
                        <li>Termo de Responsabilidade;</li>
                        <li>Certificado de Blindagem;</li>
                        <li>Certificado de Garantia do fabricante do vidro.</li>
                    </ul>
                </ul>

                <h5>VALIDADE DA PROPOSTA</h5>
                <ul><li>{props.proposta?.Pagamento.validadeProposta || '---'} dias.</li></ul>

                <div className='assinatura'>
                    <div className='parte1'>Atenciosamente,</div>
                    <div className='parte2'>Alfredo José Capopizza</div>
                    <div className='dados'>
                        <p>
                            alfredo@1021motors.com.br<br />
                            (11) 5583-1021<br />
                            (11) 95222-1021
                        </p>
                    </div>
                </div>
            </div>
            <div className='verso'>
                <h1>{props.proposta?.Vendedor.nomeLocalVendedor || '---'}</h1>
                <p>{props.proposta?.Vendedor.enderecoVendedor || '---'}, {props.proposta?.Vendedor.bairroVendedor || '---'} – {props.proposta?.Vendedor.cidadeVendedor || '---'} - {props.proposta?.Vendedor.estadoVendedor || '---'} CEP: {props.proposta?.Vendedor.cepVendedor || '---'} / Fone: {props.proposta?.Vendedor.telefoneVendedor || '---'}</p>
            </div>
        </div>
    );
});

function ImprimirProposta() {
    const componentRef = useRef();
    const { id } = useParams();
    const [proposta, setProposta] = useState(null);

    useEffect(() => {
        const fetchProposta = async () => {
            try {
                const response = await api.get(`/api/orcamento-digitado/${id}`);
                setProposta(response.data);
            } catch (error) {
                console.error('Erro ao buscar a proposta:', error);
            }
        };

        fetchProposta();
    }, [id]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'MinhaPaginaPDF',
        onAfterPrint: () => console.log('PDF gerado com sucesso!'),
    });

    const handleDownloadPDF = async () => {
        // Salva os estilos originais
        const originalWidth = componentRef.current.style.width;
        const originalHeight = componentRef.current.style.height;
    
        // Define os estilos para impressão
        componentRef.current.style.width = '1082px';
        componentRef.current.style.height = '1530px';
    
        // Inicializa o jsPDF
        const pdf = new jsPDF('p', 'pt', 'a4');
        const elementsToPrint = componentRef.current.querySelectorAll('.capa, .paginas, .verso');
    
        for (let i = 0; i < elementsToPrint.length; i++) {
            const element = elementsToPrint[i];
    
            try {
                // Verifica se o dispositivo é iOS
                const isIOS = !!window.navigator.userAgent.match(/iPhone|iPad|iPod/);
    
                // Define a escala baseada no dispositivo
                const canvasScale = isIOS ? 0.7 : 1;
                // Captura o elemento usando html2canvas com CORS habilitado
                const canvas = await html2canvas(element, {
                    scale: canvasScale,
                    useCORS: true,
                    allowTaint: false, // Evita capturar recursos sem CORS válido
                });
    
                // Gera a imagem da captura
                const imgData = canvas.toDataURL('image/jpeg', 0.7);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    
                if (i > 0) {
                    pdf.addPage();
                }
    
                // Adiciona a imagem ao PDF
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'FAST');
            } catch (error) {
                console.error("Erro ao capturar elemento para PDF:", error);
            }
        }
    
        // Salva o PDF com o nome especificado
        pdf.save('Proposta_1021.pdf');
    
        // Retorna os estilos originais
        componentRef.current.style.width = originalWidth;
        componentRef.current.style.height = originalHeight;
    };
    

    return (
        <div>
            <MyComponentToPrint ref={componentRef} proposta={proposta} />
            <div className="barPrint">
                <button className='Btns ButtonPrimary' onClick={handleDownloadPDF}>Baixar Proposta</button>
            </div>
        </div>
    );
}

export default ImprimirProposta;
