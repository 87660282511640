import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/ImagensCarros/Searce';
import Filter from '../../components/ImagensCarros/Filter';
import ModalCategoria from '../../components/ImagensCarros/Marcas';
import ModalCategoriaEdit from '../../components/ImagensCarros/MarcasEdit';

//styles
import './ImagensCarros.scss';

//Imagens
import { useAuth } from '../../context/useAuth';
import api from '../../services/apiIdz';
import Alerts from '../../components/Alerts';
import { isNew } from '../../utils/dates';
import ItemMarca from '../../components/ImagensCarros/ItemMarca';

function ImagensCarros() {

    const { token } = useAuth()

    const [popupData, setPopupData] = useState({
        titulo: '',
        logo: '',
        status: 'ativo',
    })

    const [popupDataEdit, setPopupDataEdit] = useState({
        logo: '',
        status: 'ativo'
    })

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    //Obter informação inicial
    const [marcas, setMarcas] = useState([])
    const [readyFilter, setReadyFilter] = useState(false)
    const getMarcas = async () => {
        setReadyFilter(false)
        await api.get(`/api/marcas`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            if (res.status == 200) {
                setMarcas(res.data)
                setReadyFilter(true)
            }
        })
    }

    useEffect(() => {
        getMarcas()
    }, [])



    useEffect(() => {
        console.log(marcas)
    }, [marcas])

    const navigate = useNavigate();
    const [showMarcas, setshowMarcas] = useState(false);
    const [showMarcasEdit, setshowMarcasEdit] = useState(false);
    const [isPlaying, setIsPlaying] = useState();
    const [tooltipId, setTooltipId] = useState("my-tooltip-play");

    const [selectedId, setSelectedId] = useState(null)
    const [currentEndpoint, setCurrentEndpoint] = useState(null)
    const [showDelete, setShowDelete] = useState(false)

    const showModalMarcasDelete = (id, endpoint) => {
        setShowDelete(true)
        setSelectedId(id)
        setCurrentEndpoint(endpoint)
    }

    const showModalMarcas = () => {
        setshowMarcas(!showMarcas);
    };
    const showModalMarcaEdit = async (id) => {
        setshowMarcasEdit(true);
        setSelectedId(id)
        await getMarca(id)
    };
    const closeModalMarcasEdit = (id) => {
        setshowMarcasEdit(false);
        setSelectedId(null)
    };

    const postCategoria = async () => {
        await api.post(`/api/marcas`, popupData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    setshowMarcas(false)
                    getMarcas()
                }
            }
        })
    }
    const putMarca = async (id) => {
        await api.put(`/api/marcas/${id}`, popupDataEdit, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram editados com êxito.", "success")
                    getMarcas()
                    closeModalMarcasEdit()
                    setPopupDataEdit({});
                }
            }
        })
    }

    const getMarca = async (id) => {
        await api.get(`/api/marcas/${id}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            if (res.status == 200) {
                console.log(res.data)
                setPopupDataEdit(res.data)
            }
        })
    }

    useEffect(() => {
        console.log(popupData)
    }, [popupData])


    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }
    const handlePopupDataEdit = (value, field) => {
        setPopupDataEdit({
            ...popupDataEdit,
            [field]: value
        })
    }
    const togglePlayPause = async () => {
        setIsPlaying(!isPlaying);
    }

    const handleImagem = (image) => {
        handlePopupData(image, 'logo')
    }

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])
    const handleFiltered = (rawData) => {

        let tmpCategoriaList

        if (status != null && status != '') {
            if (status == "news") {
                console.log("FILTERING ONLY SEARCH WITH NEW Marcas")
                tmpCategoriaList = rawData.filter(Marcas_d => Marcas_d.titulo.toLowerCase().includes(search) && isNew(Marcas_d))
            } else {
                console.log("FILTERING ONLY SEARCH AND STATUS CODE")
                tmpCategoriaList = rawData.filter(Marcas_d => Marcas_d.titulo.toLowerCase().includes(search) && Marcas_d.status.toLowerCase() == status)
            }
        } else {
            console.log("FILTERING ONLY SEARCH")
            tmpCategoriaList = rawData.filter(Marcas_d => Marcas_d.titulo.toLowerCase().includes(search))
        }
        console.log("after filter", tmpCategoriaList)

        setFiltered(tmpCategoriaList)
    }

    useEffect(() => {
        // if (readyFilter) {
        console.log(marcas)
        console.log(search, status.name)
        handleFiltered(marcas)
        // }
    }, [search, status, readyFilter])


    const handleMarcaStatus = async (id) => {
        try {
            const marcaToUpdate = marcas.find(item => item.id === id);

            const newStatus = marcaToUpdate.status === 'Ativo' ? 'Inativo' : 'Ativo';

            await api.put(`/api/marcas/${id}`, { status: newStatus }, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    Token: token
                }
            });

            const updatedMarcas = marcas.map(item => {
                if (item.id === id) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setMarcas(updatedMarcas);

            console.log("Categoria atualizada com sucesso!");

        } catch (error) {
            console.error('Erro ao atualizar o status:', error);
        }
    };


    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar />
            <div className="Content Marcas">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderMarcas'>
                                <i><Icon icon="nimbus:marketing" ></Icon></i>
                                <h2>Marcas <br />
                                    <span>Gerencie marcas de veículos no painel administrativo.</span></h2>
                                <Alert />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableMarcas'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsMarcas'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={showModalMarcas}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listMarcas'>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <div className='listMarcas'>
                                                    <Row>
                                                        {
                                                            filtered?.map(item => (
                                                            <>
                                                                <ItemMarca
                                                                    item={item}
                                                                    showModalMarcaEdit={showModalMarcaEdit}
                                                                    handleMarcaStatus={handleMarcaStatus}
                                                                    showModalMarcasDelete={showModalMarcasDelete}
                                                                    tooltipId={tooltipId}
                                                                    navigate={navigate}
                                                                    token={token}
                                                                    handleAlert={handleAlert}
                                                                    getMarcas={getMarcas}
                                                                    
                                                                />
                                                            </>
                                                            ))
                                                        }
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-adicionar" place="bottom" content="Adicionar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-pausar" place="bottom" content="Pausar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-ativar" place="bottom" content="Ativar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
            <ModalCategoria show={showMarcas} handlePopupData={handlePopupData} postCategoria={postCategoria} popupData={popupData} />
            <ModalCategoriaEdit show={showMarcasEdit} selectedId={selectedId} token={token} handlePopupDataEdit={handlePopupDataEdit} closeModalMarcasEdit={closeModalMarcasEdit} putMarca={putMarca} popupDataEdit={popupDataEdit} />
            <ReactTooltip
                id={tooltipId}
                place="bottom"
                content={isPlaying ? "Pausar banner" : "Ativar banner"}
                style={{ backgroundColor: "#FFFFFF", color: "#000000" }}
            ></ReactTooltip>

        </>
    );
}
export default ImagensCarros;