import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from 'react-router-dom';

//components
import Navbar from "../../../components/Navbar";
import Alerts from '../../../components/Alerts';
import Searce from '../../../components/Blindagem/Searce';
import ModalProduto from '../../../components/Blindagem/Produto';
import ModalProdutoEdit from '../../../components/Blindagem/ProdutoEdit';
import ModalEspecificacao from '../../../components/Blindagem/Especificacao';
import ModalEspecificacaoEdit from '../../../components/Blindagem/EspecificacaoEdit';
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';

//styles
import './EditarBlindagem.scss';
import ModalDelete from '../../../components/Blindagem/ModalDelete';



function EditarBlindagem() {

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])


    const { id } = useParams();
    const { token } = useAuth()
    const navigate = useNavigate();

    const [searchArray, setSearchArray] = useState([])


    const [categoria, setCategoria] = useState({})
    const [especStatus, setSpecStatus] = useState([])



    const getCategoria = async () => {
        await api.get(`/api/categorias/${id}?status=all`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            console.log(res.data['especificacoes'])
            if (res.status === 200) {
                console.log(res.data)
                setCategoria(res.data);

                res.data.status == "Ativo" ? setAtivo(true) : setAtivo(false)

                let aux = []
                res.data['especificacoes'].forEach(() => {
                    aux.push('')
                })
                setSearchArray(aux)

                let aux2 = []
                res.data['especificacoes'].forEach((item) => {
                    aux2.push(item)
                })
                setSpecStatus(aux2)
            }
        }).catch(error => {
            // Tratar erro, se necessário
            console.error("Erro ao buscar categorias:", error);
        });
    };

    useEffect(() => {
        getCategoria()
    }, [])


    const [showProduto, setshowProduto] = useState(false);
    const [showProdutoEdit, setshowProdutoEdit] = useState(false);
    const [showEspecificacao, setshowEspecificacao] = useState(false);
    const [showEspecificacaoEdit, setshowEspecificacaoEdit] = useState(false)
    const [selectedValue, setSelectedValue] = useState(null);
    const [ativo, setAtivo] = useState(false);

    const putEpecificacoesStatus = async () => {
        console.log(especStatus)
        const promises = especStatus.map(async (spec) => {
            console.log(spec)
            let objSpec = {
                status: spec.status,
                categoria: id
            }
            console.log("putEpecificacoesStatus objSpec line 114", spec, objSpec, objSpec.status)
            if (typeof spec.status == 'number') {
                objSpec = {
                    status: spec.status == 1 ? "Ativo" : "Inativo",
                    categoria: id
                }
            }
            console.log("putEpecificacoesStatus objSpec line 120", spec, objSpec, objSpec.status)

            try {
                await api.put(`/api/especificacoes-blindagem/${spec.id}`, objSpec, {
                    headers: {
                        'Authorization': process.env.REACT_APP_API_KEY,
                        "Token": token
                    }
                }).then(r => {
                    console.log(r)

                    if (typeof r.data == 'string') {
                        handleAlert("Campos inválidos", r.data, "danger")
                    } else {
                        if (r.status == 200) {
                            handleAlert("Sucesso", "Os dados foram atualizados com êxito.", "success")
                            getCategoria()
                        }
                    }
                })
            } catch (err) {
                throw err;
            }

        })

        Promise.all(promises).then(() => {
            getCategoria()
        })
 
    }



    const putCategoriaStatus = async () => {
        await api.put(`/api/categorias/${id}`, {
            status: ativo ? "Ativo" : "Inativo"
        }, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(async (r) => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram apagados com êxito.", "success")
                    //getCategoria()

                    await putEpecificacoesStatus()
                }
            }
        })
    }

    const [isPreco, setIsPreco] = useState(false);
    const [isVidro, setIsVidro] = useState(false);
    const [isTeto, setIsTeto] = useState(false);
    const [isTampa, setIsTampa] = useState(false);
    const [isInter, setIsInter] = useState(false);
    const [isTraseiros, setIsTraseiros] = useState(false);

    const [selectedId, setSelectedId] = useState(null)

    const showModalProduto = (id) => {
        console.log("ESPECIFICACAO ID:", id)
        setshowProduto(true);
        setSelectedId(id)
    };
    const closeModalProduto = () => {
        setshowProduto(false);
        setSelectedId(null)
        console.log("ESPECIFICACAO ID:", selectedId)
    };


    const showModalProdutoEdit = (id) => {
        console.log("PRODUTO ID:", id)
        setshowProdutoEdit(true);
        setSelectedId(id)
    };
    const closeModalProdutoEdit = () => {
        setshowProdutoEdit(false);
        setSelectedId(null)
        console.log("PRODUTO ID:", selectedId)
    };

    const showModalEspecificacao = () => {
        setshowEspecificacao(!showEspecificacao);
    };

    const [idEdit, setidEdit] = useState();
    const showModalEspecificacaoEdit = (id) => {
        setshowEspecificacaoEdit(!showEspecificacaoEdit);
        setidEdit(id)
    };


    const options = [
        { value: 'hatch', label: 'Hatch' },
        { value: 'sedan', label: 'Sedan' },
        { value: 'suv', label: 'SUV' },
        { value: 'esportivo', label: 'Esportivo' },
        { value: 'picape', label: 'Picape' },
        { value: 'crossover', label: 'Crossover' }
    ];

    const handleChange = (event) => {
        setSelectedValue(event.value);
    };


    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const precoToggle = () => {
        setIsPreco(!isPreco);
    };

    const handleSwitchToggle = () => {
        setAtivo(!ativo);
        setCategoria({
            ...categoria,
            status: !ativo ? "Ativo" : "Inativo"
        })
    };

    const [showDelete, setShowModalCategoriaDelete] = useState(false)
    const [currentEndpoint, setCurrentEndpoint] = useState('')

    const handleEndpoint = (end) => {
        setCurrentEndpoint(end)
    }

    const showModalCategoriaDelete = (id, endpoint) => {
        setShowModalCategoriaDelete(true);
        setSelectedId(id)
        handleEndpoint(endpoint)
    };
    const closeModalCategoriaDelete = () => {
        setSelectedId(null)
        setShowModalCategoriaDelete(false);
        handleEndpoint(null)
    };

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalCategoriaDelete(false);
        handleEndpoint(null)
        getCategoria()
    }

    useEffect(() => {
        console.log(categoria)
    }, [ativo])



    const handleSearchDynamic = (index, value) => {
        let aux = [...searchArray]
        aux[index] = value
        setSearchArray(aux)
    }

    const handleSpecDynamic = (index, value) => {
        let aux = [...especStatus]
        aux[index].status = value
        setSpecStatus(aux)
    }

    useEffect(() => {
        console.log(searchArray)
        console.log(categoria)

    }, [searchArray])

    useEffect(() => {
        console.log(especStatus)
    }, [especStatus])

    return (
        <>

            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar />
            <div className="Content Blindagem">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderBlindagem'>
                                <i><Icon icon="wpf:security-checked" ></Icon></i>
                                <h2>Blindagem Especificações<br />
                                    <span>Gerenciamento de Blindagem: Especificações Personalizadas</span></h2>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='formBlindagem'>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='HeaderForm'>
                                        <h2>{categoria?.titulo}</h2>
                                        <div className='statusVeiculo'>
                                            <Form.Check className='Label' type="switch"
                                                label={ativo ? "Tipo ativo" : "Tipo desativado"}
                                                checked={ativo}
                                                onChange={(e) => {
                                                    handleSwitchToggle()
                                                }}

                                            ></Form.Check>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={8} lg={12} className='text-right'>
                                    <Form.Group className="Form">
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} className='acoesEspecificacao'>
                                                <Button onClick={showModalEspecificacao} className='Btns ButtonSecondary'>Adicionar Especificação</Button>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={12}>
                                                <Accordion>

                                                    {categoria?.especificacoes?.map((espec, index) => (
                                                        <Accordion.Item eventKey={index} key={index}>
                                                            <Accordion.Header>
                                                                <h4>{espec.titulo}</h4>
                                                                <i><Icon icon='ri:add-fill' /></i>
                                                                <i className={`prevStatus ${especStatus[index].status == 1 ? 'sucess' : 'danger'}`}></i>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                                        <Searce handleSearchDynamic={handleSearchDynamic} index={index} />
                                                                    </Col>
                                                                    <Col xs={12} sm={12} md={6} lg={6} className='statusAdcionais'>
                                                                        <Button data-tooltip-id="my-tooltip-delete" className='Btns ButtonIcon' onClick={() => showModalCategoriaDelete(espec.id, "especificacoes-blindagem")}><i><Icon icon="mdi:trash"></Icon></i></Button>
                                                                        <Button className='Btns ButtonSecondary' onClick={() => { showModalEspecificacaoEdit(espec.id) }}>Editar</Button>

                                                                        <Form.Check className='Label' type="switch"
                                                                            label={especStatus[index].status == 1 ? "ativo" : "desativado"}
                                                                            checked={especStatus[index].status}
                                                                            onChange={() => handleSpecDynamic(index, especStatus[index].status == 1 ? 0 : 1)}
                                                                        ></Form.Check>

                                                                    </Col>
                                                                    <Col xs={12} sm={12} md={6} lg={12}>
                                                                        <div className='cadastroItem'>
                                                                            {
                                                                                espec?.produtos?.filter(d => d.titulo.toLowerCase().includes(searchArray[index])).map((produto, index) => (
                                                                                    <div className='item' key={index}>
                                                                                        <div className='infos'>
                                                                                            {produto?.melhorescolha ? (<i><Icon icon='mdi:crown' /></i>) : null}
                                                                                            <h3>{produto?.titulo}</h3>
                                                                                            <span>{produto?.descricao} </span>
                                                                                            <h4>{produto?.preco}</h4>
                                                                                            {espec.sku === "tipo_vidro" ? <p>( {produto?.garantia} de anos garantia )</p> : ''}
                                                                                        </div>
                                                                                        <div className='Acoes'>
                                                                                            <button className='Btns ButtonIcon' onClick={() => { showModalCategoriaDelete(produto?.id, "produtos") }}><Icon icon="iconamoon:close-duotone"></Icon></button>
                                                                                            <button className='Btns ButtonIcon' onClick={() => { showModalProdutoEdit(produto?.id) }}><Icon icon="ri:edit-fill"></Icon></button>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                            <div className='addVariacao' onClick={() => { showModalProduto(espec?.id) }}>
                                                                                <Button className='Btns Add'><Icon icon="ic:baseline-plus"></Icon></Button>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))}

                                                </Accordion>
                                            </Col>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} className='acoes'>
                                                    <Form.Group className="">
                                                        <Button className='Btns ButtonSecondary'>Voltar</Button>
                                                        <Button className='Btns ButtonPrimaryLight' onClick={putCategoriaStatus}>Salvar</Button>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                </Container>
            </div>
            <ModalProduto show={showProduto} showModalProduto={showModalProduto} closeModalProduto={closeModalProduto} especificacaoId={selectedId} getCategoria={getCategoria} ProdutoAlertas={handleAlert} />
            <ModalProdutoEdit show={showProdutoEdit} showModalProduto={showModalProdutoEdit} closeModalProduto={closeModalProdutoEdit} produtoId={selectedId} getCategoria={getCategoria} ProdutoAlertas={handleAlert} />
            <ModalEspecificacao show={showEspecificacao} id={id} EspecificacaoAlertas={handleAlert} idCategoria={id} handleAlert={handleAlert} getCategoria={getCategoria} />
            <ModalDelete
                deleteId={selectedId}
                endpoint={currentEndpoint}
                show={showDelete}
                handleClose={closeModalCategoriaDelete}
                handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                handleAlert={handleAlert}
            />
            <ModalEspecificacaoEdit show={showEspecificacaoEdit} idEdit={idEdit} EspecificacaoAlertas={handleAlert} />
        </>
    );
}
export default EditarBlindagem;