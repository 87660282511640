import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';

// Estilos
import "./Variacao.scss";


// Componentes
import IdzFileUpload from '../../Veiculos/idzFileUpload';
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';


function Variacao({ show, ProdutoAlertas, closeModalProduto, showModalProduto, especificacaoId, getServico, servicoId, editing, selectedId }) {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isProduto, setIsProduto] = useState(false);
    const [isDatePickerVisible, setDatePickerVisible] = useState(true);
    const [isVariacao, setIsVariacao] = useState(false);
    const [isIndividual, setIsIndividual] = useState(false);

    const [popupData, setPopupData] = useState({
        servico: servicoId,
        titulo: '',
        subtitulo: "",
        garantia: 5,
        descricao: '',
        imagem: "",
        video: "",
        status: isProduto ? 'Ativo' : 'Desativado',
        individual: isIndividual ? 1 : 0
    });

    useEffect(() => {
        if (servicoId) {
            setPopupData(prevState => ({
                ...prevState,
                servico: servicoId
            }));
        }
    }, [servicoId]);

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show]);

    useEffect(() => {
        if (editing) {
            const getCurrentVariacao = async () => {
                try {
                    const r = await api.get(`/api/opcional-estetica/${selectedId}`);
                    if (typeof r.data === 'string') {
                        ProdutoAlertas("Campos inválidos", r.data, "danger");
                    } else if (r.status === 200) {
                        setPopupData(r.data);
                        setIsVariacao(r.data.status === 'Ativo');
                        setIsIndividual(r.data.individual === 1);
                    }
                } catch (error) {
                    console.error('Erro ao obter variação:', error);
                }
            };
            getCurrentVariacao();
        }
    }, [editing, selectedId, ProdutoAlertas]);

    const variacaoToggle = () => {
        const newStatus = !isVariacao ? 'Ativo' : 'Desativado';
        setIsVariacao(!isVariacao);
        handlePopupData(newStatus, 'status');
    };

    const individualToggle = () => {
        const newIndividual = !isIndividual ? 1 : 0;
        setIsIndividual(!isIndividual);
        handlePopupData(newIndividual, 'individual');
    };

    const handlePopupData = (value, field) => {
        setPopupData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleImagem = (image) => {
        handlePopupData(image, 'imagem');
    };

    const clearData = () => {
        setPopupData({
            servico: servicoId,
            titulo: '',
            subtitulo: "",
            garantia: 5,
            descricao: '',
            imagem: "",
            video: "",
            status: isProduto ? 'Ativo' : 'Desativado',
            individual: isIndividual ? 1 : 0
        });
        setIsVariacao(false);
        setIsIndividual(false);
    };

    const handleClose = () => {
        setShowModal(false);
        closeModalProduto();
        clearData();
    };

    const postProduto = async () => {
        try {
            const r = await api.post(`/api/opcional-estetica`, popupData, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });
            if (typeof r.data === 'string') {
                ProdutoAlertas("Campos inválidos", r.data, "danger");
            } else if (r.status === 200) {
                ProdutoAlertas("Sucesso", "Os dados foram cadastrados com êxito.", "success");
                getServico();
                handleClose();
            }
        } catch (error) {
            console.error('Erro ao cadastrar produto:', error);
        }
    };

    const putProduto = async () => {
        try {
            const r = await api.put(`/api/opcional-estetica/${selectedId}`, popupData, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });
            if (typeof r.data === 'string') {
                ProdutoAlertas("Campos inválidos", r.data, "danger");
            } else if (r.status === 200) {
                ProdutoAlertas("Sucesso", "Os dados foram editados com êxito.", "success");
                getServico();
                handleClose();
            }
        } catch (error) {
            console.error('Erro ao editar produto:', error);
        }
    };

    const garantia = [
        { value: 0, label: 'Sem garantia' },
        { value: 1, label: '1 anos' },
        { value: 2, label: '2 anos' },
        { value: 3, label: '3 anos' },
        { value: 5, label: '5 anos' },
        { value: 10, label: '10 anos' }
      ];

    return (
        <>

            <Modal show={showModal} className="ModalProduto">
                <Modal.Header >
                    <Modal.Title>CADASTRO DE VARIAÇÃO</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control className='Inputs' value={popupData["titulo"]} onChange={(e) => { handlePopupData(e.target.value, "titulo") }} type="text" placeholder="Título" />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label className='Label'>
                                    <Form.Label>Subtitulo</Form.Label>
                                    <Form.Control className='Inputs' value={popupData["subtitulo"]} onChange={(e) => { handlePopupData(e.target.value, "subtitulo") }} type="text" placeholder="Subtitulo" />
                                </Form.Label>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label className='Label'>
                                    <Form.Label>Garantia</Form.Label>
                                    <Dropdown value={popupData["garantia"]} onChange={(e) => handlePopupData(e.target.value, "garantia")} options={garantia} optionLabel="label" placeholder="Escolha a Garantia" className='Dropdown' />
                                </Form.Label>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label>Descrição</Form.Label>
                                <textarea className="" value={popupData["descricao"]} onChange={(e) => { handlePopupData(e.target.value, "descricao") }} placeholder='Descrição'></textarea>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={6}>
                            <Form.Group className="mb-4">
                                <Form.Check
                                    className='Label'
                                    type="switch"
                                    label={isVariacao ? "Ativo" : "Desativado"}
                                    name="status"
                                    checked={isVariacao}
                                    onChange={variacaoToggle}
                                ></Form.Check>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={6}>
                            <Form.Group className="mb-4">
                                <Form.Check
                                    className='Label'
                                    type="switch"
                                    label={isIndividual ? "Individual" : "Multiplo"}
                                    name="individual"
                                    checked={isIndividual}
                                    onChange={individualToggle}
                                ></Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <h4>Links</h4>
                                    <i><Icon icon='ri:add-fill' /></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={12}>
                                            <Form.Label className='Label'>
                                                <Form.Label>link do vídeo</Form.Label>
                                                <div class="FormControl">
                                                    <Form.Control className='Inputs' value={popupData["video"]} onChange={(e) => { handlePopupData(e.target.value, "video") }} type="text" placeholder="Link de apresentação" />
                                                    <i><Icon icon="ic:baseline-link"></Icon></i>
                                                </div>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <h4>Imagens</h4>
                                    <i><Icon icon='ri:add-fill' /></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={12}>
                                            <Form.Group className='Label'>
                                                <Form.Label className='Label'>Foto ilustrativa</Form.Label>
                                                <IdzFileUpload currentFile={popupData["imagem"]} handleUpload={handleImagem} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Form.Group className="text-center">
                        {
                            editing ?
                                <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={putProduto}>
                                    Salvar Edição
                                </Button> :
                                <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={postProduto}>
                                    Salvar
                                </Button>
                        }
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Form.Group>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default Variacao;
