import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"

//styles
import './BannersNovo.scss';
import "react-datepicker/dist/react-datepicker.css";
import api from '../../../services/apiIdz';
import IdzFileUpload from '../../../components/Veiculos/idzFileUpload';
import Alerts from '../../../components/Alerts';
import { useAuth } from '../../../context/useAuth';
import ReaisInput from '../../../components/ReaisInput';

function BannersNovo({ }) {

    const { token } = useAuth()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const [veiculos, setVeiculos] = useState([])
    const [veiculosDetailed, setVeiculosDetailed] = useState([])

    const [bannerData, setBannerData] = useState({
        tipo_banner: 2,
        campanha: {
            listagem: '',
            preco_de: '',
            video: '',
        },
        continuo: 0,
        data_inicio: new Date(),
        desktop: {
            imagem: ''
        },
        mobile: {
            imagem: ''
        },
        tablet: {
            imagem: ''
        },
        tablet_mobile: 0,
        status: "Ativo",
    })

    useEffect(() => {
        console.log(bannerData)
    }, [bannerData])

    const handleBannerInput = (value, field) => {
        console.log(`[${field}]:${value}`)
        setBannerData({
            ...bannerData,
            [field]: value
        })
    }
    const handleBanner2ndInput = (value, field1, field2) => {
        console.log(`[${field1}][${field2}]:${value}`)
        setBannerData({
            ...bannerData,
            [field1]: {
                ...bannerData[field1],
                [field2]: value
            }
        })
    }





    async function getVeiculos() {
        await api.get(`/api/veiculos?status=ativo`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(async (res) => {
            if (res.data) {
                console.log(res.data)
                handleVeicleList(res.data)
            }
        })
    }

    const handleVeicleList = (list) => {

        let listAux = []
        list.forEach((car) => {
            listAux.push({ label: car.modelo, value: car.id })
        });

        setVeiculosDetailed(list)
        setVeiculos(listAux)
    }

    useEffect(() => {
        getVeiculos()
    }, [])

    const postData = async () => {
        console.log("Enviando banners")
        await api.post(`/api/banners`, bannerData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                }
            }
        })
    }



    useEffect(() => {
        console.log(bannerData["tipo_banner"])
        if (bannerData["tipo_banner"] === 1) {
            const auxBanner = { ...bannerData }
            // delete auxBanner["veiculo_id"]
            setBannerData(auxBanner)
        } else if (bannerData["tipo_banner"] === 3){
            const auxBanner = { 
                ...bannerData,
                texto_apoio: bannerData["texto_apoio"],
                descricao_banner: bannerData["descricao_banner"],
             }
        } else {
            const auxBanner = { ...bannerData }

            // delete auxBanner["label_botao"]
            // delete auxBanner["link"]
            // delete auxBanner["campanha"]
            // delete auxBanner["titulo"]
            // delete auxBanner["chamada"]


            setBannerData(auxBanner)
        }
        console.log(bannerData)
    }, [bannerData["tipo_banner"]])

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Evita que o formulário seja enviado ao pressionar Enter
            const newTag = inputValue.trim();
            if (newTag && tags.length < 4) { // Verifica se há menos de 4 tags
                setTags([...tags, newTag]);
                setInputValue('');
            }
        }
    };

    const handleRemoveTag = (indexToRemove) => {
        setTags(tags.filter((tag, index) => index !== indexToRemove));
    };

    useEffect(() => {
        handleBanner2ndInput(tags, "campanha", "listagem")
    }, [tags])

    useEffect(() => {
        const today = new Date();
        const thirtyDaysLater = new Date();
        thirtyDaysLater.setDate(today.getDate() + 30);
        handleBannerInput(thirtyDaysLater, "data_final")
    }, []); // Executa apenas uma vez, quando o componente é montado


    const handleBannerDesk = (image) => {
        handleBanner2ndInput(image, "desktop", "imagem")
    }
    const handleBannerMobile = (image) => {
        handleBanner2ndInput(image, "mobile", "imagem")
    }
    const handleBannerTablet = (image) => {
        handleBanner2ndInput(image, "tablet", "imagem")
    }

    const [isMobile, setIsMobile] = useState(false)
    const toggleCheckIsMobile = () => {
        setIsMobile(!isMobile)
        handleBanner2ndInput(!isMobile ? 1 : 0, "tablet", "is_mobile")
    }

    const [isContinuo, setIsContinuo] = useState(false)
    const toggleCheckIsContinuo = () => {
        setIsContinuo(!isContinuo)
        handleBannerInput(!isContinuo ? 1 : 0, "continuo")
    }

    const handlePrecoInput = (value) => {
        handleBanner2ndInput(value, "campanha", "preco_de")
    }

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar />
            <div className="Content Banners">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderBanners'>
                                <i><Icon icon="nimbus:marketing" ></Icon></i>
                                <h2>Cadastro de Banners<br />
                                    <span>Adicione um novo banner ao seu site.</span></h2>
                                <Alert />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NovoBanners'>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>TIPO DE BANNER</h3>
                                        <Form.Check
                                            className='Label'
                                            type="radio"
                                            label="Veículo"
                                            name="TipoBanner"
                                            value={2}
                                            onChange={(e) => handleBannerInput(e.target.value, "tipo_banner")}
                                            checked={bannerData["tipo_banner"] == 2}
                                        />
                                        <Form.Check
                                            className='Label'
                                            type="radio"
                                            label="Serviço"
                                            name="TipoBanner"
                                            value={1}
                                            onChange={(e) => handleBannerInput(e.target.value, "tipo_banner")}
                                            checked={bannerData["tipo_banner"] == 1}
                                        />
                                        <Form.Check
                                            className='Label'
                                            type="radio"
                                            label="Campanha"
                                            name="TipoBanner"
                                            value={3}
                                            onChange={(e) => handleBannerInput(e.target.value, "tipo_banner")}
                                            checked={bannerData["tipo_banner"] == 3}
                                        />
                                        <hr />
                                    </Col>
                                </Row>
                                {bannerData["tipo_banner"] == 2 && (
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3>veículo</h3>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Form.Label className='Label'>
                                                <span>Modelo do veículo</span>
                                                <Dropdown value={bannerData["veiculo_id"]} onChange={(e) => handleBannerInput(e.target.value, "veiculo_id")} options={veiculos} optionLabel="label" placeholder="Escolha o Veículo" className='Dropdown' />
                                            </Form.Label>
                                            <hr />
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>conteúdo do banner</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={6}>
                                                <Form.Label className='Label'>
                                                    <Form.Control className='Inputs' value={bannerData?.campanha?.video} onChange={(e) => handleBanner2ndInput(e.target.value, "campanha", "video")} type="text" placeholder="Link vídeo" />
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <h3>programação do seu banner</h3>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Form.Check
                                                    className='Label'
                                                    type="checkbox"
                                                    label="Datas contínuas"
                                                    checked={isContinuo}
                                                    onChange={toggleCheckIsContinuo}
                                                />
                                            </Col>
                                            {!isContinuo && (
                                                <Col xs={12} sm={12} md={4} lg={4} className='DatePicker'>
                                                    <DatePicker
                                                        showIcon
                                                        selected={bannerData["data_inicio"]}
                                                        onChange={(date) => handleBannerInput(date, "data_inicio")}
                                                        selectsStart
                                                        startDate={bannerData["data_inicio"]}
                                                        endDate={bannerData["data_final"]}
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                    <DatePicker
                                                        showIcon
                                                        selected={bannerData["data_final"]}
                                                        onChange={(date) => handleBannerInput(date, "data_final")}
                                                        selectsEnd
                                                        startDate={bannerData["data_inicio"]}
                                                        endDate={bannerData["data_final"]}
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                    <hr />
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>

                                {(bannerData["tipo_banner"] == 3 || bannerData["tipo_banner"] == 1)  && (<>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3>conteúdo do banner</h3>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={8}>
                                            <Row>
                                                <Col xs={12} sm={12} md={4} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <Form.Control className='Inputs' value={bannerData["titulo"]} onChange={(e) => handleBannerInput(e.target.value, "titulo")} type="text" placeholder="Título do banner" />
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <Form.Control className='Inputs' value={bannerData["chamada"]} onChange={(e) => handleBannerInput(e.target.value, "chamada")} type="text" placeholder="Chamada do banner" />
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={6}>
                                                    <Form.Label className='Label'>
                                                        <Form.Control className='Inputs' value={bannerData?.campanha?.video} onChange={(e) => handleBanner2ndInput(e.target.value, "campanha", "video")} type="text" placeholder="Link vídeo" />
                                                    </Form.Label>
                                                </Col>
                                                {bannerData["tipo_banner"] == 1 && (<>
                                                <Col xs={12} sm={12} md={4} lg={6}>
                                                    <Form.Label className='Label'> 
                                                        <ReaisInput value={bannerData?.campanha?.preco_de} handleChange={handlePrecoInput} placeholder="Preços a partir:"/>
                                                    </Form.Label>
                                                </Col>
                                                </>)}
                                                {bannerData["tipo_banner"] == 3 && (<>
                                                <Col xs={12} sm={12} md={4} lg={6}>
                                                    <Form.Label className='Label'> 
                                                        <Form.Control className='Inputs'value={bannerData["texto_apoio"]} onChange={(e) => handleBannerInput(e.target.value, "texto_apoio")} type="text" placeholder="Texto de apoio" />
                                                    </Form.Label>
                                                </Col>
                                                </>)}
                                                {bannerData["tipo_banner"] == 3 && (<>
                                                <Col xs={12} sm={12} md={4} lg={12}>
                                                    <Form.Label className='Label'> 
                                                        <textarea  value={bannerData["descricao_banner"]} onChange={(e) => handleBannerInput(e.target.value, "descricao_banner")} placeholder="Descrição do banner:"></textarea>
                                                    </Form.Label>
                                                </Col>
                                                </>)}
                                                <Col xs={12} sm={12} md={4} lg={12}>
                                                    <Form.Label className='Label'>Listagem</Form.Label>
                                                    <div className='listagem'>
                                                        {tags.map((tag, index) => (
                                                            <div key={index} className="tag">
                                                                {tag}
                                                                <i onClick={() => handleRemoveTag(index)}>X</i>
                                                            </div>
                                                        ))}
                                                        {tags.length < 4 && (
                                                            <Form.Control
                                                                value={inputValue}
                                                                onChange={(e) => setInputValue(e.target.value)}
                                                                onKeyDown={handleKeyDown}
                                                                placeholder="Digite sua lista"
                                                            />
                                                        )}
                                                    </div>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3>call to action</h3>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Row>
                                                <Col xs={12} sm={12} md={4} lg={4}>
                                                    <Form.Control className='Inputs' type="text" placeholder="Título do Botão" value={bannerData["label_botao"]} onChange={(e) => handleBannerInput(e.target.value, "label_botao")} />
                                                </Col>
                                                <Col xs={12} sm={12} md={4} lg={4}>
                                                    <Form.Label className='Label'>
                                                        <div class="FormControl">
                                                            <Form.Control className='Inputs' type="text" placeholder="Link de redirecionamento" value={bannerData["link"]} onChange={(e) => handleBannerInput(e.target.value, "link")} />
                                                            <i><Icon icon="ic:baseline-link"></Icon></i>
                                                        </div>
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>)}
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>imagens</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div class="ImagesUploader">
                                            {/* <ImagesUploader
                                                url="http://localhost:8080/notmultiple"
                                                optimisticPreviews
                                                multiple={false}
                                                onLoadEnd={(err) => {
                                                    if (err) {
                                                        console.error(err);
                                                    }
                                                }}
                                            />
                                            
                                            <span>
                                                <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                <h6>Desktop</h6>
                                                <p>Arquivos permitidos: .jpeg, .png</p>
                                            </span> */}
                                            <IdzFileUpload currentFile={bannerData["desktop"]?.imagem} handleUpload={handleBannerDesk} title='Desktop' subtitle='Arquivos permitidos: .jpeg, .png' />
                                        </div>
                                        <div class="ImagesUploader">
                                            {/* <ImagesUploader
                                                url="http://localhost:8080/notmultiple"
                                                optimisticPreviews
                                                multiple={false}
                                                onLoadEnd={(err) => {
                                                    if (err) {
                                                        console.error(err);
                                                    }
                                                }}
                                            />
                                            <span>
                                                <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                <h6>Mobile</h6>
                                                <p>Arquivos permitidos: .jpeg, .png</p>
                                            </span> */}
                                            <IdzFileUpload currentFile={bannerData["mobile"]?.imagem} handleUpload={handleBannerMobile} title='Mobile' subtitle='Arquivos permitidos: .jpeg, .png' />
                                        </div>
                                        <div class="ImagesUploader">
                                            {/* <ImagesUploader
                                                url="http://localhost:8080/notmultiple"
                                                optimisticPreviews
                                                multiple={false}
                                                onLoadEnd={(err) => {
                                                    if (err) {
                                                        console.error(err);
                                                    }
                                                }}
                                            />
                                            <span>
                                                <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                <h6>Tablet</h6>
                                                <p>Arquivos permitidos: .jpeg, .png</p>
                                            </span> */}
                                            <IdzFileUpload currentFile={bannerData["tablet"]?.imagem} handleUpload={handleBannerTablet} title='Tablet' subtitle='Arquivos permitidos: .jpeg, .png' />
                                            <Form.Check
                                                className='Label'
                                                type="checkbox"
                                                label="Usar a mesma versão do mobile"
                                                onChange={toggleCheckIsMobile}
                                                checked={isMobile}
                                            ></Form.Check>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='text-right'>
                                        <Form.Group className="FormNovo">
                                            <Button className='Btns ButtonSecondary'>Voltar</Button>
                                            <Button className='Btns ButtonPrimaryLight' onClick={postData}>Salvar</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ReactTooltip id="my-tooltip-diferenciais" place="bottom" content="Adicionar mais Diferenciais" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
        </>
    );
}
export default BannersNovo;