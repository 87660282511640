import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useMediaQuery } from 'react-responsive';

import Searce from '../../components/Leads/Searce';
import Filter from '../../components/Leads/Filter';

import LeadsList from '../../components/Leads/LeadsList';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import ModalDelete from '../../components/Leads/ModalDelete';

//styles
import './Leads.scss';
import AddLead from '../../components/Leads/Add';
import ViewLead from '../../components/Leads/ViewLead';
import api from '../../services/apiIdz';
import { useAuth } from '../../context/useAuth';
import Alerts from '../../components/Alerts';

//utils
import { isNew } from '../../utils/dates';
import { useLocation } from 'react-router-dom';

//Imagens 
import logo from '../Dashboard/img/logo-idzcar.png'
 

function MyArticles() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const { token } = useAuth()

    const location = useLocation()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const [showAdd, setshowAdd] = useState(false);
    const [showView, setshowView] = useState(false);
    const [filteredArticles, setFilteredArticles] = useState(null);
    const [searchName, setSearchName] = useState('');
    const [leads, setLeads] = useState([])

    const [veicleList, setVeicleList] = useState([])
    const [veicleToFilter, setVeicleToFilter] = useState([])


    const handleVeicleList = (list) => {
        setVeicleList(list)
    }

    async function getVeiculos() {
        await api.get(`/api/veiculos?status=ativo`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(async (res) => {
            if (res.data) {

                setVeicleToFilter(res.data)

                handleVeicleList(res.data)
            }
        })
    }


    const getLeads = async (queryParams = {}) => {
        let url = '/api/leads';

        if (Object.keys(queryParams).length > 0) {
            url += '?' + new URLSearchParams(queryParams).toString();
        }

        await api.get(url, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            console.log("LEADS", r.data);
            if (r.data) {
                console.log(r.data);

                let lista = [];

                for (let lead of r.data) {
                    lista.push({
                        id: lead.id,
                        status: lead.status,
                        data: lead.dtinc,
                        hora: lead.hora,
                        nome: lead.nome,
                        email: lead.email,
                        categoria: lead.tipo
                    });
                }

                setLeads(lista);
                setReadyFilter(true);
            }
        });
    };

    const [currentLead, setCurrentLead] = useState({})

    const setVisualizado = async (id) => {
        let stat = 'Visualizado'
        await api.put(`/api/leads/${id}`, {
            status: stat,
        }, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'Token': token
            }
        }).then(r => {
            console.log(r)
            if (r.data) {
                console.log(`status de ${id} alterado para ${stat}`)
                getLeads()
            }
        })
    }

    const getCurrentLead = async (id) => {
        await api.get(`/api/leads/${id}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'Token': token
            }
        }).then(r => {
            console.log(r)
            if (r.data) {
                setCurrentLead(r.data)
            }
        })
    }

    useEffect(() => {
        console.log(currentLead)
    }, [currentLead])

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const veiculoParam = queryParams.get('veiculo');

        if (veiculoParam !== null && veiculoParam !== undefined) {
            getLeads({ veiculo: veiculoParam });
        } else {
            getLeads();
        }
    }, []);

    useEffect(() => {
        getVeiculos()
    }, [])

    const handleFilterChange = (filter, nameFilter) => {
        setFilteredArticles(filter);
        setSearchName(nameFilter);
    };

    const showModalAdd = () => {
        setshowAdd(!showAdd);
    };
    const showModalView = (id) => {
        setshowView(true);
        getCurrentLead(id)
    };
    const closeModalView = () => {
        setshowView(false);
        setCurrentLead({})
    };

    const [readyFilter, setReadyFilter] = useState(false)

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search); // Obtenha os parâmetros de consulta da URL

        // Verifique se existe um parâmetro de consulta chamado 'search'
        if (params.has('search')) {
            const searchValue = params.get('search'); // Obtenha o valor do parâmetro 'search'
            console.log('Valor da querystring search:', searchValue);
            setSearch(searchValue.toLocaleLowerCase())
        }
    }, [location.search]);

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])


    const handleFiltered = (rawData) => {

        console.log("before filter", rawData)

        const params = new URLSearchParams(location.search); 

        let tmpLeadList

        console.log("Status", status, typeof status)
        console.log("Search", search, typeof search)

        if (status != null && status != '') {
            if (status == "news") {
                if (params.has('search')) {
                    const searchValue = params.get('search');
                    console.log("FILTERING ONLY SEARCH MODELO WITH NEW LEADS")
                    console.log(rawData)
                    tmpLeadList = rawData.filter(lead_d => lead_d.nome.toLowerCase().includes(search) && isNew(lead_d))
                } else {
                    console.log("FILTERING ONLY SEARCH WITH NEW LEADS")
                    tmpLeadList = rawData.filter(lead_d => lead_d.nome.toLowerCase().includes(search) && isNew(lead_d))
                }
            } else {
                if (params.has('search')) {
                    const searchValue = params.get('search');
                    console.log("FILTERING ONLY SEARCH MODELO AND STATUS CODE")
                    console.log(rawData)
                    tmpLeadList = rawData.filter(lead_d => lead_d.nome.toLowerCase().includes(search) && lead_d.status == status)
                } else {
                    console.log("FILTERING ONLY SEARCH AND STATUS CODE")
                    tmpLeadList = rawData.filter(lead_d => lead_d.nome.toLowerCase().includes(search) && lead_d.status == status)
                }

            }
        } else {

            if (params.has('search')) {
                const searchValue = params.get('search');
                console.log("FILTERING ONLY SEARCH MODELO")
                console.log(rawData)
                tmpLeadList = rawData.filter(lead_d => lead_d.nome.toLowerCase().includes(search))
            } else {
                console.log("FILTERING ONLY SEARCH")
                tmpLeadList = rawData.filter(lead_d => lead_d.nome.toLowerCase().includes(search))
            }

        }
        console.log("after filter", tmpLeadList)

        setFiltered(tmpLeadList)
    }

    useEffect(() => {

        handleFiltered(leads)
    }, [search, status, readyFilter, leads])


    const [showDelete, setShowModalLeadsDelete] = useState(false)
    const [selectedId, setSelectedId] = useState(null)

    const showModalLeadsDelete = (id) => {
        setShowModalLeadsDelete(true);
        setSelectedId(id)
    };
    const closeModalLeadsDelete = () => {
        setSelectedId(null)
        setShowModalLeadsDelete(false);
    };

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalLeadsDelete(false);
        getLeads()
    }

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Leads">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="tabler:user-star" ></Icon></i>
                                <h2>Leads <br />
                                    <span>Acompanhe e gerencie informações de clientes em potencial.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableLeads'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsLeads'>
                                        <Filter handleStatus={handleStatus} onFilterChange={handleFilterChange} />
                                        <Button className='Btns New' onClick={showModalAdd}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='List'>
                                        <LeadsList isNew={isNew} onViewButtonClick={showModalView}  showModalLeadsDelete={showModalLeadsDelete} leads={filtered} filter={filteredArticles} nameFilter={searchName} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
                <AddLead show={showAdd} veicleList={veicleList} getLeads={getLeads} handleAlert={handleAlert} />
                <ViewLead show={showView} setVisualizado={setVisualizado} closeModalView={closeModalView} currentLead={currentLead} getLeads={getLeads} token={token} />
            </div>
            <ModalDelete
                deleteId={selectedId}
                show={showDelete}
                handleClose={closeModalLeadsDelete}
                handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                handleAlert={handleAlert}
            />
        </>
    );
}
export default MyArticles;