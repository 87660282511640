import { Ring } from "@uiball/loaders";
import React from "react";

import "./AvatarLoading.scss"


export default function AvatarLoadingRing( ) {

    return (
         
        <div className="Avatar loading-ring">
            <Ring color='#fff' ></Ring>
        </div>
    )
}