import React from 'react';
import { useState } from 'react';
import './CodeConfirm.scss';
import NewPassword from '../NewPassword';
import { Icon } from '@iconify/react';

function CodeConfirm() {

  const [isValidCode, setValidCode] = useState(false);
  const [code, setCode] = useState('');

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const passwordInput = document.getElementById('password');
    const iconLock = document.getElementById('lock');

    if (code.trim() === '') {
      passwordInput.classList.add('passwordWrong');
      iconLock.classList.add('iconWrong');
      return;
    }
    if (parseInt(code) === 12345) {
      setValidCode(true);
    } else {
      passwordInput.classList.add('passwordWrong');
      iconLock.classList.add('iconWrong');
    }
  };

  if (isValidCode) {
    return <NewPassword />;
  }

  const handleSendCode = () => {
  }

  return (
    <div className="page-login">
      <div className="container-fluid">
        <div className='row'>
          <div className='col-12' id='logo'>
   
          </div>
          <div className='col-12' id='forms'>
            <div className='form'>
              <div className='title'>
                <h4> Informe o Código </h4>
                <h6> Informe o código que foi enviado para seu e-mail </h6>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <span className='icon' id="lock">
                    <Icon id="lock " icon="bx:lock" />
                  </span>
                  <input
                    type="text"
                    id="password"
                    value={code}
                    placeholder="Código"
                    onChange={handleCodeChange}
                  />
                </div>
                <div onClick={handleSendCode} className="sendCode">
                  Reenviar o código
                </div>
                <button className="code" type="submit"> Acessar </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CodeConfirm;
