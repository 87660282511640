import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/Paginas/Searce';
import Filter from '../../components/Paginas/Filter';
import List from '../../components/Paginas/List';

//utils
import { isNew } from '../../utils/dates';

//styles
import './Paginas.scss';
import api from '../../services/apiIdz';
import { useAuth } from '../../context/useAuth';
import Alerts from '../../components/Alerts';
import ModalDelete from '../../components/Paginas/ModalDelete';

// Imagens
import logo from '../Dashboard/img/logo-idzcar.png'

function PaginasInstitucionais() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const navigate = useNavigate();

    const { token } = useAuth()

    useEffect(() => {
        console.log(token)
    }, [token])

    const [showPaginas, setShowModalPaginas] = useState(false);
    const [showPaginasEdit, setShowModalPaginasEdit] = useState(false);
    const [showDelete, setShowModalPaginasDelete] = useState(false)

    const showModalPaginas = () => {
        setShowModalPaginas(true);
    };
    const closeModalPaginas = () => {
        setShowModalPaginas(false);
    };

    const [selectedId, setSelectedId] = useState(null)

    const showModalPaginasDelete = (id) => {
        setShowModalPaginasDelete(true);
        setSelectedId(id)
    };
    const closeModalPaginasDelete = () => {
        setSelectedId(null)
        setShowModalPaginasDelete(false);
    };


    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    //Obter informação inicial
    const [paginas, setPaginas] = useState()
    const [readyFilter, setReadyFilter] = useState(false)
    const getPaginas = async () => {
        setReadyFilter(false)
        await api.get(`/api/conteudo-institucional`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            if (res.status == 200) {
                setPaginas(res.data)
                setReadyFilter(true)
            }
        })
    }

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalPaginasDelete(false);
        getPaginas()
    }

    useEffect(() => {
        getPaginas()
    }, [])

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])


    const handleFiltered = (rawData) => {

        console.log("before filter", rawData)

        let tmpPaginaList

        console.log("Status", status, typeof status)
        console.log("Search", search, typeof search)

        if (status != null && status != '') {
            if (status == "news") {
                console.log("FILTERING ONLY SEARCH WITH NEW PAGINAS")
                tmpPaginaList = rawData.filter(paginas_d => paginas_d.titulo.toLowerCase().includes(search) && isNew(paginas_d))
            } else {
                console.log("FILTERING ONLY SEARCH AND STATUS CODE")
                tmpPaginaList = rawData.filter(paginas_d => paginas_d.titulo.toLowerCase().includes(search) && paginas_d.status == status)
            }
        } else {
            console.log("FILTERING ONLY SEARCH")
            tmpPaginaList = rawData.filter(paginas_d => paginas_d.titulo.toLowerCase().includes(search))
        }
        console.log("after filter", tmpPaginaList)

        setFiltered(tmpPaginaList)
    }

    useEffect(() => {
        if (readyFilter) {
            handleFiltered(paginas)
        }
    }, [search, status, readyFilter])

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar  menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Paginas">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="icon-park-outline:config" ></Icon></i>
                                <h2>Páginas <br />
                                    <span>Explore informações sobre a organização.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tablePaginas'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsPaginas'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={() => navigate("/paginas/novo")}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='list'>
                                        <Row>
                                            <List paginas={filtered}  showModalPaginasDelete={showModalPaginasDelete} />
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
            <ModalDelete
                deleteId={selectedId}
                show={showDelete}
                handleClose={closeModalPaginasDelete}
                handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                handleAlert={handleAlert}
            />
        </>
    );
}
export default PaginasInstitucionais;