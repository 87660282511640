import React, { useState, Component, useEffect, useRef } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';

//Components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import api from '../../services/apiIdz';
import { useAuth } from '../../context/useAuth';
import Alerts from '../../components/Alerts';

//Styles
import './MinhaConta.scss';
import "react-datepicker/dist/react-datepicker.css";

//Imagem
import avatar from "./img/avatar.png"
import AvatarLoadingRing from '../../components/AvatarLoading';

function MinhaConta() {

    // Alertas

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 4000);
        }
    }, [isVisible])

    const urlSrc = process.env.REACT_APP_API_URL

    const { token, getInitials, user } = useAuth();
    const inputRef = useRef();
    const [files, setFiles] = useState(null)
    const [thumb, setThumb] = useState([])
    const [avatarComp, setAvatarComp] = useState(<>
        <AvatarLoadingRing></AvatarLoadingRing>
    </>)


    const [loadingAvatar, setLoadingAvatar] = useState(true)

    useEffect(() => {
        if (files) {
            console.log(files)
            handleUpdateAvatar(files)
        }
    }, [files])

    function getBase64Image(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleUpdateAvatar = async (files) => {
        console.log(files)

        setAvatarComp(<>
            <AvatarLoadingRing></AvatarLoadingRing>
        </>)
        setLoadingAvatar(true)

        let thumbs_tmp = []

        for (let file of files) {
            let img = await getBase64Image(file)
            thumbs_tmp.push({ src: img })
        }

        let avatarSrc = thumbs_tmp[0].src

        try {
            await api.put(`/api/usuarios/${user?.id}`, {
                avatar: avatarSrc,
            }, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            }).then(r => {
                if (typeof r.data == 'string') {
                    handleAlert("Campos inválidos", r.data, "danger")
                } else {
                    if (r.status == 200) {
                        console.log(r.data)
                        handleAlert("Sucesso", "Avatar alterado com êxito.", "success")
                        getData()
                    }
                }
            })
        } catch (err) {
            if (err) {
                handleAlert("Erro", "Ocorreu um erro inesperado.", "danger") 
            }
        }

    }




    // Carregar Infromações

    const getData = async () => {
        await api.get(`/api/usuarios/${user?.id}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            if (r.status == 200) {
                console.log(r.data)

                setConfigData(r.data)

            }
        })
    }

    useEffect(() => {
        if (typeof user["id"] != 'undefined') {
            getData()
        }
    }, [user])

    const [configData, setConfigData] = useState({})

    const handleConfigData = (value, field) => {
        setConfigData({
            ...configData,
            [field]: value
        })
    }

    useEffect(() => {
        //getData()
        console.log(configData)

        if (Object.keys(configData).length > 0) {
            console.table(configData)
            if (configData != null) {
                setLoadingAvatar(false)
            }
        }
    }, [configData])

    useEffect(() => {
        if (!loadingAvatar) {
            setAvatarComp(configData["avatar"] != '' && typeof configData["avatar"] != 'undefined' ? <>
                <img src={urlSrc + configData["avatar"]} />
            </> : <>
                <div className='noAvatar'>
                    {getInitials(user?.nome)}
                </div>
            </>)
        }
    }, [loadingAvatar])

    // Editar Informações

    const putData = async () => {

        const dataToUpdate = {
            id: configData.id,
            nome: configData.nome,
            email: configData.email
        };

        try {
            await api.put(`/api/usuarios/${user?.id}`, dataToUpdate, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            }).then(r => {
                if (typeof r.data == 'string') {
                    handleAlert("Campos inválidos", r.data, "danger")
                } else {
                    if (r.status == 200) {
                        console.log(r.data)
                        handleAlert("Sucesso", "Os dados foram atualizados com êxito.", "success")
                        getData()
                    }
                }
            })
        } catch (err) {
            if (err) {
                handleAlert("Erro", "Ocorreu um erro inesperado.", "danger") 
            }
        }
    }

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar />
            <div className="Content PaginasMinhaconta">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderMinhaconta'>
                                <i><Icon icon="icon-park-outline:config" ></Icon></i>
                                <h2>Minha Conta<br />
                                    <span>Gerencie seu nome, senha e foto.</span></h2>
                                <Alert />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='Minhaconta'>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className='fotoMinhaconta'>

                                            {avatarComp}

                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(event) => setFiles(event.target.files)}
                                                hidden
                                                ref={inputRef}
                                            ></input>
                                            <Button onClick={() => inputRef.current.click()} className='Btns ButtonIcon'>
                                                <Icon icon="tabler:photo-plus"></Icon>
                                            </Button>
                                        </div>
                                        <h1>{user?.nome}</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>Dados</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={6}>
                                                <Form.Label className='Label'>Nome do usuário</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control

                                                        className='Inputs'
                                                        type="text"
                                                        placeholder="Digite seu título"
                                                        value={configData["nome"]}
                                                        onChange={(e) => handleConfigData(e.target.value, "nome")}
                                                    />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={6}>
                                                <Form.Label className='Label'>email</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control
                                                        disabled
                                                        className='Inputs'
                                                        type="text"
                                                        placeholder="Digite o subtitulo"
                                                        value={configData["email"]}
                                                        onChange={(e) => handleConfigData(e.target.value, "email")} />
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={6}>
                                                <Form.Label className='Label'>senha</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control
                                                        className='Inputs'
                                                        type="text"
                                                        placeholder="Digite sua senha atual"
                                                        value={configData["senhaatual"]}
                                                        onChange={(e) => handleConfigData(e.target.value, "senhaatual")} />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={6}>
                                                <Form.Label className='Label'>confirmar a senha</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control
                                                        className='Inputs'
                                                        type="text"
                                                        placeholder="Confirme sua senha"
                                                        value={configData["senhaconfirmacao"]}
                                                        onChange={(e) => handleConfigData(e.target.value, "senhaconfirmacao")} />
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs={12} sm={12} md={12} lg={6} className='text-left'>
                                        <Button className='Btns ButtonLight'>Esqueci a minha senha</Button>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} className='text-right'>
                                        <Form.Group className="FormNovo">
                                            <Button className='Btns ButtonSecondary'>Voltar</Button>
                                            <Button className='Btns ButtonPrimaryLight' onClick={putData}>Salvar</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ReactTooltip id="my-tooltip-diferenciais" place="bottom" content="Adicionar mais Diferenciais" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
        </>
    );
}
export default MinhaConta;