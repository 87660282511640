import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import api from "../../services/apiIdz";
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/Depoimentos/Searce';
import Filter from '../../components/Depoimentos/Filter';
import DepoimentosList from '../../components/Depoimentos/DepoimentosList';
import ModalDepoimentosNew from '../../components/Depoimentos/ModalDepoimentosNew';
import ModalDepoimentosEdit from '../../components/Depoimentos/ModalDepoimentosEdit';
//utils
import { isNew } from '../../utils/dates';


//styles
import './Depoimentos.scss';
import { useAuth } from '../../context/useAuth';
import Alerts from '../../components/Alerts';
import ModalDelete from '../../components/Depoimentos/ModalDelete';

// Imagens
import logo from '../Dashboard/img/logo-idzcar.png'

function Depoimentos() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    
    const navigate = useNavigate();

    const { token } = useAuth()

    const [showDepoimentos, setShowModalDepoimentos] = useState(false);
    const [showDepoimentosEdit, setShowModalDepoimentosEdit] = useState(false);
    const [showDelete, setShowModalDepoimentosDelete] = useState(false)

    const showModalDepoimentos = () => {
        setShowModalDepoimentos(true);
    };
    const closeModalDepoimentos = () => {
        setShowModalDepoimentos(false);
    };

    const [selectedId, setSelectedId] = useState(null)
    const showModalDepoimentosEdit = (id) => {
        setShowModalDepoimentosEdit(true);
        setSelectedId(id)
    };
    const closeModalDepoimentosEdit = () => {
        setSelectedId(null)
        setShowModalDepoimentosEdit(false);
    };

    const showModalDepoimentosDelete = (id) => {
        setShowModalDepoimentosDelete(true);
        setSelectedId(id)
    };
    const closeModalDepoimentosDelete = () => {
        setSelectedId(null)
        setShowModalDepoimentosDelete(false);
    };


    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const [depoimentos, setDepoimentos] = useState([])
    const [readyFilter, setReadyFilter] = useState(false)
    const getDepoimentos = async () => {
        await api.get(`/api/depoimentos`).then(res => {
            if (res.status == 200) {
                setDepoimentos(res.data)
                setReadyFilter(true)
            }
        })
    }

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalDepoimentosDelete(false);
        getDepoimentos()
    }

    useEffect(() => {
        getDepoimentos()
    }, [])


    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])


    const handleFiltered = (rawData) => {

        console.log("before filter", rawData)

        let tmpDepoimentoList

        console.log("Status", status, typeof status)
        console.log("Search", search, typeof search)

        if (status != null && status != '') {
            if (status == "news") {
                console.log("FILTERING ONLY SEARCH WITH NEW DEPOIMENTOS")
                tmpDepoimentoList = rawData.filter(depoimento_d => depoimento_d.nome.toLowerCase().includes(search) && isNew(depoimento_d))
            } else {
                console.log("FILTERING ONLY SEARCH AND STATUS CODE")
                tmpDepoimentoList = rawData.filter(depoimentos_d => depoimentos_d.nome.toLowerCase().includes(search) && depoimentos_d.status == status)
            }
        } else {
            console.log("FILTERING ONLY SEARCH")
            tmpDepoimentoList = rawData.filter(depoimento_d => depoimento_d.nome.toLowerCase().includes(search))
        }
        console.log("after filter", tmpDepoimentoList)

        setFiltered(tmpDepoimentoList)
    }

    useEffect(() => {
        if (readyFilter) {
            handleFiltered(depoimentos)
        }

    }, [search, status, readyFilter, depoimentos])

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };


    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar  menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Depoimentos">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="nimbus:marketing" ></Icon></i>
                                <h2>Depoimentos <br />
                                    <span>Opiniões e experiências compartilhadas pelos usuários.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableDepoimentos'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsDepoimentos'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={showModalDepoimentos}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listDepoimentos'>
                                        <Row>
                                            <DepoimentosList depoimentos={filtered} showModalDepoimentosEdit={showModalDepoimentosEdit} showModalDepoimentosDelete={showModalDepoimentosDelete} />
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-adicionar" place="bottom" content="Adicionar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-pausar" place="bottom" content="Pausar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-ativar" place="bottom" content="Ativar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
                <ModalDepoimentosNew showDepoimentos={showDepoimentos} closeModalDepoimentos={closeModalDepoimentos} getDepoimentos={getDepoimentos} token={token} handleAlert={handleAlert} />
                <ModalDepoimentosEdit
                    showDepoimentos={showDepoimentosEdit}
                    getDepoimentos={getDepoimentos}
                    token={token}
                    handleAlert={handleAlert}
                    closeModalDepoimentosEdit={closeModalDepoimentosEdit}
                    selectedId={selectedId}
                />
                <ModalDelete
                    deleteId={selectedId}
                    show={showDelete}
                    handleClose={closeModalDepoimentosDelete}
                    handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                    handleAlert={handleAlert}
                />
            </div>
        </>
    );
}
export default Depoimentos;