import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "react-multi-select-component";
import "./Related.scss";
import api from "../../../services/apiIdz";

function VeiculosRelatedModal({ showRelated, id, veicles, putPriceRelated }) {
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [veiclesLocal, setVeiclesLocal] = useState([])
    const [url, setUrl] = useState("");
    const [selected, setSelected] = useState([]);


    const getRelated = async () => {
        await api.get(`/api/associar-veiculos/${id}`).then(r => {
            if (r.data) {
                let filteredSelect = []

                r.data.forEach((related_car) => {
                    veicles.forEach((current_car) => {
                        if (current_car.id == related_car.relacionado_id) {
                            filteredSelect.push({ label: current_car.titulo + `|${current_car.ano_modelo}|${current_car.cor}|${related_car.relacionado_id}`, value: related_car.relacionado_id })
                        }
                    })
                })

                setSelected(filteredSelect)
            }
        })
    }

    useEffect(() => {
        if (showModal && id) {
            getRelated()
        }
    }, [showModal, id])

    useEffect(() => {
        console.log(selected)
    }, [selected])


    useEffect(() => {
        console.log(`[VEICLES]:`, veicles, `[LOADING]:${loading}`, `[ID]:${id}`)
        if (veicles && id) {
            setVeiclesLocal(veicles)

            let data = []

            let notSame = veicles.filter(d => {
                console.log("[FILTERED ID]", d)
                console.log("[CURRENT_ID]: %d \n [CAR_ID]: %d", d.id, id)
                return d.id != id
            })
            console.log(notSame)

            for (let veicle of notSame) {
                console.log(veicle)
                data.push({ label: veicle.titulo + `|${veicle.ano_modelo}|${veicle.cor}|${veicle.id}`, value: veicle.id })
            }

            console.log(data)
            setVeiclesLocal(data)
        }
    }, [veicles, id])


    const handleClose = () => {
        setShowModal(false);
    };


    useEffect(() => {
        if (showRelated) {
            setShowModal(true);
        }
    }, [showRelated])

    return (
        <>

            <Modal show={showModal} >
                <Modal.Header >
                    <Modal.Title>RELACIONAR</Modal.Title>
                    <Icon icon="ph:link-bold"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className='Label'>QUAL VEÍCULO?</Form.Label>
                            <MultiSelect
                                options={veiclesLocal}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Selecione os Veiculos"
                            />
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={() => { putPriceRelated(selected, id, handleClose) }} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default VeiculosRelatedModal;
