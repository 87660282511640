import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"

//styles
import './Variacoes.scss';
import "react-datepicker/dist/react-datepicker.css";
import IdzFileUpload from '../../../components/Veiculos/idzFileUpload';

function Variacoes({ }) {


    return (
        <>
            <Navbar />
            <div className="Content Variacoes">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderVariacoes'>
                                <i><Icon icon="wpf:security-checked" ></Icon></i>
                                <h2>Cadastro de Variações<br />
                                    <span>Abaixo você encontra os dados mais importantes da sua conta</span></h2>
                                <Alert />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NovoVariacoes'>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>Detalhes</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>
                                                    <Form.Control className='Inputs' value="" type="text" placeholder="Modelo" />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>
                                                    <Form.Control className='Inputs' value="" placeholder='Garantia' />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>
                                                    <Form.Control className='Inputs' value="" type="text" placeholder="Valor" />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={12}>
                                                <textarea className="" value="" placeholder='Descrição'></textarea>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>programação dE valor</h3>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                            <Form.Check
                                                className='Label'
                                                type="checkbox"
                                                label="Indeterminado"
                                            ></Form.Check>
                                    </Col>


                                    <Col xs={12} sm={12} md={4} lg={4} className='DatePicker'>
                                        <DatePicker
                                            showIcon
                                            selected=""
                                            onChange=""
                                            selectsStart
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        <DatePicker
                                            showIcon
                                            selected=""
                                            onChange=""
                                            selectsEnd
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>imagens</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        
                                        <div class="ImagesUploader">
                                            <IdzFileUpload currentFile="" handleUpload="" title='Ilustração' subtitle='Arquivos permitidos: .jpeg, .png' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>videos</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>
                                                    <div class="FormControl">
                                                        <Form.Control className='Inputs' type="text" placeholder="Link de apresentação" value="" />
                                                        <i><Icon icon="ic:baseline-link"></Icon></i>
                                                    </div>
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='text-right'>
                                        <Form.Group className="FormNovo">
                                            <Button className='Btns ButtonSecondary'>Voltar</Button>
                                            <Button className='Btns ButtonPrimaryLight'>Salvar</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ReactTooltip id="my-tooltip-diferenciais" place="bottom" content="Adicionar mais Diferenciais" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
            
        </>
    );
}
export default Variacoes;