import { Icon } from "@iconify/react/dist/iconify.js"
import React, { useState } from "react"
import { Col } from "react-bootstrap"
import api from "../../../services/apiIdz";


export default function ItemTipo({ item, showModalCategoriasEdit, handleCategoriaStatus, showModalCategoriaDelete, tooltipId, navigate, token, handleAlert, getCategorias }) {

    const [isPlaying, setIsPlaying] = useState(item.status == "Ativo" ? true : false);

    const togglePlayPause = async () => {
        setIsPlaying(!isPlaying);
        try {
            const status = !isPlaying ? 'Ativo' : 'Inativo';
            console.log({ status: status })
            await api.put(`/api/tipo-veiculo/${item.id}`, { status: status }, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    'Token': token
                }
            }).then(res => {
                if (res.data) {
                    handleAlert("Sucesso", `O status do banner ${item.id} foi atualizado para '${status}' com sucesso.`, "success")
                    getCategorias()
                }
            });
        } catch (error) {
            handleAlert("Error", error, "danger")
        }
    };

    return (<Col xs={6} sm={12} md={3} lg={2}>
        <div className='Veiculo'>
            <div className='imagem' style={item.imagem ? { backgroundImage: `url(https://api.idzcar.com.br/${item.imagem})` } : null}>
                {item.status == "Ativo" ? (<span><i></i> Publicado</span>) : (<span className='warning'><i></i> Pausado</span>)}
            </div>
            <h5>CATEGORIA</h5>
            <h2>{item?.titulo} {item?.configuracao}</h2>
            <hr />
            <div className='acoes'>
                <button className='Btns ButtonPrimary'  onClick={() => {
                        showModalCategoriasEdit(item?.id)
                }}>Editar</button>
                <button data-tooltip-id={tooltipId} onClick={() => { togglePlayPause() }} className='Btns ButtonIcon'>
                    {item.status == "Ativo" ? (
                        <i><Icon icon="material-symbols:pause"></Icon></i>
                    ) : (
                        <i><Icon icon="ph:play-fill"></Icon></i>
                    )}
                </button>
                <button data-tooltip-id="my-tooltip-delete" className='Btns ButtonIcon' onClick={() => { showModalCategoriaDelete(item?.id, "categorias") }} ><i><Icon icon="mdi:trash"></Icon></i></button>
            </div>
        </div>
    </Col>)
}