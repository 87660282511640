import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"
import Searce from '../../../components/Blindagem/Searce';
import Filter from '../../../components/Blindagem/Filter';
import ModalCategoria from '../../../components/Estetica/Tipo';
import ModalCategoriaEdit from '../../../components/Estetica/TipoEdit';

//styles
import './Tipo.scss';

//Imagens
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';
import Alerts from '../../../components/Alerts';
import { isNew } from '../../../utils/dates';
import ModalDelete from '../../../components/Blindagem/ModalDelete';
import ItemTipo from '../../../components/Estetica/ItemTipo';
import logo from '../../Dashboard/img/logo-idzcar.png'

function Tipo() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const { token } = useAuth()

    const [popupData, setPopupData] = useState({
        titulo: '',
        descricao: '',
        imagem: '',
        status: 'ativo',
        configuracao: ''
    })

    const [popupDataEdit, setPopupDataEdit] = useState({
        titulo: '',
        descricao: '',
        imagem: '',
        status: 'ativo'
    })

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    //Obter informação inicial
    const [categorias, setCategorias] = useState([])
    const [readyFilter, setReadyFilter] = useState(false)
    const getCategorias = async () => {
        setReadyFilter(false)
        await api.get(`/api/tipo-veiculo`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            if (res.status == 200) {
                setCategorias(res.data)
                setReadyFilter(true)
            }
        })
    }

    useEffect(() => {
        getCategorias()
    }, [])



    useEffect(() => {
        console.log(categorias)
    }, [categorias])

    const navigate = useNavigate();
    const [showCategorias, setshowCategorias] = useState(false);
    const [showCategoriasEdit, setshowCategoriasEdit] = useState(false);
    const [isPlaying, setIsPlaying] = useState();
    const [tooltipId, setTooltipId] = useState("my-tooltip-play");

    const [selectedId, setSelectedId] = useState(null)
    const [currentEndpoint, setCurrentEndpoint] = useState(null)
    const [showDelete, setShowDelete] = useState(false)

    const showModalCategoriaDelete = (id, endpoint) => {
        setShowDelete(true)
        setSelectedId(id)
        setCurrentEndpoint(endpoint)
    }

    const closeModalCategoriaDelete = () => {
        setShowDelete(false)
        setSelectedId(null)
        setCurrentEndpoint(null)
    }

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowDelete(false)
        setCurrentEndpoint(null)
        getCategorias()
    }

    const showModalCategorias = () => {
        setshowCategorias(!showCategorias);
    };
    const showModalCategoriasEdit = async (id) => {
        setshowCategoriasEdit(true);
        setSelectedId(id)
        await getCategoria(id)
    };
    const closeModalCategoriasEdit = (id) => {
        setshowCategoriasEdit(false);
        setSelectedId(null)
    };

    const postCategoria = async () => {
        await api.post(`/api/tipo-veiculo`, popupData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    setshowCategorias(false)
                    getCategorias()
                    // setTimeout(() => {
                    //     navigate("/paginas")
                    // }, 4000) 
                }
            }
        })
    }
    const putCategoria = async (id) => {
        await api.put(`/api/tipo-veiculo/${id}`, popupDataEdit, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram editados com êxito.", "success")
                    getCategorias()
                    closeModalCategoriasEdit()
                    // setTimeout(() => {
                    //     navigate("/paginas")
                    // }, 4000) 
                }
            }
        })
    }

    const getCategoria = async (id) => {
        await api.get(`/api/tipo-veiculo/${id}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            if (res.status == 200) {
                console.log(res.data)
                setPopupDataEdit(res.data)
            }
        })
    }

    useEffect(() => {
        console.log(popupData)
    }, [popupData])


    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }
    const handlePopupDataEdit = (value, field) => {
        setPopupDataEdit({
            ...popupDataEdit,
            [field]: value
        })
    }
    const togglePlayPause = async () => {
        setIsPlaying(!isPlaying);
    }

    const handleImagem = (image) => {
        handlePopupData(image, 'imagem')
    }

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])
    const handleFiltered = (rawData) => {

        let tmpCategoriaList

        if (status != null && status != '') {
            if (status == "news") {
                console.log("FILTERING ONLY SEARCH WITH NEW CategoriaS")
                tmpCategoriaList = rawData.filter(Categorias_d => Categorias_d.titulo.toLowerCase().includes(search) && isNew(Categorias_d))
            } else {
                console.log("FILTERING ONLY SEARCH AND STATUS CODE")
                tmpCategoriaList = rawData.filter(Categorias_d => Categorias_d.titulo.toLowerCase().includes(search) && Categorias_d.status.toLowerCase() == status)
            }
        } else {
            console.log("FILTERING ONLY SEARCH")
            tmpCategoriaList = rawData.filter(Categorias_d => Categorias_d.titulo.toLowerCase().includes(search))
        }
        console.log("after filter", tmpCategoriaList)

        setFiltered(tmpCategoriaList)
    }

    useEffect(() => {
        // if (readyFilter) {
        console.log(categorias)
        console.log(search, status.name)
        handleFiltered(categorias)
        // }
    }, [search, status, readyFilter])


    const handleCategoriaStatus = async (id) => {
        try {
            const categoriaToUpdate = categorias.find(item => item.id === id);

            const newStatus = categoriaToUpdate.status === 'Ativo' ? 'Inativo' : 'Ativo';

            await api.put(`/api/tipo-veiculo/${id}`, { status: newStatus }, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    Token: token
                }
            });

            const updatedCategorias = categorias.map(item => {
                if (item.id === id) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setCategorias(updatedCategorias);

            console.log("Categoria atualizada com sucesso!");

        } catch (error) {
            console.error('Erro ao atualizar o status:', error);
        }
    };

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };


    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Blindagem">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="mdi:stars" ></Icon></i>
                                <h2>Tipos de veículos <br />
                                    <span>Gerencie os tipos e valores de blindagem para cada categoria de veículo.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableBlindagem'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsBlindagem'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={showModalCategorias}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listBlindagem'>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <div className='listBlindagem'>
                                                    <Row>
                                                        {
                                                            filtered?.map(item => (<>
                                                                <ItemTipo
                                                                    item={item}
                                                                    showModalCategoriasEdit={showModalCategoriasEdit}
                                                                    handleCategoriaStatus={handleCategoriaStatus}
                                                                    showModalCategoriaDelete={showModalCategoriaDelete}
                                                                    tooltipId={tooltipId}
                                                                    navigate={navigate}
                                                                    token={token}
                                                                    handleAlert={handleAlert}
                                                                    getCategorias={getCategorias}
                                                                    
                                                                />

                                                            </>

                                                            ))
                                                        }
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-adicionar" place="bottom" content="Adicionar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-pausar" place="bottom" content="Pausar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-ativar" place="bottom" content="Ativar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
            <ModalCategoria show={showCategorias} handlePopupData={handlePopupData} postCategoria={postCategoria} popupData={popupData} />
            <ModalCategoriaEdit show={showCategoriasEdit} selectedId={selectedId} token={token} handlePopupDataEdit={handlePopupDataEdit} closeModalCategoriasEdit={closeModalCategoriasEdit} putCategoria={putCategoria} popupDataEdit={popupDataEdit} />
            <ReactTooltip
                id={tooltipId}
                place="bottom"
                content={isPlaying ? "Pausar banner" : "Ativar banner"}
                style={{ backgroundColor: "#FFFFFF", color: "#000000" }}
            ></ReactTooltip>
            <ModalDelete
                deleteId={selectedId}
                endpoint={currentEndpoint}
                show={showDelete}
                handleClose={closeModalCategoriaDelete}
                handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                handleAlert={handleAlert}
            />
        </>
    );
}
export default Tipo;