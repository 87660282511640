import styled from "styled-components";

export const FileUploadContainer = styled.section`
  position: relative;
  margin: 0px 0 15px;
  border: 2px dotted lightgray;
  /* padding: 35px 20px; */
  height:140px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  border: 2px dashed #4C4E56;
  background: #191A1E;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;

export const InputLabel = styled.label`
  top: -21px;
  font-size: 13px;
  color: black;
  left: 0;
  position: absolute;
`;

export const UploadFileBtn = styled.button`
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  border: none;
  padding: 0;
  text-align: center;
  font-weight: 700;
  border-radius: 6px;
  color: #3498db;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  display: inline-block;
  align-items: center;
  padding-right: 0;
  justify-content: center;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 30px;

  span{
    font-size:13px;
  }
  p{
    font-size:9px;
  }

  i{
    width: 100% !important;
    height: 50px;
    position: relative !important;
    border: none !important;
    font-size: 50px !important;
    color: #555A67;

        display: flex !important;
    justify-content: end;
    align-items: center;
  }

  p{
    color: #555A67;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: #3498db;
    z-index: -1;
    transition: width 250ms ease-in-out;
  }

  i {
    font-size: 30px !important;
    margin-right: 5px;
    border-right: 2px solid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: 500px) {
    width: 70%;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: 0;
    background: transparent;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;

  span {
    font-size: 14px;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const FileMetaData = styled.div`
  display: ${(props) => (props.isImageFile ? "none" : "flex")};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background: rgba(135, 77, 249, 0.85);
  aside {
    position: relative;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
  i {
    position: relative;
    text-align: center;
    font-size: 26px;
    margin-top: 10px;
    cursor: pointer;
  }
  span{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    top: -10px;
    position: relative;
  }

`;

export const RemoveFileIcon = styled.i`
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`;

export const PreviewContainer = styled.section`
  padding: 0.25rem;
  width: 16%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #373940;
  &:hover {
    opacity: 0.55;

    ${FileMetaData} {
      display: flex;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;
  }

  @media only screen and (max-width: 750px) {
    width: 25%;
  }

  @media only screen and (max-width: 500px) {
    width: 50%;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
`;

export const ImagePreview = styled.img`
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;
