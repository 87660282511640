import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import DatePicker from 'react-datepicker';

// CSS
import "./Categorias.scss";
import 'react-datepicker/dist/react-datepicker.css';
import api from "../../../services/apiIdz";
import IdzFileUpload from "../../Veiculos/idzFileUpload";

function CategoriasModalEdit({ showCategorias, token, handleAlert, getCategoria, closeModalCategorias, editId }) {

    const [url, setUrl] = useState("");
    const [ativo, setAtivo] = useState(false);


    const [categoria, setCategoria] = useState({
        dt_inclusao: new Date(),
        ordem: 1,
        status: "Ativo"
    })
 

    const getCurrentData = async (id) => {
        await api.get(`/api/categoria-estetica/${id}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    // handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    // getCategoria()
                    // setShowModal(false)
                    let image = r.data.imagem != '' ? null : process.env.REACT_APP_API_URL + r.data.imagem 
                    let data = r.data

                    if(data["status"] == "Ativo"){
                        setAtivo(true)
                    }else{
                        setAtivo(false)
                    }

                    data["imagem"] = process.env.REACT_APP_API_URL + image

                    setCategoria(data)
                }
            }
        })
    }

    useEffect(() => {
        if (editId) {
            getCurrentData(editId) 
        }
    }, [editId])



   
    const handleCategoriaData = (value, field) => {
        setCategoria({
            ...categoria,
            [field]: value
        })
    }

    const handleClose = () => { 
        closeModalCategorias();
    };
 

    const handleSwitchToggle = () => {
        setAtivo(!ativo);
        console.log(!ativo)
        handleCategoriaData(!ativo == 1 ? "Ativo" : "Inativo", "status")
    };

    const handleImagem = (image) => {
        handleCategoriaData(image, 'imagem')
    }

    const postData = async () => {
        console.log("Enviando categorias")

        await api.put(`/api/categoria-estetica/${editId}`, categoria, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    closeModalCategorias()
                    getCategoria() 
                }
            }
        })
    }

    return (
        <>

            <Modal show={showCategorias} className="ModalCategoriasNew">
                <Modal.Header >
                    <Modal.Title>Adicionar Categoria</Modal.Title>
                    <Icon icon="mdi:stars"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        value={categoria["titulo"]}
                                        onChange={(e) => { handleCategoriaData(e.target.value, "titulo") }}

                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={12}>
                                <Form.Group className='Label'>
                                    <Form.Label className='Label'>Descrição</Form.Label>
                                    <textarea
                                        className=""
                                        value={categoria["descricao"]}
                                        onChange={(e) => { handleCategoriaData(e.target.value, "descricao") }}
                                    ></textarea>

                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={12}>
                                <Form.Group className='Label'>
                                    <Form.Label className='Label'>Foto</Form.Label>
                                    <IdzFileUpload currentFile={categoria["imagem"]} handleUpload={handleImagem} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Check
                                className='Label'
                                type="switch"
                                label={ativo ? "ativo" : "desativado"}
                                checked={ativo}
                                onChange={handleSwitchToggle}
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={postData} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CategoriasModalEdit;
