import { Ring } from "@uiball/loaders";
import React from "react";

import "./ButtonLoading.scss"


export default function ButtonLoadingRing({ sending }) {

    return (
        sending &&
        <div className="loading-ring">
            <Ring color='#fff' ></Ring>
        </div>
    )
}