import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';
import { Dropdown } from 'primereact/dropdown';

// Componentes
import IdzFileUpload from '../../Veiculos/idzFileUpload';

//styles
import './Categorias.scss';

function Categorias({ show, handlePopupData, popupData, postCategoria }) {
    const { token } = useAuth() 
    const [showModal, setShowModal] = useState(false);
    const [opcionaisSelecionados, setOpcionaisSelecionados] = useState([]);

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show]);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleUpload = (image) => {
        handlePopupData(image, "imagem");
    };

        // Opcionais

        const [opcionais, setOpcionais] = useState([])

        const getOpcionais = async () => {
            await api.get(`/api/modelos/categoria`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY
                }
            }).then(response => {       
                if (response.data) {
                    console.log("[OPCIONAIS]:", response.data);
                    const lista = response.data.map(opcional => ({
                        label: opcional.titulo,
                        value: opcional.id
                    }));
                    setOpcionais(lista);
        
                    // Defina optionsDropdown apenas se não estiver definido
                    if (!optionsDropdown.length) {
                        setOptionsDropdown(lista);
                    }
                }
            }).catch(error => {
                console.error("Erro ao obter opcionais:", error);
                // Lidar com o erro, talvez exibindo uma mensagem de erro ao usuário
            });
        };

        const [optionsDropdown, setOptionsDropdown] = useState([]);
    
        useEffect(() => {
            if (opcionaisSelecionados) {
                getOpcionais(opcionaisSelecionados, "opcionais")
            }
        }, [opcionaisSelecionados])
    
    
        const handleDropdownChange = (event) => {
            const selectedOption = event.value;
        
            // Verificar se a opção já está presente e desabilitada
            const isOptionAlreadyDisabled = optionsDropdown.some(option =>
                option.value === selectedOption && option.disabled
            );
        
            if (!isOptionAlreadyDisabled) {
                const updatedOptions = optionsDropdown.map(option => {
                    if (option.value === selectedOption) {
                        return { ...option, disabled: true };
                    } else {
                        return option;
                    }
                });
        
                console.log("Updated optionsDropdown:", updatedOptions);
                setOptionsDropdown(updatedOptions);
                const newOpcionaisSelecionados = [...opcionaisSelecionados, selectedOption];
                setOpcionaisSelecionados(newOpcionaisSelecionados);
        
                // Atualize o campo 'opcionais' em popupData
                handlePopupData(newOpcionaisSelecionados, "configuracao");
            }
        };
    
        const handleTagDelete = (tagIndex) => {
            const newTags = [...opcionaisSelecionados];
            newTags.splice(tagIndex, 1);
            setOpcionaisSelecionados(newTags);
    
            const deletedTag = opcionaisSelecionados[tagIndex];
            const updatedOptions = optionsDropdown.map(option =>
                option.value === deletedTag ? { ...option, disabled: false } : option
            );
            setOptionsDropdown(updatedOptions);
        };

        useEffect(() => {
            console.log("optionsDropdown:", optionsDropdown);
        }, [optionsDropdown]);



        useEffect(() => {
            console.log("popupData:", popupData);
        }, [popupData]);

        
    
    return (
        <>
            <Modal show={showModal} className="ModalCategoria">
                <Modal.Header >
                    <Modal.Title>CADASTRO DE CATEGORIA</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label>Nome da Categoria</Form.Label>
                                <Form.Control className='Inputs' value={popupData["titulo"]} onChange={(e) => { handlePopupData(e.target.value, "titulo") }} type="text" placeholder="Nome" />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label>Descrição</Form.Label>
                            <textarea className="" value={popupData["descricao"]} onChange={(e) => { handlePopupData(e.target.value, "descricao") }} placeholder='Descrição'></textarea>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={12}>
                            <Form.Label className='Label'>
                                <span>Selecione as categorias de veículos</span>
                                <Dropdown
                                    emptyMessage="Sem resultado"
                                    value={null}
                                    options={opcionais}
                                    onChange={handleDropdownChange}
                                    optionLabel="label"
                                    placeholder="Opcionais"
                                    className='Dropdown'
                                    filter
                                />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                             {opcionaisSelecionados.length > 0 && (
                                <div className='TagsInput'>
                                    {opcionaisSelecionados.map((id, index) => {
                                        const opcionaisSelecionadosObj = opcionais.find(opcional => opcional.value === id);
                                        if (opcionaisSelecionadosObj) {
                                            return (
                                                <span key={index}>
                                                    {opcionaisSelecionadosObj.label} 
                                                    <i onClick={() => handleTagDelete(index)}><Icon icon="iconamoon:close-duotone"></Icon></i>
                                                </span>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12} className="mb-4">
                            <Form.Label className='Label'>
                                <Form.Label>Foto</Form.Label>
                                <div className="ImagesUploader">
                                    <IdzFileUpload
                                        currentFile={popupData["imagem"]}
                                        handleUpload={handleUpload}
                                        title='Ilustração'
                                        subtitle='Arquivos permitidos: .jpeg, .png'
                                    />
                                </div>
                            </Form.Label>
                        </Col>
                    </Row>

                    <Form.Group className="text-center">
                        <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={postCategoria}>
                            Salvar
                        </Button>
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Categorias;
