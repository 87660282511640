import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Icon } from '@iconify/react';
import { Tooltip as ReactTooltip } from "react-tooltip";


import 'react-tooltip/dist/react-tooltip.css'

function MarcasList({marcas, showModalMarcasDelete,showModalMarcasEdit}) {




    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };


    useEffect(() => {
        if (marcas) {
            console.log(marcas)
        }
    }, [marcas])

    function dateToLocaleStringCustom(date) {
        const options = {
            day: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return date.toLocaleString('pt-BR', options);
    }


    return (
        <>
            <div className="UsuariosList">
                <div className="card">
                    <DataTable
                        value={marcas}
                        paginatorClassName={"paginator"}
                        paginatorTemplate={paginatorTemplate}
                        sortField="createdAt"
                        sortOrder={-1}
                        paginator
                        rows={12}>

                        <Column
                            field="status"
                            header="STATUS"
                            style={{ width: '14%' }}
                            body={(rowData) => <span><i className="Ativo"></i> <strong>{rowData.status}</strong></span>}
                            bodyClassName="status-column">
                        </Column>

                        <Column field="titulo"
                            header="NOME"
                            bodyClassName="title-column name-column"
                            style={{ width: '20%' }} >
                        </Column>



                        <Column
                            field="Actions"
                            header=""
                            style={{ width: '22%' }}
                            bodyClassName="actions-column"
                            body={(rowData) => {
                                return (
                                    <>
                                        <button className='Btns ButtonIcon' onClick={(e) => { showModalMarcasDelete(rowData.id) }}> <i><Icon className="icons" icon="mdi:trash" /></i></button>
                                        <button className='Btns View' onClick={(e)=>{showModalMarcasEdit(rowData.id)}}>Editar <i><Icon className="icons" icon="ri:add-fill" /></i></button>
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <ReactTooltip id="my-tooltip-1" place="bottom" content="Publicar" />
            <ReactTooltip id="my-tooltip-2" place="bottom" content="Já publicado" />

        </>
    );
}
export default MarcasList;