import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import api from "../../services/apiIdz";
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/Estetica/Searce';
import Filter from '../../components/Estetica/Filter';
import PropostasList from '../../components/Blindagem/PropostasList';
import ModalCategoriasNew from '../../components/Estetica/ModalCategoriasNew';
import ModalCategoriasEdit from '../../components/Estetica/ModalCategoriasEdit';
import { useAuth } from '../../context/useAuth';
import Alerts from '../../components/Alerts';
import ModalDeleteProposta from '../../components/Blindagem/ModalDeleteProposta';

//utils
import { isNew } from '../../utils/dates';

//styles
import './Propostas.scss';

//Imagens 
import logo from '../Dashboard/img/logo-idzcar.png'

function EsteticaCategoria() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const navigate = useNavigate();

    const { token } = useAuth()

    const [showCategorias, setShowModalCategorias] = useState(false);
    const [showCategoriasEdit, setShowModalCategoriasEdit] = useState(false);
    const [showDelete, setShowModalCategoriasDelete] = useState(false)

    const showModalCategorias = () => {
        setShowModalCategorias(true);
    };
    const closeModalCategorias = () => {
        setShowModalCategorias(false);
    };

    const showModalCategoriasEdit = (id) => {
        setShowModalCategoriasEdit(true);
        setSelectedId(id)
    };
    const closeModalCategoriasEdit = () => {
        setShowModalCategoriasEdit(false);
        setSelectedId(null)
    };

    const [selectedId, setSelectedId] = useState(null)

    const showModalCategoriasDelete = (id) => {
        setShowModalCategoriasDelete(true);
        setSelectedId(id)
    };
    const closeModalCategoriasDelete = () => {
        setSelectedId(null)
        setShowModalCategoriasDelete(false);
    };


    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const [categorias, setCategorias] = useState([])
    const [readyFilter, setReadyFilter] = useState(false)
    const getCategoria = async () => {
        await api.get(`/api/orcamento-digitado`).then(res => {
            if (res.status == 200) {
                console.log(res.data)
                setCategorias(res.data)
                setReadyFilter(true)
            }
        })
    }

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalCategoriasDelete(false);
        getCategoria()
    }

    useEffect(() => {
        getCategoria()
    }, [])


    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])


    const handleFiltered = (rawData) => {
        console.log("before filter", rawData)
    
        let tmpCategoriaList;
    
        console.log("Status", status, typeof status)
        console.log("Search", search, typeof search)
    
        if (status != null && status !== '') {
            if (status === "news") {
                console.log("FILTERING ONLY SEARCH WITH NEW CATEGORIA")
                tmpCategoriaList = rawData.filter(categoria_d => (categoria_d.titulo || '').toLowerCase().includes(search) && isNew(categoria_d))
            } else {
                console.log("FILTERING ONLY SEARCH AND STATUS CODE")
                tmpCategoriaList = rawData.filter(categorias_d => (categorias_d.titulo || '').toLowerCase().includes(search) && categorias_d.status === status)
            }
        } else {
            console.log("FILTERING ONLY SEARCH")
            tmpCategoriaList = rawData.filter(categoria_d => (categoria_d.titulo || '').toLowerCase().includes(search))
        }
        console.log("after filter", tmpCategoriaList)
    
        setFiltered(tmpCategoriaList)
    }

    useEffect(() => {
        // if (readyFilter) {
        handleFiltered(categorias)
        //}

    }, [search, status, readyFilter, categorias])

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };



    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content PropostaList">
            <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="tabler:user-star" ></Icon></i>
                                <h2>Propostas <br />
                                    <span>Acompanhe e gerencie informações de clientes em potencial.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableEstetica'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsEstetica'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={() => navigate("/proposta/novo")}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listEstetica'>
                                        <PropostasList categorias={filtered} showModalCategoriasDelete={showModalCategoriasDelete} showModalCategoriasEdit={showModalCategoriasEdit} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    {/* <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-adicionar" place="bottom" content="Adicionar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-pausar" place="bottom" content="Pausar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-ativar" place="bottom" content="Ativar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} /> */}
                </Container>


                <ModalCategoriasNew showCategorias={showCategorias} token={token} getCategoria={getCategoria} closeModalCategorias={closeModalCategorias} handleAlert={handleAlert} />
                <ModalCategoriasEdit editId={selectedId} showCategorias={showCategoriasEdit} token={token} getCategoria={getCategoria} closeModalCategorias={closeModalCategoriasEdit} handleAlert={handleAlert} />
                <ModalDeleteProposta
                    deleteId={selectedId}
                    show={showDelete}
                    handleClose={closeModalCategoriasDelete}
                    handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                    handleAlert={handleAlert}
                />
            </div>
        </>
    );
}
export default EsteticaCategoria;