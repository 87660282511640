import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import "./ModalDeleteMarca.scss";
import api from "../../../services/apiIdz";
import { useAuth } from "../../../context/useAuth";

function ModalDeleteMarca({ show, handleClose, deleteId, handleAlert, handleCloseDeleteModalSuccess }) {
    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState("");
    const { token } = useAuth()

    const deleteData = async () => {
        await api.delete(`/api/marcas-estetica/${deleteId}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    setShowModal(false)
                    handleAlert("Sucesso", "Os dados foram apagados com êxito.", "success")
                    handleCloseDeleteModalSuccess()
                }
            }
        })
    }

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    return (
        <>

            <Modal show={showModal} >
                <Modal.Header >
                    <Modal.Title>Deletar Marca</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <p>Deseja realmente deletar?</p>
                    <Form>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={deleteData} className="Btns ButtonPrimaryLight">
                                Sim
                            </Button>
                            <Button onClick={() => {
                                handleClose()
                                setShowModal(false)
                            }} variant="primary" className="Btns ButtonSecondaryLight">
                                Não
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalDeleteMarca;
