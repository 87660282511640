import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';

// Componentes
import IdzFileUpload from '../../Veiculos/idzFileUpload';

//styles
import './MarcaEditar.scss';

function MarcasEdit({ show, handlePopupDataEdit, popupDataEdit, putMarca, closeModalMarcasEdit, selectedId }) {

    
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
            setShowModal(show);
    }, [show])

    const handleClose = () => {
        setShowModal(false);
        closeModalMarcasEdit();
        handlePopupDataEdit("", "logo")
    };

    const handleUpload = (image) => {
        handlePopupDataEdit(image, "logo")
    }



    return (
        <>

            <Modal show={showModal} className="ModalCategoria">
                <Modal.Header >
                    <Modal.Title>Editar Marca</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={12} className="mb-4">
                            <Form.Label className='Label'>
                                <Form.Label>Logomarca</Form.Label>
                                <div class="ImagesUploader">
                                    <IdzFileUpload
                                        currentFile={popupDataEdit["logo"]}
                                        handleUpload={handleUpload}
                                        title='Logo'
                                        subtitle='Arquivos permitidos: .jpeg, .png'
                                    />
                                </div>
                            </Form.Label>
                        </Col>
                    </Row>

                    <Form.Group className="text-center">
                        <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={() => { putMarca(selectedId);  }}>
                            Salvar
                        </Button>
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Form.Group>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default MarcasEdit;
