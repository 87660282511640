import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/Destaques/Searce';
import Filter from '../../components/Destaques/Filter';
import DestaquesEditModal from '../../components/Destaques/Edit';
import DestaquesModalNew from '../../components/Destaques/ModalNew';


// Imganes
import logo from '../Dashboard/img/logo-idzcar.png'

//styles
import './Destaques.scss';
import api from '../../services/apiIdz';
import { useAuth } from '../../context/useAuth';
import ItemDestaque from '../../components/Destaques/itemDestaque';
import ModalDelete from '../../components/Destaques/ModalDelete';
import Alerts from '../../components/Alerts';

function Destaques() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const navigate = useNavigate();

    const [destaques, setDestaques] = useState([])
    const [readyFilter, setReadyFilter] = useState(false)
    const [showDelete, setShowDelete] = useState(false)



    const getData = async () => {
        setReadyFilter(false)
        await api.get(`/api/destaques`).then(res => {
            if (res.data) {
                console.log(res.data)
                setDestaques(res.data)
                setReadyFilter(true)
            }
        })
    }

    const [veiculos, setVeiculos] = useState([])
    const handleVeicleList = (list) => {
        setVeiculos(list)
    }

    async function getVeiculos() {
        await api.get(`/api/veiculos?status=ativo`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(async (res) => {
            if (res.data) {
                console.log(res.data)
                handleVeicleList(res.data)
            }
        })
    }

    useEffect(() => {
        getData()
        getVeiculos()
    }, [])

    const [deleteId, setDeleteId] = useState(null)
    const handleShowDeleteModal = (id) => {
        setDeleteId(id)
        setShowDelete(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteId(null)
        setShowDelete(false);
    };

    const handleCloseDeleteModalSuccess = () => {
        setDeleteId(null)
        setShowDelete(false);
        getData()
    }

    const [edit, setEdit] = useState(null)
    const handleShowEditModal = (data) => {
        setEdit(data)
        setShowModalEditar(true);
    };

    const handleCloseEditModal = () => {
        setEdit(null)
        setShowModalEditar(false);
    };

    const handleCloseEditModalSuccess = () => {
        setEdit(null)
        setShowModalEditar(false);
        getData()
    }

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])
    const handleFiltered = (rawData) => {


        let tmpDestaqueList = rawData.filter(destaque_d => {
            console.log(destaque_d.modelo?.toLowerCase(), destaque_d.status.toLowerCase())
            return destaque_d.modelo?.toLowerCase().includes(search) && destaque_d.status.toLowerCase().includes(status)
        })
        console.log(tmpDestaqueList)

        setFiltered(tmpDestaqueList)
    }

    useEffect(() => {
        //if (readyFilter) {
            console.log(destaques)
            console.log(search, status.name)
            handleFiltered(destaques)
        //}
    }, [search, status, readyFilter])

    const { token } = useAuth()

    const [showEditar, setShowModalEditar] = useState(false);
    const [showDestaque, setShowModalDestaque] = useState(false);

    const showModalDestaque = () => {
        setShowModalDestaque(!showDestaque);
    };

    const showModalEditar = () => {
        setShowModalEditar(!showEditar);
    };

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Destaques">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="nimbus:marketing" ></Icon></i>
                                <h2>Destaques <br />
                                    <span>Configure e modifique os destaques do seu site.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableDestaques'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch}/>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsDestaques'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={showModalDestaque}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listDestaques'>
                                        <Row>
                                            {
                                                filtered?.map((destaque) => (
                                                    <ItemDestaque destaqueInfo={destaque} token={token} handleAlert={handleAlert} onEdit={handleShowEditModal} onDelete={handleShowDeleteModal} />
                                                ))
                                            }

                                            <Col xs={12} sm={12} md={3} lg={3}> 
                                                <div className='AddDestaque'>
                                                    <Button onClick={showModalDestaque} data-tooltip-id="my-tooltip-adicionar" className='Btns Add'><Icon icon="ic:baseline-plus"></Icon></Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>                    
                <ModalDelete deleteId={deleteId} show={showDelete} handleClose={handleCloseDeleteModal} handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess} handleAlert={handleAlert} />
                <DestaquesEditModal edit={edit} handleClose={handleCloseEditModal} handleCloseEditModalSuccess={handleCloseEditModalSuccess} showEditar={showEditar} veiculos={veiculos} token={token} getData={getData} handleAlert={handleAlert}/>
                <DestaquesModalNew showDestaque={showDestaque} veiculos={veiculos} token={token} getData={getData} handleAlert={handleAlert} />
                <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir Destaque" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                <ReactTooltip id="my-tooltip-ativar" place="bottom" content="Ativar Destaque" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                <ReactTooltip id="my-tooltip-pausar" place="bottom" content="Pausar Destaque" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
            </div>
        </>
    );
}
export default Destaques;