import React, { useEffect, useState } from 'react';
import "./index.scss"
import Alert from '../Alert';
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';
import api from '../../services/apiIdz';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';

//Imagens
import logo from './img/logo-idz.svg'
import logocliente from './img/logocliente.png'
import { useAuth } from '../../context/useAuth';


function Navbar({ menu, handleMenuToggle }) {
    const location = useLocation();
    const [newLeadsCount, setNewLeadsCount] = useState(0);
    const [name, setName] = useState("");
    const [isMenuMarketing, setMenuMarketing] = useState(false);
    const [isMenuEstetica, setMenuEstetica] = useState(false);
    const [isMenuBlindagem, setMenuBlindagem] = useState(false);
    const [isMenuConfig, setMenuConfig] = useState(false);
    const [avatar, setAvatar] = useState('')
    const { logout, user, getInitials } = useAuth()

    useEffect(() => {
        console.log(user)
        if (Object.keys(user).length > 0) {

            let splited = user?.nome?.split(" ")
            console.log(splited)
            let last = splited[splited?.length - 1]

            setName(`${splited[0]} ${last}`)

            if (user.avatar) {
                setAvatar(user.avatar)
            }
        }
    }, [user])

    const fetchLeads = async () => {
        try {
            const response = await api.get(`/api/leads`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY
                }
            });

            if (response.data) {
                const currentDate = new Date();
                const data = response.data.map(lead => {
                    const dtinc = lead.dtinc.split('/');
                    const leadDate = new Date(dtinc[2], dtinc[1] - 1, dtinc[0]);
                    const isSameDate = leadDate.toDateString() === currentDate.toDateString();
                    const isYesterday = leadDate.toDateString() === new Date(currentDate.getTime() - 86400000).toDateString();
                    return isSameDate || isYesterday;
                });

                const newLeadsCount = data.filter(isNew => isNew).length;
                setNewLeadsCount(newLeadsCount);
            } else {
                console.log('Nenhum dado recebido da API.');
            }
        } catch (error) {
            console.error('Erro ao buscar os leads:', error);
        }
    };


    useEffect(() => {
        fetchLeads();
    }, []);

    // Open SubMenus
    const openMenuMarketing = () => {
        setMenuMarketing(!isMenuMarketing);
        if (isMenuConfig === true) {
            setMenuConfig(false);
        }
    };

    const openMenuEstetica = () => {
        setMenuEstetica(!isMenuEstetica);
        if (isMenuConfig === true) {
            setMenuConfig(false);
        }
    };

    const openMenuBlindagem = () => {
        setMenuBlindagem(!isMenuBlindagem);
        if (isMenuConfig === true) {
            setMenuConfig(false);
        }
    };
    

    const openMenuConfig = () => {
        setMenuConfig(!isMenuConfig);
        if (isMenuMarketing === true) {
            setMenuMarketing(false);
        }
    };

    useEffect(() => {
        // Verifica se a localização corresponde a uma das URLs do submenu de Marketing
        if (location.pathname === '/paginas' || location.pathname === '/banners' || location.pathname === '/destaques' || location.pathname === '/depoimentos') {
            setMenuMarketing(true);
        } else {
            setMenuMarketing(false);
        }
        // Verifica se a localização corresponde a uma das URLs do submenu de Configurações
        if (location.pathname === '/dados' || location.pathname === '/usuarios') {
            setMenuConfig(true);
        } else {
            setMenuConfig(false);
        }
    }, [location.pathname]);
    

    return (
        <>
            <aside>
                <div className={menu ? 'active container-header' : ' container-header'}>
                    {isMobile ? <a onClick={handleMenuToggle} className='close'><Icon className="icons" icon="iconamoon:close-bold" /></a> : null}
                    <a href="/">
                        <img className="logo" src={logo} />
                    </a>

                    <nav className="navbar">

                        <ul className="navbar-nav">
                            <li className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                                <a href="/dashboard">
                                    <Icon className="icons" icon="material-symbols:dashboard-customize" />
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li className={`nav-item ${location.pathname === '/leads' ? 'active' : ''}`}>
                                <a href="/leads">
                                    <Icon className="icons" icon="tabler:user-star" />
                                    <span>Leads
                                        {newLeadsCount != 0 &&
                                            <span className="number">{newLeadsCount}</span>
                                        }
                                    </span>
                                </a>
                            </li>
                            <li className={`nav-item ${location.pathname === '/analiseveiculo' ? 'active' : ''}`}>
                                <a href="/veiculos">
                                    <Icon className="icons" icon="fa-solid:car" />
                                    <span>Veículos</span>
                                </a>
                            </li>
                                {/* 
                                <li className={`nav-item desabilitado`}>
                                    <a>
                                        <Icon className="icons" icon="fa-solid:check" />
                                        <span>Análise de Veículos </span>
                                        <span className='breve'>breve</span>
                                    </a>
                                </li> 
                                */}
                            <li onClick={openMenuBlindagem} className={`nav-item dropdown  ${isMenuBlindagem === true ? 'open' : ''} ${location.pathname.startsWith('/blindagem') ? 'active' : ''}`}>
                                <a>
                                    <Icon className="icons" icon="wpf:security-checked" />
                                    <span>Blindagem <Icon className="icons" icon="icon-park-outline:down" /></span>
                                </a>
                                <ul>
                                    <li className={`${location.pathname === '/blindagem' ? 'active' : ''}`}>
                                        <a href="/blindagem">Cadastros <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/proposta' ? 'active' : ''}`}>
                                        <a href="/proposta">Propostas <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                </ul>
                            </li>
                            <li onClick={openMenuEstetica} className={`nav-item dropdown  ${isMenuEstetica === true ? 'open' : ''} ${location.pathname.startsWith('/estetica') ? 'active' : ''}`}>
                                <a>
                                    <Icon className="icons" icon="mdi:stars" />
                                    <span>Estética <Icon className="icons" icon="icon-park-outline:down" /></span>
                                </a>
                                <ul>
                                    <li className={`${location.pathname === '/estetica/categorias' ? 'active' : ''}`}>
                                        <a href="/estetica/categorias">Categorias <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/estetica/produtos' ? 'active' : ''}`}>
                                        <a href="/estetica/produtos">Produtos <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/estetica/tipos' ? 'active' : ''}`}>
                                        <a href="/estetica/tipos">Tipos de veiculos <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/estetica/marcas' ? 'active' : ''}`}>
                                        <a href="/estetica/marcas">Marcas <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                </ul>
                            </li>
                            <li onClick={openMenuMarketing} className={`nav-item dropdown  ${isMenuMarketing === true ? 'open' : ''} ${location.pathname.startsWith('/marketing') ? 'active' : ''}`}>
                                <a>
                                    <Icon className="icons" icon="nimbus:marketing" />
                                    <span>Marketing <Icon className="icons" icon="icon-park-outline:down" /></span>
                                </a>
                                <ul>
                                    <li className={`${location.pathname === '/banners' ? 'active' : ''}`}>
                                        <a href="/banners">Banners <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/destaques' ? 'active' : ''}`}>
                                        <a href="/destaques">Destaques <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/depoimentos' ? 'active' : ''}`}>
                                        <a href="/depoimentos">Depoimentos <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/paginas' ? 'active' : ''}`}>
                                        <a href="/paginas">Páginas <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                </ul>
                            </li>
                            <li onClick={openMenuConfig} className={`nav-item dropdown  ${isMenuConfig === true ? 'open' : ''} ${location.pathname === '/configuracoes' ? 'active' : ''}`}>
                                <a>
                                    <Icon className="icons" icon="icon-park-outline:config" />
                                    <span>Configurações <Icon className="icons" icon="icon-park-outline:down" /></span>
                                </a>
                                <ul>
                                    <li className={`${location.pathname === '/dados' ? 'active' : ''}`}>
                                        <a href="/dados">Dados <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                    <li className={`${location.pathname === '/usuarios' ? 'active' : ''}`}>
                                        <a href="/usuarios">Usuarios <Icon className="icons" icon="grommet-icons:form-next-link" /></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <div className="user-card profile">
                        <div className="profile">

                            <a className='avatar'>

                                {avatar != '' ? 
                                <img className="logo" src={`https://api.idzcar.com.br/${avatar}`} /> : 
                                <img className="logo" src={logocliente} />
                                }

                            </a>

                            <a href="/minhaconta">
                                <span>
                                    {name}
                                </span>
                            </a>

                            <a className='logout' onClick={logout}>
                                <Icon className="icons" icon="material-symbols:logout" />
                            </a>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
}
export default Navbar;
