import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import GPT from '../../../components/Veiculos/GPT';
import ReactDOMServer from 'react-dom/server';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"
import FileUpload from "../../../components/Veiculos/FileUpload"
import Alerts from '../../../components/Alerts';
import { Ring } from "@uiball/loaders"
import { useMediaQuery } from 'react-responsive';
import NewModelo from '../../../components/Veiculos/NewModelo'
import NewVidros from '../../../components/Veiculos/NewVidros'
import NewBlindagens from '../../../components/Veiculos/NewBlindagens'

//styles
import './Novo.scss';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';
import FileUploadSingle from '../../../components/Veiculos/FileUploadSingle';
import IdzFileUpload from '../../../components/Veiculos/idzFileUpload';
import ReaisInput from '../../../components/ReaisInput';
import KmInput from '../../../components/KmInput';
import { useParams } from 'react-router-dom';
import ButtonLoadingRing from '../../../components/ButtonLoading';

//Imagens 
import logo from '../../Dashboard/img/logo-idzcar.png'

function Veiculos() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [menu, setMenu] = useState(false);
    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    const { token, user, loading } = useAuth()

    const [statusMultiplos, setStatusMultiplos] = useState('on'); // Estado inicial
    const [multiplos, setMultiplos] = useState('');

    const handleClickStatusMultiplos = (newStatus) => {
        setStatusMultiplos(newStatus);
        if (newStatus === 'on') {
            setMultiplos('Ativo');
        } else if (newStatus === 'off') {
            setMultiplos('Inativo');
        } else if (newStatus === 'vendido') {
            setMultiplos('Vendido');
        } 
    };

    useEffect(() => {
        handleInputValueChange(multiplos, "status")
    }, [multiplos])


    const saveButton = useRef(null)
    const navigate = useNavigate()

    const [show, setShowModal] = useState(false);
    const [showBlindagens, setShowModalBlindagens] = useState(false);
    const [showVidros, setShowModalVidros] = useState(false);

    const showModal = () => {
        setShowModal(!show);
    };
    const showModalBlindagens = () => {
        setShowModalBlindagens(!showBlindagens);
    };
    const showModalVidros = () => {
        setShowModalVidros(!showVidros);
    };

    const [showTooltip, setShowTooltip] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');
    const [ativo, setAtivo] = useState(false);

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const handleSwitchToggle = () => {
        setAtivo(!ativo);
    };

    // useEffect(() => {
    //     handleInputValueChange(ativo ? "Ativo" : "Inativo", "status")
    // }, [ativo])



    const getMarcas = async () => {
        await api.get(`/api/marcas`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                console.log("[MARCAS]:", r.data)
                let lista = [

                ]
                for (let marca of r.data) {
                    if (marca.status == "Ativo") {
                        lista.push({ label: marca.titulo, value: marca.id })
                    }
                }
                setMarcas(lista)
            }
        })
    }

    const getModelos = async (marca_id) => {
        await api.get(`/api/modelos/marca/${marca_id}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                console.log("[MODELOS]:", r.data)
                let lista = [

                ]
                for (let modelo of r.data) {
                    if (modelo.status == "Ativo") {
                        lista.push({ label: modelo.titulo, value: modelo.id })
                    }
                }
                setModelos(lista)
            }
        })
    }

    const [combustivelList, setCombustivelList] = useState([])
    const [combustivelAuxList, setCombustivelAuxList] = useState([])
    const [fipe, setFipe] = useState(0)

    function converterPrecoParaNumero(precoString) {
        var precoSemSimbolo = precoString.replace(/[^\d,]/g, '');
        return parseFloat(precoSemSimbolo.replace(',', '.'));
    }


    const getModelosDetalhes = async (modelo_id, ano) => {
        await api.get(`/api/modelos/${modelo_id}/ano/${ano}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                console.log("[DETALHES_MODELO]:", r.data)

                let listaCombustivel = []
                let auxModelo = []
                for (let modelo of r.data) {
                    listaCombustivel.push({ label: modelo.combustivel, value: modelo.combustivel })
                    auxModelo.push(modelo)
                }

                // setCombustivelList(listaCombustivel)

                setCombustivelList([
                    { label: 'Gasolina', value: 'Gasolina' },
                    { label: 'Etanol', value: 'Etanol' },
                    { label: 'Flex', value: 'Flex ' },
                    { label: 'Diesel', value: 'Diesel' },
                    { label: 'GNV', value: 'GNV' },
                    { label: 'Híbrido', value: 'Híbrido' },
                    { label: 'Eletrico', value: 'Eletrico' }
                ])

                console.log("[COMBUSTIVEL_AUX_LIST]", auxModelo)
                let filtered = auxModelo.filter(d => d.combustivel.toLowerCase() == configData["combustivel"].toLowerCase())
                console.log("[FILTERED]", filtered)

                if (filtered.length > 0) {
                    setFipe(filtered[0].preco)
                } else {
                    console.log("Não encontrou combustivel semelhante, buscando o maior valor", auxModelo)

                    let maioresPrecos = auxModelo.map(function (produto) {
                        return converterPrecoParaNumero(produto.preco);
                    });

                    var maiorPrecoNumero = Math.max(...maioresPrecos);
                    var maiorPrecoFormatado = maiorPrecoNumero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });;

                    console.log("O maior preço entre os auxModelo é:", maiorPrecoFormatado);
                    setFipe(maiorPrecoFormatado)
                }

                setCombustivelAuxList(auxModelo)
            }
        })
    }

    const [opcionaisChumbado, setOpcionaisChumbado] = useState([
        { label: "Air Bag", value: "Air Bag" },
        { label: "Alarme", value: "Alarme" },
        { label: "Ar condicionado", value: "Ar condicionado" },
        { label: "Direção hidráulica", value: "Direção hidráulica" },
        { label: "Freio ABS", value: "Freio ABS" },
        { label: "Travas elétricas", value: "Travas elétricas" },
        { label: "Vidros elétricos", value: "Vidros elétricos" },
        { label: "Ar quente", value: "Ar quente" },
        { label: "Banco do motorista com ajuste de altura", value: "Banco do motorista com ajuste de altura" },
        { label: "Bancos em couro", value: "Bancos em couro" },
        { label: "Computador de bordo", value: "Computador de bordo" },
        { label: "Desembaçador traseiro", value: "Desembaçador traseiro" },
        { label: "Rodas de liga leve", value: "Rodas de liga leve" },
        { label: "Teto solar", value: "Teto solar" },
        { label: "Tração 4x4", value: "Tração 4x4" }
    ])


    const [opcionais, setOpcionais] = useState([
        { label: "Air Bag", value: "Air Bag" },
        { label: "Alarme", value: "Alarme" },
        { label: "Ar condicionado", value: "Ar condicionado" },
        { label: "Direção hidráulica", value: "Direção hidráulica" },
        { label: "Freio ABS", value: "Freio ABS" },
        { label: "Travas elétricas", value: "Travas elétricas" },
        { label: "Vidros elétricos", value: "Vidros elétricos" },
        { label: "Ar quente", value: "Ar quente" },
        { label: "Banco do motorista com ajuste de altura", value: "Banco do motorista com ajuste de altura" },
        { label: "Bancos em couro", value: "Bancos em couro" },
        { label: "Computador de bordo", value: "Computador de bordo" },
        { label: "Desembaçador traseiro", value: "Desembaçador traseiro" },
        { label: "Rodas de liga leve", value: "Rodas de liga leve" },
        { label: "Teto solar", value: "Teto solar" },
        { label: "Tração 4x4", value: "Tração 4x4" }
    ])


    const getAnosModelo = async (modelo_id) => {
        console.log(modelo_id)
        await api.get(`/api/modelos/${modelo_id}/anos`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                console.log("[ANOS_MODELO]:", r.data)
                let lista = [

                ]
                for (let ano of r.data) {
                    lista.push({ label: ano, value: ano })
                }
                setAnosModelo(lista)
            }
        })
    }

    const { id } = useParams();
    const [sending, setSending] = useState(false)

    const putVeiculo = async () => {

        // console.log("PutVeiculo")
        // console.log(`id`, id)
        // console.log(`configData`, configData)

        saveButton.current.disabled = true;
        setSending(true)

        await api.put(`/api/veiculos/${id}`, configData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")

                    setTimeout(() => {
                        navigate("/veiculos")
                    }, 4000)
                }
            }

        })
    }


    useEffect(() => {
        if (user) {
            getMarcas()
        }
    }, [loading])



    const [intInformation, setInformation] = useState(true);
    const [intOptions, setOptions] = useState(false);
    const [intDetail, setDetail] = useState(false);
    const [intPhoto, setPhoto] = useState(false);
    const [intUpload, setUpload] = useState(false);
    const [intSeo, setSeo] = useState(false);


    const [isChecked, setIsChecked] = useState(false);
    const [inputs, setInputs] = useState(['']);
    const [tours, setTours] = useState(['']);
    const [versaoSelecionada, setVersaoSelecionada] = useState(null);

    const handleInputsDynamic = (value, i) => {
        if (inputs) {
            const inputData = [...inputs]
            inputData[i] = value
            setInputs(inputData)
        }
    }
    const handleToursDynamic = (value, i) => {
        if (tours) {
            const tourData = [...tours]
            tourData[i] = value
            setTours(tourData)
        }
    }

    useEffect(() => {
        if (inputs) {
            handleInputValueChange(inputs, "videos")
        }
    }, [inputs])

    useEffect(() => {
        if (tours) {
            handleInputValueChange(tours, "tour")
        }
    }, [tours])

    const handleVersaoChange = (event) => {
        setVersaoSelecionada(event.value);
    };

    const handleAddInput = () => {
        if (inputs.length < 3) {
            setInputs([...inputs, '']);
        }
    };


    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    //BLINDAGEM START
    useEffect(() => {
        if (isChecked) {
            handleInputValueChange({
                blindado: 1,
                tipo_blindagem: "",
                tipo_vidro: "",
                garantia_vidro: "",
            }, "blindagem")
        } else {
            handleInputValueChange({
                blindado: 0,
                tipo_blindagem: "",
                tipo_vidro: "",
                garantia_vidro: "",
            }, "blindagem")
        }
    }, [])

    const handleCheckChange = (e) => {
        setIsChecked(e.target.checked);
        if (e.target.checked) {
            handleInputValueChange({
                blindado: 1,
                tipo_blindagem: "",
                tipo_vidro: "",
                garantia_vidro: "",
            }, "blindagem")
        } else {
            handleInputValueChange({
                blindado: 0,
                tipo_blindagem: "",
                tipo_vidro: "",
                garantia_vidro: "",
            }, "blindagem")
        }
    };

    function Phases(i) {
        setInformation(i == 'Information' ? true : false)
        setOptions(i == 'Options' ? true : false)
        setDetail(i == 'Detail' ? true : false)
        setPhoto(i == 'Photo' ? true : false)
        setUpload(i == 'Upload' ? true : false)
        setSeo(i == 'Seo' ? true : false)
    }

    const [marcas, setMarcas] = useState([])
    const [modelos, setModelos] = useState([])
    const [anosModelo, setAnosModelo] = useState([])

    const placeholdersCar = [

        { label: 'Amarelo', value: 'Amarelo' },
        { label: 'Azul', value: 'Azul' },
        { label: 'Bege', value: 'Bege' },
        { label: 'Branco', value: 'Branco' },
        { label: 'Bronze', value: 'Bronze' },
        { label: 'Cinza', value: 'Cinza' },
        { label: 'Dourado', value: 'Dourado' },
        { label: 'Indefinida', value: 'Indefinida' },
        { label: 'Laranja', value: 'Laranja' },
        { label: 'Marrom', value: 'Marrom' },
        { label: 'Prata', value: 'Prata' },
        { label: 'Preto', value: 'Preto' },
        { label: 'Rosa', value: 'Rosa' },
        { label: 'Roxo', value: 'Roxo' },
        { label: 'Verde', value: 'Verde' },
        { label: 'Vermelho', value: 'Vermelho' },
        { label: 'Vinho', value: 'Vinho' }
    ];

    const placeholdersOpcionais = [
        { label: 'Airbag', value: 'Airbag', disabled: false },
        { label: 'Ar-Condicionado', value: 'Ar-Condicionado', disabled: false },
        { label: 'Direção Elétrica', value: 'Direção Elétrica', disabled: false },
        { label: 'Bancos em Couro', value: 'Bancos em Couro', disabled: false }
    ];

    const placeholdersCombustivel = [
        { label: 'Gasolina', value: 'Gasolina' },
        { label: 'Etanol', value: 'Etanol' },
        { label: 'Diesel', value: 'Diesel' },
        { label: 'Flex (Gasolina/Etanol)', value: 'Flex (Gasolina/Etanol)' }
    ];

    const [blindagem, setBlindagem] = useState([]);
    const [vidros, setVidros] = useState([]);
    const [garantia, setGarantia] = useState([]);

    const getBlindagem = async () => {
        await api.get(`/api/atributos?atributo=tipo_blindagem`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                let lista = []
                for (let atributos of r.data.opcoes) {
                    lista.push({ label: atributos.label, value: atributos.id })
                }
                setBlindagem(lista)
            }
        })
    }

    const getVidros = async () => {
        await api.get(`/api/atributos?atributo=tipo_vidro`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                let lista = []
                for (let atributos of r.data.opcoes) {
                    lista.push({ label: atributos.label, value: atributos.id })
                }
                setVidros(lista)
            }
        })
    }

    const getGarantia = async () => {
        await api.get(`/api/atributos?atributo=garantia_vidro`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                console.log("[blindagem]:", r.data.opcoes)
                let lista = []
                for (let marca of r.data.opcoes) {
                    lista.push({ label: marca.label, value: marca.id })
                }
                setGarantia(lista)
            }
        })
    }

    useEffect(() => {
        getBlindagem();
        getVidros();
        getGarantia();
    }, []);

    useEffect(() => {
        if (blindagem.length > 0 && vidros.length > 0 && garantia.length > 0) {
            if (configData["blindagem"]) {
                // let blindagemId = blindagem.filter(d => d.label === )
                //configData["blindagem"]

                const blindagemAux = { ...configData["blindagem"] }

                if (configData["blindagem"]["tipo_blindagem"]) {
                    console.log(configData["blindagem"]["tipo_blindagem"].toLowerCase())
                    console.log("Blindagem List", blindagem.filter(r => r.label.toLowerCase() === configData["blindagem"]["tipo_blindagem"].toLowerCase()))
                    const id_blindagem = blindagem.filter(r => r.label.toLowerCase() === configData["blindagem"]["tipo_blindagem"].toLowerCase())[0]["value"]
                    blindagemAux.tipo_blindagem = id_blindagem;
                }
                if (configData["blindagem"]["tipo_vidro"]) {
                    console.log(configData["blindagem"]["tipo_vidro"].toLowerCase())
                    console.log("Vidros List", vidros.filter(r => r.label.toLowerCase() === configData["blindagem"]["tipo_vidro"].toLowerCase()))
                    const id_vidro = vidros.filter(r => r.label.toLowerCase() === configData["blindagem"]["tipo_vidro"].toLowerCase())[0]["value"]
                    blindagemAux.tipo_vidro = id_vidro
                }
                if (configData["blindagem"]["garantia_vidro"]) {
                    console.log(configData["blindagem"]["garantia_vidro"].toLowerCase())
                    console.log("Garantia List", garantia.filter(r => r.label.toLowerCase() === configData["blindagem"]["garantia_vidro"].toLowerCase()))
                    const id_garantia = garantia.filter(r => r.label.toLowerCase() === configData["blindagem"]["garantia_vidro"].toLowerCase())[0]["value"]
                    blindagemAux.garantia_vidro = id_garantia
                }

                setConfigData({
                    ...configData,
                    blindagem: blindagemAux
                })
            }
        }
    }, [blindagem, vidros, garantia])

    const [configData, setConfigData] = useState({
        detalhes: {
            titulo: '',
            chamada: '',
            descricao: '',
            detalhes_interno: '',
            detalhes_externo: ''
        },
        pagina_produto: {
            titulo: "",
            imagem: ""
        },
        pagina_busca: {
            titulo: "",
            imagem: ""
        },
        status: "Ativo"
    });

    const decodeHTML = (html) => {
        return html.replaceAll(`&amp;amp;amp;amp;amp;lt;`, "<").replaceAll(`&amp;amp;amp;amp;amp;gt;`, ">").replace(/\\/g, '').replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&quot;/g, '"').replace(/&amp;lt;/g, "<").replace(/&amp;gt;/g, ">").replace(/&amp;quot;/g, '"');
    }


    const getConfigData = async () => {

        await api.get(`/api/veiculos/${id}`).then(async (r) => {
            console.log(r.data)
            if (r.data) {
                console.log("[FIRST_CONFIG_DATA]:", r.data)

                let aux = r.data

                let htmlInterior = decodeHTML(aux["detalhes"]['detalhes_interno']);
                let htmlExterno = decodeHTML(aux["detalhes"]['detalhes_externo']);

                console.log(htmlInterior)
                console.log(htmlExterno)

                aux["detalhes"]['detalhes_interno'] = htmlInterior
                aux["detalhes"]['detalhes_externo'] = htmlExterno

                setConfigData(aux)

                if (r.data?.videos != '' && r.data?.videos?.length > 0) {
                    setInputs(r.data.videos)
                }

                if (r.data?.tour != '' && r.data?.tour.length > 0) {
                    setTours(r.data.tour)
                }

                if (r.data?.diferenciais != '' && r.data?.diferenciais.length > 0) {
                    setComponentes(r.data.diferenciais)
                    //console.log("[DIFERENCIAIS]:",r.data.diferenciais)
                }

                if (r.data?.blindagem.blindado == 1) {
                    setIsChecked(true);
                    handleInputValueChange({
                        blindado: 1,
                        tipo_blindagem: r.data?.blindagem.tipo_blindagem,
                        tipo_vidro: r.data?.blindagem.tipo_vidro,
                        garantia_vidro: r.data?.blindagem.garantia_vidro,
                    }, "blindagem")
                } else {
                    handleInputValueChange({
                        blindado: 0,
                        tipo_blindagem: "",
                        tipo_vidro: "",
                        garantia_vidro: "",
                    }, "blindagem")
                }

                console.log(r.data.status)

                if (r.data?.status == "Ativo") {
                    setStatusMultiplos("on")
                } else if (r.data?.status == "Inativo") {
                    setStatusMultiplos("off")
                }else if (r.data?.status == "Vendido") {
                    setStatusMultiplos("vendido")
                }
            }
        })
    }



    useEffect(() => {
        getConfigData()
    }, [])


    useEffect(() => {
        if (configData['combustivel']) {
            console.log("[COMBUSTIVEL_AUX_LIST]", combustivelAuxList)
            let filtered = combustivelAuxList.filter(d => d.combustivel.toLowerCase() == configData["combustivel"].toLowerCase())
            console.log("[FILTERED]", filtered)

            if (filtered.length > 0) {
                setFipe(filtered[0].preco)
            } else {
                console.log("Não encontrou combustivel semelhante, buscando o maior valor", combustivelAuxList)

                let maioresPrecos = combustivelAuxList.map(function (produto) {
                    return converterPrecoParaNumero(produto.preco);
                });

                var maiorPrecoNumero = Math.max(...maioresPrecos);
                var maiorPrecoFormatado = maiorPrecoNumero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });;

                console.log("O maior preço entre os auxModelo é:", maiorPrecoFormatado);
                setFipe(maiorPrecoFormatado)
            }
        }
    }, [configData['combustivel']])

    const handleInputValueChange = (value, field) => {
        setConfigData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleInputChange = (event, field) => {
        setConfigData(prevData => ({
            ...prevData,
            [field]: event.target.value
        }));
    };

    useEffect(() => {
        console.log(configData)
        console.log(configData["ano_modelo"], configData["modelo_id"])
        if (configData["ano_modelo"] && configData["modelo_id"]) {
            getModelosDetalhes(configData["modelo_id"], configData["ano_modelo"])
        }
    }, [configData["ano_modelo"], configData["modelo_id"]])

    function getBase64Image(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const [newUserInfo, setNewUserInfo] = useState({
        profileImages: []
    });

    useEffect(() => {
        console.log(newUserInfo)
    }, [newUserInfo])



    const updateUploadedMultipleFiles = async (files) => {
        console.log(files)

        if (files != null) {

            let images = []
            files.forEach((file, index) => {

                if (file.id && !file.src.includes("base64")) {
                    images.push({ id: file.id, imagem: file.src })
                } else {
                    images.push({ imagem: file.src })
                }

            })

            handleInputValueChange(images, "fotos")
        }

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        //logic to create new user...
    };


    // Opcionais

    const [opcionaisSelecionados, setOpcionaisSelecionados] = useState([]);
    const [diferenciaisDigitados, setDiferenciaisDigitados] = useState('');
    const [optionsDropdown, setOptionsDropdown] = useState(placeholdersOpcionais);
    const [loadingOpcionais, setLoadingOpcionais] = useState(true)

    useEffect(() => {
        console.log("[OPCIONAIS_CONFIG_DATA]", configData["opcionais"])
        if (typeof configData["opcionais"] != undefined) {
            if (configData["opcionais"] != '') {
                if (configData["opcionais"]?.length > 0 && loadingOpcionais) {
                    console.log("ENTROU NO IF DO OPCIONAIS")
                    setOpcionaisSelecionados(configData["opcionais"])
                    setLoadingOpcionais(false)
                }
            }
        }
    }, [configData["opcionais"]])

    useEffect(() => {
        if (opcionaisSelecionados) {
            handleInputValueChange(opcionaisSelecionados, "opcionais")
        }
    }, [opcionaisSelecionados])


    const handleDropdownChange = (event) => {
        const selectedOption = event.value;
        // const updatedOptions = optionsDropdown.map(option =>
        //     option.value === selectedOption ? { ...option, disabled: true } : option
        // );
        setOpcionaisSelecionados([...opcionaisSelecionados, selectedOption]);
        // setOptionsDropdown(updatedOptions);
    };

    useEffect(() => {
        console.log(opcionaisSelecionados)
        if (opcionaisSelecionados.length > 0) {
            let aux = [...opcionaisChumbado]; // Copia os opcionais para um novo array
            opcionaisSelecionados.forEach((dinamic) => {
                const itemIndex = aux.findIndex(d => d.value.toLowerCase() === dinamic.toLowerCase());
                if (itemIndex !== -1) {
                    aux.splice(itemIndex, 1); // Remove o item do array de opcionais
                }
            });
            setOpcionais(aux);
        }
    }, [opcionaisSelecionados]);

    useEffect(() => {
        console.log("opcionais alterados", opcionais)
    }, [opcionais])

    const handleInputTagsChange = (event) => {
        setDiferenciaisDigitados(event.target.value);
        //handleInputValueChange(event, "outros_diferenciais")
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' && diferenciaisDigitados.trim() !== '') {
            setOpcionaisSelecionados([...opcionaisSelecionados, diferenciaisDigitados.trim()]);
            setDiferenciaisDigitados('');
        }
    };

    const handleTagDelete = (tagIndex) => {
        const newTags = [...opcionaisSelecionados];
        newTags.splice(tagIndex, 1);
        setOpcionaisSelecionados(newTags);

        const deletedTag = opcionaisSelecionados[tagIndex];
        const updatedOptions = optionsDropdown.map(option =>
            option.value === deletedTag ? { ...option, disabled: false } : option
        );
        setOptionsDropdown(updatedOptions);
    };

    // Algo a destacar

    const [destaquesDigitados, setDestaquesDigitados] = useState('');
    const [destaquesSelecionados, setDestaquesSelecionados] = useState([]);


    const [loadingDestaques, setLoadingDestaques] = useState(true)

    useEffect(() => {
        console.log("[DESTAQUES_CONFIG_DATA]", configData["destaques"])
        if (typeof configData["destaques"] != undefined) {
            if (configData["destaques"] != '') {
                if (configData["destaques"]?.length > 0 && loadingDestaques) {
                    console.log("ENTROU NO IF DO DESTAQUES")
                    setDestaquesSelecionados(configData["destaques"])
                    setLoadingDestaques(false)
                }
            }
        }
    }, [configData["opcionais"]])

    useEffect(() => {
        if (destaquesSelecionados) {
            handleInputValueChange(destaquesSelecionados, "destaques")
        }
    }, [destaquesSelecionados])

    const handleInputKeyPressDestaques = (event) => {
        if (event.key === 'Enter' && destaquesDigitados.trim() !== '') {
            setDestaquesSelecionados([...destaquesSelecionados, destaquesDigitados.trim()]);
            setDestaquesDigitados('');
        }
    };

    const handleTagDeleteDestaques = (tagIndex) => {
        const newTags = [...destaquesSelecionados];
        newTags.splice(tagIndex, 1);
        setDestaquesSelecionados(newTags);
    };

    // Duplicar Diferenciais



    const [componentes, setComponentes] = useState([
        {
            chamada: "",
            descricao: "",
            imagem: ""
        }
    ]);
    const [mostrarBotaoAdd, setMostrarBotaoAdd] = useState(true);


    const handleAddComponente = () => {
        if (componentes.length < 4) { // Verifica se e menor 4
            setComponentes([...componentes, {
                chamada: "",
                descricao: "",
                imagem: ""
            }]); // Adiciona 
            if (componentes.length === 3) { // Verifica se e 3 
                setMostrarBotaoAdd(false); // Se =  3 esconder Add
            }
        }
    };

    const handleDeleteComponente = (index) => {
        const novosComponentes = componentes.filter(item => item !== index); // Remove o componente
        setComponentes(novosComponentes);
        if (!mostrarBotaoAdd && novosComponentes.length < 4) { // Verifica se o botão "Add" está oculto e se  é menor que 4
            setMostrarBotaoAdd(true); // Se for o caso, mostre "Add"
        }
    };

    const handleChangeComponente = (value, i, field) => {
        if (componentes) {
            const inputData = [...componentes]
            inputData[i][field] = value
            setComponentes(inputData)
        }
    }

    useEffect(() => {
        console.log(componentes)
        if (componentes) {
            handleInputValueChange(componentes, "diferenciais")
        }
    }, [componentes])


    const handleImagemTituloProduto = (value, field) => {
        let aux_paginaProduto = { ...configData["pagina_produto"] }
        aux_paginaProduto[field] = value
        console.log(aux_paginaProduto)
        handleInputValueChange(aux_paginaProduto, "pagina_produto")
    }

    const handleImagemPaginaProduto = (image) => {
        handleImagemTituloProduto(image, "imagem")
    }

    const handleImagemTituloBusca = (value, field) => {
        let aux_paginaBusca = { ...configData["pagina_busca"] }
        aux_paginaBusca[field] = value
        console.log(aux_paginaBusca)
        handleInputValueChange(aux_paginaBusca, "pagina_busca")
    }

    const handleImagemBusca = (image) => {
        handleImagemTituloBusca(image, "imagem")
    }

    useEffect(() => {
        if (configData["marca_id"]) {
            getModelos(configData["marca_id"])
        }
    }, [configData["marca_id"]])

    useEffect(() => {
        if (configData["modelo_id"]) {
            getAnosModelo(configData["modelo_id"])
        }
    }, [configData["modelo_id"]])

    const [anoFabricacao, setAnoFabricacao] = useState([])
    useEffect(() => {
        if (configData["ano_modelo"]) {
            setAnoFabricacao([
                { label: configData["ano_modelo"], value: configData["ano_modelo"] },
                { label: configData["ano_modelo"] + 1, value: configData["ano_modelo"] + 1 }
            ])
        }
    }, [configData["ano_modelo"]])

    const handlePriceChange = (value) => {
        handleInputValueChange(value, "preco")
    }
    const handleKmChange = (value) => {
        handleInputValueChange(value, "km")
    }

    useEffect(() => {
        if (configData["preco"]) {
            console.log(configData["preco"])
        }
    }, [configData["preco"]])

    /* WRONG AREA */


    const [wrongData, setWrongData] = useState(
        {
            informacoes_iniciais: {
                fields: {
                    marca_id: false,
                    modelo_id: false,
                    ano_modelo: false,
                    ano_fabricacao: false,
                    cor: false,
                    combustivel: false,
                    preco: false,
                    km: false
                },
                allow: true,
                to_allow: "opcionais",
                next: "Options",
                back: ""
            },
            opcionais: {
                fields: {

                },
                allow: true,
                to_allow: "detalhes",
                next: "Detail",
                back: "Information"
            },
            detalhes: {
                fields: {
                    titulo: false,
                    chamada: false,
                    descricao: false,
                    detalhes_interno: false,
                    detalhes_externo: false
                },
                allow: true,
                to_allow: "fotos",
                next: "Photo",
                back: "Options"
            },
            fotos: {
                fields: {

                },
                allow: true,
                to_allow: "videos_links",
                next: "Upload",
                back: "Detail"
            },
            videos_links: {
                fields: {

                },
                allow: true,
                to_allow: "seo",
                next: "Seo",
                back: "Photo"
            },
            seo: {
                fields: {

                },
                allow: true,
                to_allow: "",
                next: "",
                back: "Upload"
            }
        }
    )

    const [disabledOpcionais, setDisabledOpcionais] = useState(true);
    const [disabledDetalhes, setDisabledDetalhes] = useState(true);
    const [disabledFotos, setDisabledFotos] = useState(true);
    const [disabledVideo, setDisabledVideo] = useState(true);
    const [disabledSeo, setDisabledSeo] = useState(true);

    const checkDisableds = () => {


        if (wrongData.opcionais.allow) {
            setDisabledOpcionais(false)
        }

        if (wrongData.detalhes.allow) {
            setDisabledDetalhes(false)
        }

        if (wrongData.fotos.allow) {
            setDisabledFotos(false)
        }

        if (wrongData.videos_links.allow) {
            setDisabledVideo(false)
        }

        if (wrongData.seo.allow) {
            setDisabledSeo(false)
        }

    }

    useEffect(() => {
        if (wrongData) {
            console.log("[WRONG_DATA]:", wrongData)
            checkDisableds()
        }
    }, [wrongData])

    const handleCheckMandatoryFields = (phase) => {

        console.log(phase, wrongData)
        const phaseData = wrongData[phase];

        if (!phaseData) {
            console.error(`Phase '${phase}' não encontrada em wrongData`);
            return;
        }

        const fields = phaseData.fields;

        let allAreFalse = false;
        let fieldSize = Object.keys(wrongData[phase].fields).length
        let currentAmountOfFalse = 0;

        Object.keys(wrongData[phase].fields).forEach((key, index) => {

            const field = fields[key];

            console.log(key, field)
            console.log(`[configData[${key}]]:${configData[key]}`, typeof configData[key] == 'undefined', configData[key] == '')

            if (typeof configData[key] == 'undefined' || configData[key] == '') {

                setWrongData(prevState => ({
                    ...prevState,
                    [phase]: {
                        ...prevState[phase],
                        fields: {
                            ...prevState[phase].fields,
                            [key]: true
                        }
                    }
                }
                ))

            } else {

                setWrongData(prevState => ({
                    ...prevState,
                    [phase]: {
                        ...prevState[phase],
                        fields: {
                            ...prevState[phase].fields,
                            [key]: false
                        }
                    }
                }
                ))

                currentAmountOfFalse++;

                // console.log(currentAmountOfFalse, fieldSize)

                // if (currentAmountOfFalse == fieldSize) {
                //     allAreFalse = true
                // }
            }
        })


        if (currentAmountOfFalse === fieldSize) {
            setWrongData(prevState => ({
                ...prevState,
                [phase]: {
                    ...prevState[phase],
                    allow: true
                }
            }
            ))

            setWrongData(prevState => ({
                ...prevState,
                [wrongData[phase].to_allow]: {
                    ...wrongData[wrongData[phase].to_allow],
                    allow: true
                }
            }
            ))

            Phases(wrongData[phase].next)
        } else {
            handleAlert('Informações Insuficientes', `Favor verificar os campos obrigatórios`, 'danger')
        }
    }

    const handleCheckMandatoryDetailsFields = (phase) => {

        console.log(phase, wrongData)
        const phaseData = wrongData[phase];

        if (!phaseData) {
            console.error(`Phase '${phase}' não encontrada em wrongData`);
            return;
        }

        const fields = phaseData.fields;

        let allAreFalse = false;
        let fieldSize = Object.keys(wrongData[phase].fields).length
        let currentAmountOfFalse = 0;

        Object.keys(wrongData[phase].fields).forEach((key, index) => {

            const field = fields[key];

            console.log(key, field)
            console.log(`[configData["detalhes"][${key}]]:${configData["detalhes"][key]}`, typeof configData['detalhes'][key] == 'undefined', configData['detalhes'][key] == '')

            if (typeof configData['detalhes'][key] == 'undefined' || configData['detalhes'][key] == '') {

                setWrongData(prevState => ({
                    ...prevState,
                    [phase]: {
                        ...prevState[phase],
                        fields: {
                            ...prevState[phase].fields,
                            [key]: true
                        }
                    }
                }
                ))

            } else {

                setWrongData(prevState => ({
                    ...prevState,
                    [phase]: {
                        ...prevState[phase],
                        fields: {
                            ...prevState[phase].fields,
                            [key]: false
                        }
                    }
                }
                ))

                currentAmountOfFalse++;

                // console.log(currentAmountOfFalse, fieldSize)

                // if (currentAmountOfFalse == fieldSize) {
                //     allAreFalse = true
                // }
            }
        })


        if (currentAmountOfFalse === fieldSize) {
            setWrongData(prevState => ({
                ...prevState,
                [phase]: {
                    ...prevState[phase],
                    allow: true
                }
            }
            ))

            setWrongData(prevState => ({
                ...prevState,
                [wrongData[phase].to_allow]: {
                    ...wrongData[wrongData[phase].to_allow],
                    allow: true
                }
            }
            ))

            Phases(wrongData[phase].next)
        } else {
            handleAlert('Informações Insuficientes', `Favor verificar os campos obrigatórios`, 'danger')
        }
    }

    const checkNotDisabled = (fieldBool, disabledClass) => {
        if (fieldBool) {
            return disabledClass
        } else {
            return ''
        }
    }

    const [text, setText] = useState('');

    const modules = {
        toolbar: [
            ['bold', 'italic'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ],
    };

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Evita que o formulário seja enviado ao pressionar Enter
            const newTag = inputValue.trim();
            if (newTag && tags.length < 4) { // Verifica se há menos de 4 tags
                setTags([...tags, newTag]);
                setInputValue('');
            }
        }
    };

    useEffect(() => {
        if (tags) {
            handleInputValueChange(tags, "palavras_chave")
        }
    }, [tags])

    const handleRemoveTag = (indexToRemove) => {
        setTags(tags.filter((tag, index) => index !== indexToRemove));
    };

    const [tooltipContent, setTooltipContent] = useState('');

    const handleMouseEnter = (content) => {
        setTooltipContent(content);
    };



    //IA

    useEffect(() => {
        if (intDetail) {
            handleCarData();
        }
    }, [intDetail]);

    const [carData, setCarData] = useState({ modelo: '', marca: '', ano: '' });

    const handleCarData = () => {

        const modeloId = configData["modelo_id"];
        const modelo = modelos.find(item => item.value === modeloId);
        console.log(modelo.label)

        const marcaId = configData["marca_id"];
        const marca = marcas.find(item => item.value === marcaId);
        console.log(marca.label)

        const ano = configData["ano_modelo"];
        console.log(ano)

        const carInfo = `${marca ? marca.label : ''} ${modelo ? modelo.label : ''} ${ano ? ano : ''}`;
        setCarData(carInfo);

    };

    const handleModelResponse = (response) => {
        try {
            // Limpe os campos em configData
            setConfigData(prevData => ({
                ...prevData,
                detalhes: {
                    ...prevData.detalhes,
                    titulo: '',
                    chamada: '',
                    detalhes_externo: '',
                    detalhes_interno: '',
                    descricao: ''
                },

            }));
            const parsedResponse = JSON.parse(response);

            const { titulo, chamada, exterior, interior, descricao } = parsedResponse;

            const exteriorHTML = (
                <ul>
                    {exterior.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            );
            const interiorHTML = (
                <ul>
                    {interior.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            );
            const interiorHTMLString = ReactDOMServer.renderToStaticMarkup(interiorHTML);
            const exteriorHTMLString = ReactDOMServer.renderToStaticMarkup(exteriorHTML);

            console.log("interiorHTML", interiorHTMLString);

            setConfigData(prevData => ({
                ...prevData,
                detalhes: {
                    ...prevData.detalhes,
                    titulo: titulo || '',
                    chamada: chamada || '',
                    detalhes_externo: exteriorHTMLString || <ul></ul>, // Valor padrão para interiorHTML
                    detalhes_interno: interiorHTMLString || <ul></ul>, // Valor padrão para interiorHTML
                    descricao: descricao || ''
                },
                // Atualize outros campos de configData, se necessário
            }));
        } catch (error) {
            console.error("Erro ao analisar a resposta como JSON:", error);
        }
    };

    useEffect(() => {
        console.log("input videos:", configData)
    }, [configData])

    const [loadingIA, setLoadingIA] = useState(false);

    const setLoadingStatus = (status) => {
        setLoadingIA(status);
    };




    return (
        <>
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Veiculos">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="fa-solid:car" ></Icon></i>
                                <h2>Veículos <br />
                                    <span>Gerencie informações e detalhes dos veículos cadastrados</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NavVeiculos'>
                                <ul>
                                    <li>
                                        <a onClick={() => wrongData.informacoes_iniciais.allow && Phases("Information")} className={`${intInformation ? 'active' : ''} `}>
                                            <i><Icon className="icons" icon="fa-solid:car" /></i>
                                            <span>INFORMAÇÕES <br />INICIAIS</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => wrongData.opcionais.allow && Phases("Options")} className={`${intOptions ? 'active' : ''} ${disabledOpcionais ? "disabled-phase" : ""}`}>
                                            <i><Icon className="icons" icon="mdi:car-seat" /></i>
                                            <span>OPCIONAIS <br />&nbsp;</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() =>
                                            wrongData.detalhes.allow && Phases("Detail")
                                        } className={`${intDetail ? 'active' : ''} ${disabledDetalhes ? "disabled-phase" : ""}`}>
                                            <i><Icon className="icons" icon="mingcute:car-window-fill" /></i>
                                            <span>DETALHES <br />&nbsp;</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => wrongData.fotos.allow && Phases("Photo")} className={`${intPhoto ? 'active' : ''} ${disabledFotos ? "disabled-phase" : ""}`}>
                                            <i><Icon className="icons" icon="mingcute:pic-fill" /></i>
                                            <span>FOTOS <br />&nbsp; </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => wrongData.videos_links.allow && Phases("Upload")} className={`${intUpload ? 'active' : ''} ${disabledVideo ? "disabled-phase" : ""}`}>
                                            <i><Icon className="icons" icon="fluent:video-32-filled" /></i>
                                            <span>VÍDEOS E LINKS <br />&nbsp; </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => wrongData.seo.allow && Phases("Seo")} className={`${intSeo ? 'active' : ''} ${disabledSeo ? "disabled-phase" : ""}`}>
                                            <i><Icon className="icons" icon="icon-park-outline:search" /></i>
                                            <span>SEO<br />&nbsp; </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {intInformation &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo Informacoes'>
                                                <h2><i><Icon icon="fa-solid:car"></Icon></i> Informações iniciais</h2>
                                                <div className='statusMultiplos'>
                                                    <span className={statusMultiplos}></span>
                                                    <ul>
                                                        <li className={statusMultiplos === 'on' ? 'ativo' : ''} onClick={() => handleClickStatusMultiplos('on')}>ON</li>
                                                        <li className={statusMultiplos === 'off' ? 'ativo' : ''} onClick={() => handleClickStatusMultiplos('off')}>OFF</li>
                                                        <li className={statusMultiplos === 'vendido' ? 'ativo' : ''} onClick={() => handleClickStatusMultiplos('vendido')}>Vendido</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Escolha a marca </span>
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["marca_id"] && !configData["marca_id"] ? 'wrongField' : ''}>
                                                                <Dropdown filter value={configData["marca_id"]} onChange={(e) => { handleInputChange(e, "marca_id") }} options={marcas} optionLabel="label" placeholder="Marca" className='Dropdown' />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Escolha o modelo</span><div onClick={() => { showModal() }} className='naoEncontrol'>Não encontrou?</div>
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["modelo_id"] && !configData["modelo_id"] ? 'wrongField' : ''}>
                                                                <Dropdown filter value={configData["modelo_id"]} onChange={(e) => { handleInputChange(e, "modelo_id") }} options={modelos} optionLabel="label" placeholder="Escolha o Modelo" className='Dropdown' />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Escolha o ano de fabricação</span>
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["ano_modelo"] && !configData["ano_modelo"] ? 'wrongField' : ''}>
                                                                <Dropdown value={configData["ano_modelo"]} onChange={(e) => { handleInputChange(e, "ano_modelo") }} options={anosModelo} optionLabel="label" placeholder="Ano do Modelo" className='Dropdown' />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Escolha o ano do modelo</span>
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["ano_fabricacao"] && !configData["ano_fabricacao"] ? 'wrongField' : ''}>
                                                                <Dropdown value={configData["ano_fabricacao"]} onChange={(e) => {
                                                                    handleInputChange(e, "ano_fabricacao")
                                                                }} options={anoFabricacao} optionLabel="label" placeholder="Ano de Fabricação" className='Dropdown' />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>escolha uma cor</span>
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["cor"] && !configData["cor"] ? 'wrongField' : ''}>
                                                                <Dropdown filter value={configData["cor"]} onChange={(e) => { handleInputChange(e, "cor") }} options={placeholdersCar} optionLabel="label" placeholder="Cor" className='Dropdown' />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>escolha o tipo de combustível</span>
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["combustivel"] && !configData["combustivel"] ? 'wrongField' : ''}>
                                                                <Dropdown
                                                                    value={configData["combustivel"]}
                                                                    options={combustivelList}
                                                                    onChange={(e) => { handleInputChange(e, "combustivel") }}
                                                                    optionLabel="label"
                                                                    placeholder="Combustível"
                                                                    className='Dropdown'
                                                                />
                                                            </div>
                                                        </Form.Label>
                                                    </Col>

                                                    <Col xs={12} sm={12} md={6} lg={12}>
                                                        <Form.Check className='Label' type="switch" label="O veículo é blindado?" checked={isChecked} onChange={handleCheckChange}>
                                                        </Form.Check>
                                                    </Col>
                                                    {isChecked && (<>
                                                        <Col xs={12} sm={12} md={6} lg={3}>
                                                            <Form.Label className='Label'>
                                                                <span>Tipo de vidro</span><div onClick={() => { showModalVidros() }} className='naoEncontrol'>Não encontrou?</div>
                                                                <Dropdown filter emptyMessage="Sem resultado" value={configData["blindagem"]["tipo_vidro"]} onChange={
                                                                    (e) => {
                                                                        setConfigData(prevData => ({
                                                                            ...prevData,
                                                                            "blindagem": {
                                                                                ...prevData.blindagem,
                                                                                "tipo_vidro": e.target.value
                                                                            }
                                                                        }));
                                                                    }
                                                                } options={vidros} optionLabel="label" placeholder="Escolha o tipo de vidro" className='Dropdown' />
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={3}>
                                                            <Form.Label className='Label'>
                                                                <span>Garantia do vidro</span>
                                                                <Dropdown emptyMessage="Sem resultado" value={configData["blindagem"]["garantia_vidro"]} onChange={
                                                                    (e) => {
                                                                        setConfigData(prevData => ({
                                                                            ...prevData,
                                                                            "blindagem": {
                                                                                ...prevData.blindagem,
                                                                                "garantia_vidro": e.target.value
                                                                            }
                                                                        }));
                                                                    }
                                                                } options={garantia} optionLabel="label" placeholder="Escolha a garantia do vidro" className='Dropdown' />
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={6}>
                                                            <Form.Label className='Label'>
                                                                <span>Tipo de Blindagem</span><div onClick={() => { showModalBlindagens() }} className='naoEncontrol'>Não encontrou?</div>
                                                                <Dropdown filter emptyMessage="Sem resultado" value={configData["blindagem"]["tipo_blindagem"]} onChange={
                                                                    (e) => {
                                                                        setConfigData(prevData => ({
                                                                            ...prevData,
                                                                            "blindagem": {
                                                                                ...prevData.blindagem,
                                                                                "tipo_blindagem": e.target.value
                                                                            }
                                                                        }));
                                                                    }
                                                                } options={blindagem} optionLabel="label" placeholder="Escolha o tipo de blindagem" className='Dropdown' />
                                                            </Form.Label>
                                                        </Col>
                                                    </>)}
                                                </Row>

                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>conte mais sobre o carro</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>quilometragem</span>
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["km"] && !configData["km"] ? 'wrongField' : ''}>
                                                                {/* <Form.Control className='Inputs noMarginButtonInput' type="text" value={configData["km"]} onChange={(e) => { handleInputChange(e, "km") }} placeholder="Quanto o carro já rodou?" /> */}
                                                                <KmInput value={configData["km"]} handleChange={handleKmChange}></KmInput>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Preço</span>
                                                            <div className='Fipe'>
                                                                <i></i>
                                                                {(configData["ano_fabricacao"] && configData["combustivel"]) && (
                                                                    <span><strong>FIPE {configData["ano_modelo"]}:</strong> {fipe}</span>
                                                                )}
                                                            </div>
                                                            {/* <Form.Control value={configData["preco"]} onChange={(e) => { handleInputChange(e, "preco") }} className='Inputs' type="text" placeholder="Por quanto quer vender?" /> */}
                                                            <div className={wrongData["informacoes_iniciais"]["fields"]["preco"] && !configData["preco"] ? 'wrongField' : ''}>
                                                                <ReaisInput value={configData["preco"]} handleChange={handlePriceChange}></ReaisInput>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {/* <Button className='Btns ButtonSecondary'>Voltar</Button> */}
                                                {/* <Button className='Btns ButtonPrimaryLight' onClick={() => { handleCheckMandatoryFields("informacoes_iniciais") }}>Continuar</Button> */}
                                                <Button className='Btns ButtonPrimaryLight Salvar' ref={saveButton} onClick={putVeiculo}>
                                                    <ButtonLoadingRing sending={sending} />
                                                    Salvar
                                                </Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intOptions &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2><i><Icon icon="mdi:car-seat"></Icon></i> Opcionais</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Selecione os opcionais</span>
                                                            <Dropdown
                                                                filter
                                                                value={null}
                                                                options={opcionais}
                                                                onChange={handleDropdownChange}
                                                                optionLabel="label"
                                                                placeholder="Opcionais"
                                                                className='Dropdown'
                                                            />
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Form.Label className='Label'>
                                                            <span>Outros Diferenciais</span>
                                                            <div className="FormControl Full">
                                                                <Form.Control
                                                                    className='Inputs'
                                                                    type="text"
                                                                    placeholder="Escreva os Diferenciais"
                                                                    value={diferenciaisDigitados}
                                                                    onChange={handleInputTagsChange}
                                                                    onKeyPress={handleInputKeyPress}
                                                                />
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Escreva os destaques e dê um enter para incluí-los')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <div className='TagsInput'>
                                                            {opcionaisSelecionados.map((tag, index) => (
                                                                <span key={index}>
                                                                    {tag}
                                                                    <i onClick={() => handleTagDelete(index)}><Icon icon="iconamoon:close-duotone"></Icon></i>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2>Tem algo mais que queira destacar?</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <Form.Label className='Label'>
                                                            <span>outros destaques</span>
                                                            <div className="FormControl Full">
                                                                <Form.Control
                                                                    className='Inputs'
                                                                    type="text"
                                                                    placeholder="Escreva os destaques"
                                                                    value={destaquesDigitados}
                                                                    onChange={(e) => {
                                                                        setDestaquesDigitados(e.target.value)
                                                                        //handleInputValueChange(e.target.value, "destaques")
                                                                    }}
                                                                    onKeyPress={handleInputKeyPressDestaques}
                                                                />
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Escreva os destaques e dê um enter para incluí-los')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <div className='TagsInput'>
                                                            {destaquesSelecionados.map((destaque, index) => (
                                                                <span key={index}>
                                                                    {destaque}
                                                                    <i onClick={() => handleTagDeleteDestaques(index)}>
                                                                        <Icon icon="iconamoon:close-duotone"></Icon>
                                                                    </i>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {/* <Button className='Btns ButtonSecondary' onClick={() => Phases(wrongData.opcionais.back)} > Voltar</Button>
                                                <Button className='Btns ButtonPrimaryLight' onClick={() => { handleCheckMandatoryFields("opcionais") }}>Continuar</Button> */}
                                                <Button className='Btns ButtonPrimaryLight Salvar' ref={saveButton} onClick={putVeiculo}>
                                                    <ButtonLoadingRing sending={sending} />
                                                    Salvar
                                                </Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intDetail &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2><i><Icon icon="mingcute:car-window-fill"></Icon></i> Detalhes</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col>

                                                        <GPT carInfo={carData} onModelResponse={handleModelResponse} setLoadingStatus={setLoadingStatus} />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <Form.Label className='Label'>
                                                            <span>Título</span>
                                                            <div className="FormControl Full">
                                                                <div className={`loadingIA ${loadingIA == true ? 'active' : ''} ${wrongData["detalhes"]["fields"]["titulo"] && !configData["detalhes"]["titulo"] ? 'wrongField' : ''}`}>
                                                                    <Form.Control
                                                                        className='Inputs'
                                                                        type="text"
                                                                        value={configData["detalhes"]["titulo"]}
                                                                        onChange={(e) =>
                                                                            setConfigData(prevData => ({
                                                                                ...prevData,
                                                                                "detalhes": {
                                                                                    ...prevData.detalhes,
                                                                                    "titulo": e.target.value
                                                                                }
                                                                            }))
                                                                        } placeholder="Título do veículo" />
                                                                </div>
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Adicione uma titulo com no máximo 30 caracteres')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <Form.Label className='Label'>
                                                            <span>Chamada</span>
                                                            <div className="FormControl Full">
                                                                <div className={`loadingIA ${loadingIA == true ? 'active' : ''} ${wrongData["detalhes"]["fields"]["chamada"] && !configData["detalhes"]["chamada"] ? 'wrongField' : ''}`}>
                                                                    <Form.Control
                                                                        className='Inputs'
                                                                        type="text"
                                                                        value={configData["detalhes"]["chamada"]}
                                                                        onChange={(e) =>
                                                                            // handleInputChange(e, "chamada_pagina")
                                                                            setConfigData(prevData => ({
                                                                                ...prevData,
                                                                                "detalhes": {
                                                                                    ...prevData.detalhes,
                                                                                    "chamada": e.target.value
                                                                                }
                                                                            }))
                                                                        } placeholder="Chamada da página" />
                                                                </div>
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Adicione uma chamada com no máximo 140 caracteres')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <Form.Label className='Label'>
                                                            <span>Descrição</span>
                                                            <div className={`loadingIA ${loadingIA == true ? 'active' : ''} textarea`}>
                                                                <textarea
                                                                    className={wrongData["detalhes"]["fields"]["descricao"] && !configData["detalhes"]["descricao"] ? 'wrongField' : ''}
                                                                    value={configData["detalhes"]["descricao"]}
                                                                    placeholder="Digite seu texto aqui..."
                                                                    onChange={(e) =>
                                                                        //handleInputChange(e, "descricao")
                                                                        setConfigData(prevData => ({
                                                                            ...prevData,
                                                                            "detalhes": {
                                                                                ...prevData.detalhes,
                                                                                "descricao": e.target.value
                                                                            }
                                                                        }))
                                                                    }></textarea>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <Form.Label className='Label'>
                                                            <span>Detalhes internos</span>
                                                            <ReactQuill
                                                                value={configData["detalhes"]["detalhes_interno"]}
                                                                className={`loadingIA quill ${loadingIA == true ? 'active' : ''} ${wrongData["detalhes"]["fields"]["detalhes_interno"] && !configData["detalhes"]["detalhes_interno"] ? 'wrongField' : ''}`}
                                                                modules={modules}
                                                                placeholder="Digite os detalhes internos aqui..."
                                                                dangerouslySetInnerHTML={{ __html: configData["detalhes"]["detalhes_interno"] }}
                                                                onChange={(value) => setConfigData(prevState => ({
                                                                    ...prevState,
                                                                    detalhes: {
                                                                        ...prevState.detalhes,
                                                                        detalhes_interno: value
                                                                    }
                                                                }))}
                                                            />
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        <Form.Label className='Label'>
                                                            <span>Detalhes externos</span>
                                                            <ReactQuill
                                                                value={configData["detalhes"]["detalhes_externo"]}
                                                                className={`loadingIA quill ${loadingIA == true ? 'active' : ''} ${wrongData["detalhes"]["fields"]["detalhes_externo"] && !configData["detalhes"]["detalhes_externo"] ? 'wrongField' : ''}`}
                                                                modules={modules}
                                                                placeholder="Digite os detalhes externos aqui..."
                                                                dangerouslySetInnerHTML={{ __html: configData["detalhes"]["detalhes_externo"] }}
                                                                onChange={(value) => setConfigData(prevState => ({
                                                                    ...prevState,
                                                                    detalhes: {
                                                                        ...prevState.detalhes,
                                                                        detalhes_externo: value
                                                                    }
                                                                }))}
                                                            />
                                                        </Form.Label>
                                                    </Col>F
                                                </Row>

                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {/* <Button className='Btns ButtonSecondary' onClick={() => Phases(wrongData.detalhes.back)} >Voltar</Button>
                                                <Button className='Btns ButtonPrimaryLight' onClick={() => { handleCheckMandatoryDetailsFields("detalhes") }}>Continuar</Button> */}
                                                <Button className='Btns ButtonPrimaryLight Salvar' ref={saveButton} onClick={putVeiculo}>
                                                    <ButtonLoadingRing sending={sending} />
                                                    Salvar
                                                </Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intPhoto &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2><i><Icon icon="mingcute:pic-fill"></Icon></i> Fotos</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    {/* <FileUpload
                                                        accept=".jpg,.png,.jpeg"
                                                        multiple
                                                        updateFilesCb={(files) => {
                                                            updateUploadedFiles(files, "destaque")
                                                        }}
                                                    /> */}
                                                    <IdzFileUpload
                                                        multiple
                                                        handleUpload={updateUploadedMultipleFiles}
                                                        currentFile={configData["fotos"]}
                                                    ></IdzFileUpload>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2><i><Icon icon="mingcute:pic-fill"></Icon></i> Fotos de destaques</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={3} lg={6}>
                                                        <label className='labelInfo'><i><Icon icon="material-symbols:info-outline"></Icon></i> Foto em Destaque na <strong> Página do Produto</strong></label>
                                                        <div className="ImagesUploader Full">
                                                            <IdzFileUpload currentFile={configData["pagina_produto"]["imagem"]} handleUpload={handleImagemPaginaProduto}></IdzFileUpload>
                                                            <span>
                                                                <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                                <h6>Carregar foto</h6>
                                                                <p>Arquivos permitidos: .jpeg, .png</p>
                                                            </span>
                                                        </div>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Titulo da Foto" value={configData["pagina_produto"]?.titulo} onChange={(e) => handleImagemTituloProduto(e.target.value, "titulo")} />
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Adicione uma descrição com no máximo 80 caracteres')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={3} lg={6}>
                                                        <label className='labelInfo'><i><Icon icon="material-symbols:info-outline"></Icon></i> Foto em Destaque na <strong>Página de Busca</strong></label>
                                                        <div className="ImagesUploader Full">
                                                            <IdzFileUpload currentFile={configData["pagina_busca"]["imagem"]} handleUpload={handleImagemBusca}></IdzFileUpload>
                                                            <span>
                                                                <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                                <h6>Carregar foto</h6>
                                                                <p>Arquivos permitidos: .jpeg, .png</p>
                                                            </span>
                                                        </div>
                                                        <Form.Label className='Label'>
                                                            <div className="FormControl Full">
                                                                <Form.Control className='Inputs' type="text" placeholder="Titulo da Foto" value={configData["pagina_busca"]?.titulo} onChange={(e) => handleImagemTituloBusca(e.target.value, "titulo")} />
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Adicione uma descrição com no máximo 80 caracteres')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2><i><Icon icon="mingcute:pic-fill"></Icon></i> DIFERENCIAIS</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                {componentes.map((indice, idx) => (
                                                    <>
                                                        <Row key={idx}>
                                                            <Col xs={12} sm={12} md={3} lg={3}>
                                                                <div className="ImagesUploader">
                                                                    <IdzFileUpload currentFile={configData["diferenciais"][idx]?.imagem} handleDynamicUpload={handleChangeComponente} index={idx} field={"imagem"}></IdzFileUpload>
                                                                    <span>
                                                                        <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                                        <h6>Carregar foto</h6>
                                                                        <p>Arquivos permitidos: .jpeg, .png</p>
                                                                    </span>
                                                                </div>
                                                            </Col>

                                                            <Col xs={12} sm={12} md={9} lg={idx === 0 ? 9 : 8} className='diferenciais'>
                                                                <Form.Label className='Label'>
                                                                    <Form.Control value={componentes[idx]["chamada"]} onChange={(e) => { handleChangeComponente(e.target.value, idx, "chamada") }} className='Inputs' type="text" placeholder="Chamada da página" />
                                                                </Form.Label>
                                                                <textarea placeholder="Descrição" value={componentes[idx]["descricao"]} onChange={(e) => { handleChangeComponente(e.target.value, idx, "descricao") }} ></textarea>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={1} className='text-center'>
                                                                {idx !== 0 && (
                                                                    <Button className='Btns Remove Full' onClick={() => handleDeleteComponente(indice)}>
                                                                        <Icon icon="ic:baseline-minus"></Icon>
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ))}
                                                {mostrarBotaoAdd && (
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12} className='text-center'>
                                                            <Button className='Btns Add' onClick={handleAddComponente}>
                                                                <Icon icon="ic:baseline-plus" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {/* <Button className='Btns ButtonSecondary' onClick={() => Phases(wrongData.fotos.back)} >Voltar</Button>
                                                <Button className='Btns ButtonPrimaryLight' onClick={() => { handleCheckMandatoryFields("fotos") }}>Continuar</Button> */}
                                                <Button className='Btns ButtonPrimaryLight Salvar' ref={saveButton} onClick={putVeiculo}>
                                                    <ButtonLoadingRing sending={sending} />
                                                    Salvar
                                                </Button>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center nao-cadastrar'>
                                            <Form.Group className="FormNovo">
                                                <Button className='Btns ButtonLight'>Quero cadastrar as fotos depois</Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intUpload &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2><i><Icon icon="fluent:video-32-filled"></Icon></i> Vídeos e links</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        {inputs.map((input, index) => (
                                                            <Form.Label key={index + 1} className='Label'>
                                                                {index == 0 && <span>Vídeos</span>}
                                                                <div className="FormControl">
                                                                    <Form.Control
                                                                        className='Inputs add'
                                                                        type="text"
                                                                        placeholder="Adicione o Link"
                                                                        value={inputs[index]}
                                                                        onChange={(e) => handleInputsDynamic(e.target.value, index, "videos")}
                                                                    />
                                                                    <i><Icon icon="ic:baseline-link"></Icon></i>

                                                                </div>
                                                                {index === 0 && (
                                                                    <Button data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Adcionar mais links')} className='Btns Add' onClick={handleAddInput}><Icon icon="ic:baseline-plus"></Icon></Button>
                                                                )}
                                                                {(index !== 0) && (
                                                                    <Button data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Excluir esse link')} className='Btns Remove' onClick={() => handleRemoveInput(index)}><Icon icon="ic:baseline-minus"></Icon></Button>
                                                                )}
                                                            </Form.Label>
                                                        ))}

                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                        {
                                                            tours.map((tour, index) => (
                                                                <Form.Label className='Label'>
                                                                    {index == 0 && <span>Tour virtual</span>}
                                                                    <div className="FormControl Full">
                                                                        <Form.Control
                                                                            value={tours[index]}
                                                                            onChange={(e) => handleToursDynamic(e.target.value, index, "tour")}
                                                                            className='Inputs add'
                                                                            type="text"
                                                                            placeholder="Adicione o Link"
                                                                        />
                                                                        <i><Icon icon="ic:baseline-link"></Icon></i>
                                                                    </div>
                                                                </Form.Label>
                                                            ))
                                                        }

                                                    </Col>
                                                </Row>

                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {/* <Button className='Btns ButtonSecondary' onClick={() => Phases(wrongData.videos_links.back)} >Voltar</Button> */}
                                                <Button className='Btns ButtonPrimaryLight' ref={saveButton} onClick={putVeiculo}>Salvar</Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {intSeo &&
                                <div className='Novo'>
                                    <Row className='justify-content-center'>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <div className='HeaderNovo'>
                                                <h2><i><Icon icon="icon-park-outline:search"></Icon></i> SEO - Otimização para Buscadores</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8}>
                                            <Form.Group className="FormNovo">
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12}>

                                                        <Form.Label className='Label'>
                                                            <span>URL da Página</span>
                                                            <div className="FormControl Full">
                                                                <Form.Control
                                                                    value={configData["seo"]["url"]}
                                                                    onChange={(e) => setConfigData({
                                                                        ...configData,
                                                                        seo: {
                                                                            ...configData["seo"],
                                                                            url: e.target.value
                                                                        }
                                                                    })}
                                                                    className='Inputs'
                                                                    type="text"
                                                                    placeholder="URL da Página"
                                                                />
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Crie uma título com no máximo 50 caracteres')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>

                                                        <Form.Label className='Label'>
                                                            <span>Título da Página</span>
                                                            <div className="FormControl Full">
                                                                <Form.Control
                                                                    value={configData["seo"]["titulo"]}
                                                                    onChange={(e) => setConfigData({
                                                                        ...configData,
                                                                        seo: {
                                                                            ...configData["seo"],
                                                                            titulo: e.target.value
                                                                        }
                                                                    })}
                                                                    className='Inputs'
                                                                    type="text"
                                                                    placeholder="Título da Página"
                                                                />
                                                                <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Adicione uma título com no máximo 80 caracteres')} icon="akar-icons:light-bulb"></Icon></i>
                                                            </div>
                                                        </Form.Label>

                                                        <Col xs={12} sm={12} md={4} lg={12}>
                                                            <Form.Label className='Label'>
                                                                <span>Palavras-chaves</span>
                                                                <div className="FormControl Full">
                                                                    <div className='listagem'>
                                                                        {tags.map((tag, index) => (
                                                                            <div key={index} className="tag">
                                                                                {tag}
                                                                                <i onClick={() => handleRemoveTag(index)}>X</i>
                                                                            </div>
                                                                        ))}
                                                                        {tags.length < 4 && (
                                                                            <Form.Control
                                                                                value={inputValue}
                                                                                onChange={(e) => setInputValue(e.target.value)}
                                                                                onKeyDown={handleKeyDown}
                                                                                placeholder="Palavras-chaves"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Escreva as palavras-chaves e dê um enter para incluí-los')} icon="akar-icons:light-bulb"></Icon></i>
                                                                </div>
                                                            </Form.Label>
                                                        </Col>

                                                        <Col xs={12} sm={12} md={4} lg={12}>
                                                            <Form.Label className='Label'>
                                                                <span>Descrição</span>
                                                                <div className="FormControl Full">
                                                                    <textarea value={configData["seo"]["descricao"]}
                                                                        onChange={(e) => setConfigData({
                                                                            ...configData,
                                                                            seo: {
                                                                                ...configData["seo"],
                                                                                descricao: e.target.value
                                                                            }
                                                                        })} placeholder="Insira sua descrição"></textarea>
                                                                    <i><Icon data-tooltip-id="my-tooltip" onMouseEnter={() => handleMouseEnter('Adicione uma descrição com no máximo 180 caracteres')} icon="akar-icons:light-bulb"></Icon></i>
                                                                </div>
                                                            </Form.Label>
                                                        </Col>

                                                    </Col>
                                                </Row>

                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} sm={12} md={8} lg={8} className='text-center'>
                                            <Form.Group className="FormNovo">
                                                {/* <Button className='Btns ButtonSecondary' onClick={() => Phases(wrongData.videos_links.back)} >Voltar</Button> */}
                                                <Button className='Btns ButtonPrimaryLight' ref={saveButton} onClick={putVeiculo}>Salvar</Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
                <ReactTooltip id="my-tooltip" place="bottom" content={tooltipContent} />
            </div>

            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <NewModelo show={show} handleAlert={handleAlert} />
            <NewVidros showVidros={showVidros} handleAlert={handleAlert} />
            <NewBlindagens showBlindagens={showBlindagens} handleAlert={handleAlert} />
        </>
    );
}
export default Veiculos;