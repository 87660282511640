import React, { useState, Component, useEffect, useRef } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import { useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"
import IdzFileUpload from "../../../components/Veiculos/idzFileUpload"
import ModalVariacoes from '../../../components/Estetica/Variacoes';
import ModalRegraPreco from '../../../components/Estetica/RegrasPreco';


//styles
import './ServicosNovo.scss';
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';
import ButtonLoadingRing from '../../../components/ButtonLoading';
import Alerts from '../../../components/Alerts';

function ServicosNovo() {

    const { token } = useAuth()

    const navigate = useNavigate()

    const [showProduto, setshowProduto] = useState(false);
    const [showRegraPreco, setshowRegraPreco] = useState(false);

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])



    const [isCriativo, setIsCriativo] = useState(false)
    const toggleCheckIsCriativo = () => {
        setIsCriativo(!isCriativo)
    }


    const [categorias, setCategorias] = useState([]);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    const [nome, setNome] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [video, setVideo] = useState('');
    const [imagem, setImagem] = useState(null);

    useEffect(() => {
        const getCategoria = async () => {
            try {
                const res = await api.get(`/api/categoria-estetica`);
                if (res.status === 200) {
                    const categoriasFormatadas = res.data.map(categoria => ({
                        label: categoria.titulo,
                        value: categoria.id
                    }));
                    setCategorias(categoriasFormatadas);
                }
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };

        getCategoria();
    }, []);

    const handleDropdownChange = (e) => {
        setCategoriaSelecionada(e.value);
    };

    const handleNomeChange = (e) => {
        setNome(e.target.value);
    };

    const handleSubtituloChange = (e) => {
        setSubtitulo(e.target.value);
    };
    

    const handleDescricaoChange = (e) => {
        setDescricao(e.target.value);
    };

    const handleVideoChange = (e) => {
        setVideo(e.target.value);
    };

    const handleImagemUpload = (file) => {
        setImagem(file);
    };

    const postData = async () => {
        console.log("Enviando dados");

        const data = {
            titulo: nome,
            chamada: subtitulo,
            descricao,
            categoria: categoriaSelecionada,
            criativo: {
                video,
                imagem: imagem ? imagem.name : ''
            }
        };

        try {
            const res = await api.post(`/api/servico-estetica`, data, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });

            if (typeof res.data === 'string') {
                handleAlert("Campos inválidos", res.data, "danger");
            } else if (res.status === 200) {
                handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success");
                // Resetar campos após o sucesso
                setNome('');
                setDescricao('');
                setCategoriaSelecionada(null);
                setVideo('');
                setImagem(null);
            }
        } catch (error) {
            console.error('Erro ao enviar dados:', error);
            handleAlert("Erro", "Ocorreu um erro ao cadastrar os dados.", "danger");
        }
    };


    const showModalProduto = () => {
        setshowProduto(true);
    };
    const closeModalProduto = () => {
        setshowProduto(false);
    };

    const showModalRegraPreco = () => {
        setshowRegraPreco(true);
    };
    const closeModalRegraPreco = () => {
        setshowRegraPreco(false);
    };

    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar />
            <div className="Content EsteticaNovo">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderEstetica'>
                                <i><Icon icon="mdi:stars" ></Icon></i>
                                <h2>Estética<br />
                                    <span>Crie um novo serviço para o seu site.</span></h2>
                                <Alert />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NovoEstetica'>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>Adicionar novo </h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>Categoria</Form.Label>
                                                <Dropdown
                                                    value={categoriaSelecionada}
                                                    options={categorias}
                                                    onChange={handleDropdownChange}
                                                    optionLabel="label"
                                                    placeholder="Selecione a categoria"
                                                    className='Dropdown'
                                                    filter
                                                    emptyMessage="Sem resultado"
                                                />
                                            </Col>
                                            <Col xs={4} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>Nome</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control 
                                                        className='Inputs' 
                                                        type="text" 
                                                        placeholder="Digite o nome" 
                                                        value={nome} 
                                                        onChange={handleNomeChange} 
                                                    />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={4} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>Chamada</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control 
                                                        className='Inputs' 
                                                        type="text" 
                                                        placeholder="Digite uma chamada" 
                                                        value={subtitulo} 
                                                        onChange={handleSubtituloChange} 
                                                    />
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={12}>
                                                <Form.Label className='Label'>Descrição</Form.Label>
                                                <textarea 
                                                    placeholder="Descrição do Serviço" 
                                                    value={descricao} 
                                                    onChange={handleDescricaoChange} 
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='mt-4'>
                                        <h3>Criativos</h3>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={12} className="checkbox mb-2">
                                        <Form.Check
                                            className='Label'
                                            type="checkbox"
                                            label="Video"
                                        />
                                        <Form.Check
                                            className='Label'
                                            type="checkbox"
                                            label="Foto"
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={12}>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Form.Label className='Label'>
                                                <div class="FormControl">
                                                    <Form.Control 
                                                        className='Inputs' 
                                                        type="text" 
                                                        placeholder="Link de redirecionamento" 
                                                        value={video}
                                                        onChange={handleVideoChange} 
                                                    />
                                                    <i><Icon icon="ic:baseline-link"></Icon></i>
                                                </div>
                                            </Form.Label>
                                        </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={12}>
                                        <Row className='mt-4 mb-4'>
                                            <Col xs={12} sm={12} md={12} lg={4}>
                                                <Form.Label className='Label'>Imagem</Form.Label>
                                                <div class="ImagesUploader">
                                                    <IdzFileUpload title='Upload de foto' currentFile={imagem} handleUpload={handleImagemUpload} />
                                                    <span>
                                                        <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                        <h6>Upload de Foto</h6>
                                                        <p>Arquivos permitidos: .jpeg, .png</p>
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4>Variações</h4>
                                                    <i><Icon icon='ri:add-fill' /></i>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='mt-4 mb-4'>
                                                    <Col xs={12} sm={12} md={6} lg={12}>
                                                        <div className='cadastroItem'>
                                                            <div className='item' >
                                                                <div className='infos'>
                                                                    <i><Icon icon='mdi:crown' /></i>
                                                                    <h3>PPF Fosco</h3>
                                                                    <p>10 de anos garantia</p> 
                                                                </div>
                                                                <div className='Acoes'>
                                                                    <button className='Btns ButtonIcon'><Icon icon="iconamoon:close-duotone"></Icon></button>
                                                                    <button className='Btns ButtonIcon'><Icon icon="ri:edit-fill"></Icon></button>
                                                                </div>
                                                            </div>
                                                            <div className='addVariacao'>
                                                                <Button className='Btns Add' onClick={() => { showModalProduto() }}><Icon icon="ic:baseline-plus"></Icon></Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4>Regras de preço</h4>
                                                    <i><Icon icon='ri:add-fill' /></i>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='mt-4 mb-4'>
                                                    <Col xs={12} sm={12} md={6} lg={12}>
                                                        <div className='cadastroItem'>
                                                            <div className='item' >
                                                                <div className='infos'>
                                                                    <i><Icon icon='mdi:crown' /></i>
                                                                    <span>PPF Fosco</span>
                                                                    <h3>Veículos Pequenos e Médios</h3>
                                                                    <h4>R$ 10,00</h4>
                                                                </div>
                                                                <div className='Acoes'>
                                                                    <button className='Btns ButtonIcon'><Icon icon="iconamoon:close-duotone"></Icon></button>
                                                                    <button className='Btns ButtonIcon'><Icon icon="ri:edit-fill"></Icon></button>
                                                                </div>
                                                            </div>
                                                            <div className='addVariacao'>
                                                                <Button className='Btns Add' onClick={() => { showModalRegraPreco() }}><Icon icon="ic:baseline-plus"></Icon></Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row> */}
                                <Row  className='mt-4'>
                                    <Col xs={12} sm={12} md={12} lg={12} className='text-right'>
                                        <Form.Group className="FormNovo">
                                            <Button className='Btns ButtonSecondary'>Voltar</Button>
                                            <Button onClick={postData} className='Btns ButtonPrimaryLight' style={{ position: 'relative' }}>
                                                <ButtonLoadingRing />
                                                Salvar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ReactTooltip id="my-tooltip-diferenciais" place="bottom" content="Adicionar mais Diferenciais" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
            <ModalVariacoes show={showProduto} showModalProduto={showModalProduto} closeModalProduto={closeModalProduto} ProdutoAlertas={handleAlert} />
            <ModalRegraPreco show={showRegraPreco} showModalRegraPreco={showModalRegraPreco} closeModalRegraPreco={closeModalRegraPreco} RegraPrecoAlertas={handleAlert} />
        </>
    );
}
export default ServicosNovo;