import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import "./EspecificacaoEdit.scss";

//Componentes
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';

function EspecificacaoEdit({ show, idEdit, EspecificacaoAlertas, editData }) {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isEspecificacao, setIsEspecificacao] = useState(true);
    const [popupData, setPopupData] = useState({
        categoria: '',
        titulo: '',
        sku: '',
        status: 1
    });

    useEffect(() => {
        if (show) {
            setShowModal(true);
            if (editData) {
                setPopupData(editData);

            } else {
                // Se não há dados de edição, fazemos a requisição GET para obter os dados da especificação
                fetchEspecificacao();
            }
        }
    }, [show]);

    const handleClose = () => {
        setShowModal(false);
    };

    const EspecificacaoToggle = () => {
        setIsEspecificacao(!isEspecificacao);
        setPopupData({
            ...popupData,
            status: !isEspecificacao ? "Ativo" : "Inativo"
        })
    };

    const handlePopupData = (value, field) => {
        console.log("popupData", popupData)
        setPopupData({
            ...popupData,
            [field]: field === 'status' ? (value === 'ativo' ? '1' : '0') : value
        });
    };
    const fetchEspecificacao = async () => {
        try {
            const response = await api.get(`/api/especificacoes-blindagem/${idEdit}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });
            console.log(response.data.sku)
            const { categoria_id, sku, titulo, status } = response.data
            const data = {
                categoria:categoria_id,
                sku,
                titulo,
                status,
            };
            data["status"] = data["status"] == 1 ? "Ativo" : "Inativo"
            setPopupData(data);
            setIsEspecificacao(data.status == 1 || data.status == "Ativo" ? true : false)
        } catch (error) {
            console.error("Erro ao buscar dados da especificação:", error);
        }
    };

    const putEspecificacao = async () => {
        console.log("popupData: ", popupData)
        try {
            const response = await api.put(`/api/especificacoes-blindagem/${idEdit}`, popupData, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });
            if (response.status === 200) {
                EspecificacaoAlertas("Sucesso", "Os dados foram editados com êxito.", "success");
                setShowModal(false);
            } else {
                EspecificacaoAlertas("Erro", "Falha ao editar os dados.", "danger");
            }
        } catch (error) {
            console.error("Erro ao editar a especificação:", error);
            EspecificacaoAlertas("Erro", "Falha ao editar os dados.", "danger");
        }
    };

    useEffect(() => {
        console.log("[POPUP DAT]:", popupData)
    }, [popupData])

    return (
        <>
            <Modal show={showModal} className="ModalEspecificacao">
                <Modal.Header>
                    <Modal.Title>EDITAR ESPECIFICAÇÃO</Modal.Title>
                    <Icon icon="ri:edit-fill" />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={12}>
                                <Form.Label className='Label'>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control className='Inputs' value={popupData["titulo"]} onChange={(e) => { handlePopupData(e.target.value, "titulo") }} type="text" placeholder="Título" />
                                </Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={12}>
                                <Form.Label className='Label'>
                                    <Form.Label>SKU</Form.Label>
                                    <Form.Control className='Inputs' value={popupData["sku"]} onChange={(e) => { handlePopupData(e.target.value, "sku") }} type="text" placeholder="SKU" />
                                </Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Check
                                        className='Label'
                                        type="switch"
                                        label={isEspecificacao ? "Ativo" : "Inativo"}
                                        name="status"
                                        checked={isEspecificacao}
                                        onChange={(e) => {
                                            EspecificacaoToggle();
                                            //handlePopupData(e.target.checked ? 'ativo' : 'inativo', "status")
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="text-center">
                            <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={putEspecificacao}>
                                Salvar Alterações
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EspecificacaoEdit;
