import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';


// CSS
import "./NewModelo.scss";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

function NewModelo({ show, handleAlert }) {

    const { token } = useAuth()
    const [showModal, setShowModal] = useState(false);
    const [marcas, setMarcas] = useState([])

    const getMarcas = async () => {
        await api.get(`/api/marcas`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                console.log("[MARCAS]:", r.data)
                let lista = [

                ]
                for (let marca of r.data) {
                    if (marca.status == "Ativo") {
                        lista.push({ label: marca.titulo, value: marca.id })
                    }
                }
                setMarcas(lista)
            }
        })
    }

    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        getMarcas()
        getCategorias()
    }, [])

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    const [popupData, setPopupData] = useState({
        categoria: '',
        marca_id: '',
        modelo:  '',
        versao:  '',
        fabricacao:  '',
        ultimo:  ''
    })


    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }
    

    const postModelo = async () => {
        const dataToSend = {
            ...popupData
        };
       await api.post(`/api/modelos`, dataToSend, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    handleClose()
                }
            }
        })
    }

    const [categorias, setCategorias] = useState([])

    const getCategorias = async () => {
        await api.get(`/api/modelos/categoria`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(r => {
            if (r.data) {
                console.log("[MARCAS]:", r.data)
                let lista = [

                ]
                for (let marca of r.data) {
                    if (marca.status == "Ativo") {
                        lista.push({ label: marca.titulo, value: marca.id })
                    }
                }
                setCategorias(lista)
            }
        })
    }
    


    return (
        <>

            <Modal show={showModal} className="NewModelo">
                <Modal.Header >
                    <Modal.Title>Cadastro de Modelos</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className='Label'>
                                <span>Categoria</span>
                                <Dropdown filter value={popupData["categoria"]} onChange={(e) => { handlePopupData(e.target.value, "categoria") }} options={categorias} optionLabel="label" placeholder="Categorias" className='Dropdown' />
                            </Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Marca</Form.Label>
                            <Dropdown filter value={popupData["marca_id"]} onChange={(e) => { handlePopupData(e.target.value, "marca_id") }} options={marcas} optionLabel="label" placeholder="Marca" className='Dropdown' />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nome do Modelo</Form.Label>
                            <Form.Control value={popupData["modelo"]} onChange={(e) => { handlePopupData(e.target.value, "modelo") }} type="text" placeholder="Informe o modelo" ></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nome da Versão</Form.Label>
                            <Form.Control value={popupData["versao"]} onChange={(e) => { handlePopupData(e.target.value, "versao") }} type="text" placeholder="Informe a versão" ></Form.Control>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Primeiro ano de fabricação</Form.Label>
                                    <Form.Control value={popupData["fabricacao"]} onChange={(e) => { handlePopupData(e.target.value, "fabricacao") }} type="text" placeholder="Informe o ano" ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Último ano de fabricação</Form.Label>
                                    <Form.Control value={popupData["ultimo"]} onChange={(e) => { handlePopupData(e.target.value, "ultimo") }} type="text" placeholder="Informe o ano" ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="text-center">
                            <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={postModelo}>
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
            
        </>
    );
};

export default NewModelo;
