import React, { useState } from 'react';
import { Col, Row , Container} from "react-bootstrap";
import './newPassword.scss';
import Home from '../../pages/Home';
import { Icon } from '@iconify/react';

import { useParams } from "react-router-dom";
import api from "../../services/apiIdz";
import Alerts from '../../components/Alerts';
import { useNavigate } from "react-router-dom";
import ShowPassword from '../ShowPassword';

import { useAuth } from "../../context/useAuth";

//Imagens 
import logo from '../../pages/Login/img/logo-login.svg'

function NewPassword() {

  const navigate = useNavigate()

  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setPassword] = useState('');
  const { token } = useParams()
  const { tokenAuth } = useAuth()

  const [isVisible, setIsVisible] = useState(false);
  const [isTitle, setTitle] = useState('');
  const [initDescription, setDescription] = useState('');
  const [initType, setType] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handleNewPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isNewPassword = false;
    const passwordInput = document.getElementById('password');
    const confirmPasswordInput = document.getElementById('confirmPassword');
    const iconLock = document.getElementById('lock');
    const iconConfirmLock = document.getElementById('lock-confirm');

    passwordInput.classList.remove('passwordWrong');
    confirmPasswordInput.classList.remove('passwordWrong');
    iconLock.classList.remove('iconWrong');
    iconConfirmLock.classList.remove('iconWrong');


    if (newPassword.trim() === '') {
      passwordInput.classList.add('passwordWrong');
      iconLock.classList.add('iconWrong');
      return;
    }
    if (confirmPassword.trim() === '') {
      confirmPasswordInput.classList.add('passwordWrong');
      iconConfirmLock.classList.add('iconWrong');
      return;
    }

    if (newPassword === confirmPassword) {
      isNewPassword = true;
    } else {
      confirmPasswordInput.classList.add('passwordWrong');
      iconConfirmLock.classList.add('iconWrong');
    }

    if (isNewPassword) {
      postNewPassword();
    }


  }

  const postNewPassword = async () => {
    const sendNewPassword = {
        senha: newPassword,
    };
    try {
      await api.put(`/api/resetar-senha/${token}`, sendNewPassword ,  {
        headers: {
          'Authorization': process.env.REACT_APP_API_KEY,
          'Token': tokenAuth
        }
      }).then((res) => {
        setIsVisible(true);
        setType('success');
        setTitle('Cadastrado com sucesso');
        setDescription('Senha alterada com sucesso');
        setTimeout(() => {
          navigate('/login')
        }, 2000);
      })
    } catch (err) {
      setIsVisible(true);
      setType('danger');
      setTitle('Token inválido');
      setDescription('Não foi possível alterar a senha');
      console.log(err);
    }
  }

  const togglePasswordVisibility = (input) => {
    if (input === 'new') {
      setShowNewPassword(!showNewPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className='page-login'>
      {isVisible &&
        <Alerts title={isTitle} description={initDescription} type={initType} />
      }
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}  id='logo'>

          </Col>
          <Col xs={12} sm={12} md={12} lg={6}  id='forms'>
            <div className='form'>
              <div className='title'>
                <i><img src={logo} /></i>
                <h4> Cadastre sua nova senha </h4>
                <p>Digite abaixo a sua nova senha e confirme</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <span className='icon' id="lock">
                    <Icon id="lock " icon="bx:lock" />
                  </span>
                  <input
                    className='newPassword'
                    type={showNewPassword ? 'text' : 'password'}
                    id="password"
                    placeholder='Nova senha'
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                  />
                  <ShowPassword
                    isVisible={showNewPassword}
                    toggleVisibility={() => togglePasswordVisibility('new')}
                  />
                </div>
                <div>
                  <span className='icon' id="lock-confirm">
                    <Icon id="lock " icon="bx:lock" />
                  </span>
                  <input
                    type={showConfirmPassword ? 'text' : 'senha'}
                    id="confirmPassword"
                    placeholder='Confirmar senha'
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <ShowPassword
                    isVisible={showConfirmPassword}
                    toggleVisibility={() => togglePasswordVisibility('confirm')}
                  />
                </div>
                <button className='savePassword' type="submit">Cadastrar Senha</button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NewPassword;
