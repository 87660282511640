import { createContext, useContext, useEffect, useState } from 'react';
import jwt from 'jwt-decode'
import api from '../services/apiIdz';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useCookies } from 'react-cookie';


export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [cookie, setCookie, removeCookie] = useCookies(['Token'])

    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})
    const [token, setToken] = useState()
    const [avatar, setAvatar] = useState()

    const logout = () => {
        try {
            window.localStorage.removeItem("auth_data")
            window.location.href = window.location.origin + "/login"
        } catch (err) {
            throw err
        }
    }

    function isAdmin() {
        return user?.role === "admin" || user?.role === "editor chefe"
    }
    function isNotRedator() {
        return user?.role !== "redator"
    }
    function isRedator() {
        return user?.role === "redator"
    }

    function getInitials(completeName) {
        if (typeof completeName != 'undefined') { 
            const palavras = completeName.trim().split(' ');
            let initials = '';
            if (palavras.length > 1) {


                for (let i = 0; i < Math.min(2, palavras.length); i++) {
                    const palavra = palavras[i];
                    initials += palavra.charAt(0);
                }

                return initials.toUpperCase();
            } else {
                initials = `${completeName[0]}${completeName[1]}`
                return initials.toUpperCase();
            }

        }


    }

    const handleAvatar = () => {
        setAvatar(getInitials(user.nome))
    }

    function checkLogin() {
        if (window.localStorage.getItem("auth_data") != null) {
            try {
                const localData = JSON.parse(window.localStorage.getItem("auth_data"))

                if (localData.token) {

                    setToken(localData.token)
                    setIsLogged(true)
                    setLoading(false)

                    console.log({
                        "id": localData.id,
                        "nome": localData.nome,
                        "email": localData.email,
                        "tipo_usuario": localData.tipo_usuario,
                        "status": localData.status
                    })

                    setUser({
                        "id": localData.id,
                        "nome": localData.nome,
                        "email": localData.email,
                        "tipo_usuario": localData.tipo_usuario,
                        "status": localData.status
                    })
                    setAvatar(getInitials(localData.nome))

                    if (location.pathname.toLowerCase().includes("/login")) {
                        navigate("/dashboard")
                    }

                    return true
                } else {

                    setIsLogged(false)
                    setLoading(false)
                    navigate("/login")

                    return false

                }
            } catch (err) {
                throw err
            }
        }
    }


    useEffect(() => {
        if (user) {
            handleAvatar()
        }
    }, [user])

    useEffect(() => {
        if (loading) {
            checkLogin()
        }
    }, [loading])



    const handleUser = (userData) => {
        setUser(userData)
    }

    const updateUser = (value, field) => {
        setUser(prevUser => ({
            ...prevUser,
            [field]: value
        }))

    }


    const handleLogin = async (email, password) => {
        try {
            await api.post("/api/login", {
                "email": email,
                "password": password
            }, {
                withCredentials: true
            }).then((res) => {
                if (res.status == 200) {
                    let token = res.data
                    console.log(token)

                    // localStorage.setItem("bluwi__auth", token)
                    // setCookie("Token", token, {})



                    //setToken(token)
                    //handleUser(jwt(token))
                    //navigate("/dashboard")
                }
            })
        } catch (err) {
            throw err
        }
    }

    return <AuthContext.Provider value={{ isLogged, handleLogin, user, handleUser, checkLogin, logout, loading, token, isAdmin, avatar, getInitials, isNotRedator, isRedator, updateUser }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}



