import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

import api from '../../services/apiIdz';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/Veiculos/Searce';
import Filter from '../../components/Veiculos/Filter';
import VeiculosEditModal from '../../components/Veiculos/Edit';
import VeiculosRelatedModal from '../../components/Veiculos/Related';
import ModalDelete from '../../components/Veiculos/ModalDelete';

//styles
import './Veiculos.scss';
import axios from 'axios';
import Alerts from '../../components/Alerts';
import { useAuth } from '../../context/useAuth';

//Imagens 
import logo from '../Dashboard/img/logo-idzcar.png'

function Veiculos() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [show, setShowModal] = useState(false);
    const [showRelated, setShowModalRelated] = useState(false);

    const [veicles, setVeicles] = useState()
    const [marcas, setMarcas] = useState([])
    const [modelos, setModelos] = useState([])

    const [currentPrice, setCurrentPrice] = useState(0)

    const [selectedId, setSelectedId] = useState(null)

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    async function getVeiculos() {
        setReadyFilter(false)
        await api.get(`/api/veiculos`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(async (res) => {
            if (res.data) {
                console.log(res.data)
                handleVeicleList(res.data)
            }
        })
    }
    async function getVeiculosAtivos() {
        setReadyFilter(false)
        await api.get(`/api/veiculos?status=ativo`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(async (res) => {
            if (res.data) {
                console.log("veiculos ativos:", res.data)
                handleVeicleList(res.data)
            }
        })
    }
    async function getVeiculosInativos() {
        setReadyFilter(false)
        await api.get(`/api/veiculos?status=inativo`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(async (res) => {
            if (res.data) {
                console.log("veiculos inativos", res.data)
                handleVeicleList(res.data)
            }
        })
    }

    const { token } = useAuth()

    const putPriceVeiculo = async (price_local, id, close) => {

        let configData = {
            preco: price_local
        }

        await api.put(`/api/veiculos/${id}`, configData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    close()
                    getVeiculos()
                }
            }

        })
    }

    const putRelatedVeiculo = async (related, id, close) => {
        let configData = {
            veiculos: []
        }
        related.forEach((veicle) => {
            configData.veiculos.push(veicle.value)
        })

        console.log(configData)

        if (configData.veiculos.length > 0) {
            await api.put(`/api/associar-veiculos/${id}`, configData, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            }).then(r => {
                console.log(r)

                if (typeof r.data == 'string') {
                    handleAlert("Campos inválidos", r.data, "danger")
                } else {
                    if (r.status == 200) {
                        handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                        close()
                    }
                }

            })
        } else {
            handleAlert("Relação insuficiente", "Nenhum veículo para associar.", "warning")
        }


    }


    const navigate = useNavigate();

    const showModalEdit = () => {
        setShowModal(!show);


    };

    const showModalRelated = () => {
        setShowModalRelated(!showRelated);


    };

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filteredArticles, setFilteredArticles] = useState(null);
    const [searchName, setSearchName] = useState('');

    const handleFilterChange = (filter, nameFilter) => {
        setFilteredArticles(filter);
        setSearchName(nameFilter);
    };

    const [readyFilter, setReadyFilter] = useState(false)

    const [filtered, setFiltered] = useState([])
    const handleFiltered = (rawData) => {

        console.log(rawData)

        let tmpCarList = rawData.sort((a, b) => {
            if (a.status === 'Vendido' && b.status !== 'Vendido') {
              return 1; // b vem antes de a
            }
            if (a.status !== 'Vendido' && b.status === 'Vendido') {
              return -1; // a vem antes de b
            }
            return 0; // mantém a ordem original se ambos têm o mesmo status
          }).filter(car_d => car_d.modelo?.toLowerCase().includes(search) && car_d.status.toLowerCase().includes(status))

        setFiltered(tmpCarList)
    }

    const handleVeicleList = (rawData) => {
        setVeicles(rawData);
        setReadyFilter(true)
    }


    useEffect(() => {
        if (readyFilter) {
            console.log(veicles)
            console.log(search, status.name)
            handleFiltered(veicles)
        }
    }, [search, status, readyFilter])



    useEffect(() => {
        console.log(`[${status}]`)
        // if (status == 'ativo') {
        //     getVeiculosAtivos()
        // } else if (status == 'inativo') {
        //     getVeiculosInativos()
        // } else {
        getVeiculos()
        //}
    }, [status])


    const [showDelete, setShowDelete] = useState(false);


    const [deleteId, setDeleteId] = useState(null)
    const handleShowDeleteModal = (id) => {
        setDeleteId(id)
        setShowDelete(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteId(null)
        setShowDelete(false);
    };

    const handleCloseDeleteModalSuccess = () => {
        setDeleteId(null)
        setShowDelete(false);
    }

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };


    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Veiculos">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="fa-solid:car" ></Icon></i>
                                <h2>Veículos <br />
                                    <span>Gerencie informações e detalhes dos veículos cadastrados</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableVeiculos'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsVeiculos'>
                                        <Filter handleStatus={handleStatus} onFilterChange={handleFilterChange} />
                                        <Button className='Btns New' onClick={() => navigate("/veiculos/novo")}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listVeiculos'>
                                        <Row>
                                            {
                                                filtered?.map((car, index) => {
                                                    return (
                                                        <Col xs={6} sm={12} md={3} lg={3} key={index}>
                                                            <div className='Veiculo'>
                                                                <div className='imagem'>
                                                                    {car.status === "Ativo" ? (
                                                                        <span><i></i> Publicado</span>
                                                                    ) : car.status === "Inativo" ? (
                                                                        <span className="danger"><i></i> Inativo</span>
                                                                    ) : (
                                                                        <span className="sold"><i></i> Vendido</span>
                                                                    )}
                                                                    <img className='thumbnail' src={"https://api.idzcar.com.br/" + car.imagem}
                                                                    />
                                                                </div>
                                                                <h2>{car.modelo}</h2>
                                                                <h6>{car.ano_modelo} | {car.cor} | KM {car.km}</h6>
                                                                <hr />
                                                                <h3><span>Valor</span> {car.preco?.toUpperCase()}</h3>
                                                                {car.leads > 0 ?
                                                                    <Link to={`/leads?veiculo=${car.id}`}>
                                                                        <h4>
                                                                            <i><Icon icon="basil:chat-solid"></Icon></i> {car.leads} leads 
                                                                        </h4>
                                                                    </Link>
                                                                 : null }

                                                                <div className='acoes'>
                                                                    <button className='Btns ButtonPrimary' onClick={() => { navigate(`/veiculos/editar/${car.id}`) }}>Editar</button>
                                                                    <button data-tooltip-id="my-tooltip-delete" className='Btns ButtonIcon' onClick={() => handleShowDeleteModal(car.id)}><i><Icon icon="mdi:trash"></Icon></i></button>
                                                                    <button onClick={() => {
                                                                        console.log("car id", car.id)
                                                                        console.log("car price", car.preco)
                                                                        showModalEdit()
                                                                        setSelectedId(car.id)
                                                                        setCurrentPrice(car.preco)
                                                                    }} className='Btns ButtonIcon' data-tooltip-id="my-tooltip-editar-preco"><i><Icon icon="ri:edit-fill"></Icon></i></button>
                                                                    <button onClick={() => {
                                                                        showModalRelated()
                                                                        setSelectedId(car.id)

                                                                    }} className='Btns ButtonIcon' data-tooltip-id="my-tooltip-relacionados"><i><Icon icon="ph:link-bold"></Icon></i></button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
                <VeiculosEditModal show={show} id={selectedId} price={currentPrice} putPriceVeiculo={putPriceVeiculo} />
                <VeiculosRelatedModal showRelated={showRelated} id={selectedId} veicles={veicles} putPriceRelated={putRelatedVeiculo} />
                <ModalDelete deleteId={deleteId} getVeiculos={getVeiculos} show={showDelete} handleClose={handleCloseDeleteModal} handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess} handleAlert={handleAlert} />

                <ReactTooltip id="my-tooltip-delete" place="bottom" content="Deletar" style={{ backgroundColor: "#FFFFFF", color: "#000000" }}></ReactTooltip>
                <ReactTooltip id="my-tooltip-editar-preco" place="bottom" content="Editar Preço" style={{ backgroundColor: "#FFFFFF", color: "#000000" }}></ReactTooltip>
                <ReactTooltip id="my-tooltip-relacionados" place="bottom" content="Veiculos Relacionados" style={{ backgroundColor: "#FFFFFF", color: "#000000" }}></ReactTooltip>
            </div>
        </>
    );
}
export default Veiculos;