import React, { useState, Component, useEffect, useRef } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import { useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"
import IdzFileUpload from "../../../components/Veiculos/idzFileUpload"

//utils
import { formatDateHour } from '../../../utils/dates';

//styles
import './PaginasNovo.scss';
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';
import ButtonLoadingRing from '../../../components/ButtonLoading';
import Alerts from '../../../components/Alerts';

function PaginasNovo() {

    const navigate = useNavigate()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])


    const { token, user } = useAuth()

    const buttonRef = useRef()

    const [pagina, setPagina] = useState({
        data_publicacao:formatDateHour(new Date()),
        banner: '',
        corpo: '',
    })

    useEffect(() => {
        console.log(pagina)
    }, [pagina])

    const handlePagina = (value, field) => {
        setPagina({
            ...pagina,
            autor: "",
            status: "Ativo",
            [field]: value
        })
    }

    const handlePagina2nd = (value, field1, field2) => {
        setPagina({
            ...pagina,
            [field1]: {
                ...pagina[field1],
                [field2]: value
            }
        })
    }

    useEffect(() => {
        if (user) {
            console.log("[USER DATA]", user)
            handlePagina(user.nome, "autor")
        }
    }, [user])

    const handleImagemBanner = (image) => {
        handlePagina(image, "banner")
    }
    const handleImagemCorpo = (image) => {
        handlePagina(image, "corpo")
    }

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (tags) {
            handlePagina(tags, "palavras_chave")
        }
    }, [tags])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Evita que o formulário seja enviado ao pressionar Enter
            const newTag = inputValue.trim();
            if (newTag && tags.length < 4) { // Verifica se há menos de 4 tags
                setTags([...tags, newTag]);
                setInputValue('');
            }
        }
    };

    const handleRemoveTag = (indexToRemove) => {
        setTags(tags.filter((tag, index) => index !== indexToRemove));
    };

    const [sending, setSending] = useState(false)
    const postData = async () => {
        try {
            setSending(true)
            const auxData = { ...pagina }
            auxData['palavras_chave'] = auxData['palavras_chave'].length > 0 ? auxData['palavras_chave'].join(",") : ''
            console.log(auxData)

            await api.post(`/api/conteudo-institucional`,
                auxData, {
                    headers: {
                        'Authorization': process.env.REACT_APP_API_KEY,
                        "Token": token
                    }
                }).then(r => {
                    console.log(r)

                    if (typeof r.data == 'string') {
                        handleAlert("Campos inválidos", r.data, "danger")
                        setSending(false)
                    } else {
                        if (r.status == 200) {
                            handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")

                            setTimeout(() => {
                                navigate("/paginas")
                            }, 4000)
                        }
                    }
                })
        } catch (err) {
            if (err) {
                handleAlert("Erro", "Ocorreu um erro inesperado.", "danger")
                setSending(false)
            }
        }

        
    }


    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar />
            <div className="Content PaginasNovo">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderPaginas'>
                                <i><Icon icon="icon-park-outline:config" ></Icon></i>
                                <h2>Páginas<br />
                                    <span>Crie uma nova página para o seu site.</span></h2>
                                <Alert />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NovoPaginas'>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>NOVA PÁGINA</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={12}>
                                                <Form.Label className='Label'>Titulo da Página</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control className='Inputs' type="text" placeholder="Digite seu título" value={pagina["titulo"]} onChange={(e) => { handlePagina(e.target.value, "titulo") }} />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={12}>
                                                <Form.Label className='Label'>Subtitulo</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control className='Inputs' type="text" placeholder="Digite o chamada" value={pagina["chamada"]} onChange={(e) => { handlePagina(e.target.value, "chamada") }} />
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={12}>
                                                <Form.Label className='Label'>Texto da Página</Form.Label>
                                                <textarea value={pagina["conteudo"]} onChange={(e) => { handlePagina(e.target.value, "conteudo") }}></textarea>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='mt-4'>
                                        <h3>Criativos</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={4}>
                                        <Form.Label className='Label'>Imagem do banner</Form.Label>
                                        <div class="ImagesUploader">
                                            <IdzFileUpload title='Upload de foto' currentFile={pagina["banner"]} handleUpload={handleImagemBanner} />
                                            <span>
                                                <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                <h6>Upload de Foto</h6>
                                                <p>Arquivos permitidos: .jpeg, .png</p>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={4}>
                                        <Form.Label className='Label'>Imagem do corpo da página</Form.Label>
                                        <div class="ImagesUploader">
                                            <IdzFileUpload title='Upload de foto' currentFile={pagina["corpo"]} handleUpload={handleImagemCorpo} />
                                            <span>
                                                <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                <h6>Upload de Foto</h6>
                                                <p>Arquivos permitidos: .jpeg, .png</p>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-4 mb-4'>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4>SEO</h4>
                                                    <i><Icon icon='ri:add-fill' /></i>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='mt-4 mb-4'>
                                                        <Col xs={12} sm={12} md={4} lg={4}>
                                                            <Form.Label className='Label'>Título</Form.Label>
                                                            <Form.Label className='Label'>
                                                                <Form.Control className='Inputs' type="text" placeholder="Digite o título" value={pagina["seo_titulo"]} onChange={(e) => { handlePagina(e.target.value, "seo_titulo") }} />
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={4} lg={4}>
                                                            <Form.Label className='Label'>URL</Form.Label>
                                                            <Form.Label className='Label'>
                                                                <Form.Control className='Inputs' type="text" placeholder="URL amigável" value={pagina["url"]} onChange={(e) => { handlePagina(e.target.value, "url") }} />
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={4} lg={12}>
                                                            <Form.Label className='Label'>Palavras-chaves</Form.Label>
                                                            <div className='listagem'>
                                                                {tags.map((tag, index) => (
                                                                    <div key={index} className="tag">
                                                                        {tag}
                                                                        <i onClick={() => handleRemoveTag(index)}>X</i>
                                                                    </div>
                                                                ))}
                                                                {tags.length < 4 && (
                                                                    <Form.Control
                                                                        value={inputValue}
                                                                        onChange={(e) => setInputValue(e.target.value)}
                                                                        onKeyDown={handleKeyDown}
                                                                        placeholder="Digite as Palavras-chaves"
                                                                    />
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={4} lg={12}>
                                                            <Form.Label className='Label'>Descrição</Form.Label>
                                                            <Form.Label className='Label'>
                                                                <Form.Control className='Inputs' type="text" placeholder="Digite aqui sua descrição" value={pagina["seo_descricao"]} onChange={(e) => { handlePagina(e.target.value, "seo_descricao") }} />
                                                            </Form.Label>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='text-right'>
                                        <Form.Group className="FormNovo">
                                            <Button className='Btns ButtonSecondary'>Voltar</Button>
                                            <Button className='Btns ButtonPrimaryLight' onClick={postData} ref={buttonRef} style={{ position: 'relative' }}>
                                                <ButtonLoadingRing sending={sending} />
                                                Salvar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ReactTooltip id="my-tooltip-diferenciais" place="bottom" content="Adicionar mais Diferenciais" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>
        </>
    );
}
export default PaginasNovo;