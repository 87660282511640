import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMediaQuery } from 'react-responsive';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Searce from '../../components/Veiculos/Searce';
import Filter from '../../components/Veiculos/Filter';
import ItemBanner from '../../components/Banner/ItemBanner';
import ModalDelete from '../../components/Banner/ModalDelete';

//styles
import './Banners.scss';
import api from '../../services/apiIdz';
import Alerts from '../../components/Alerts';
import { useAuth } from '../../context/useAuth';

// Imganes
import logo from '../Dashboard/img/logo-idzcar.png'

function Banners() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])

    const [showDelete, setShowDelete] = useState(false);
    const [banners, setBanners] = useState([])
    const [readyFilter, setReadyFilter] = useState(false)


    const getData = async () => {
        setReadyFilter(false)
        await api.get(`/api/banners`).then(res => {
            if (res.data) {
                console.log(res.data)
                setBanners(res.data)
                setReadyFilter(true)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])



    const [deleteId, setDeleteId] = useState(null)
    const handleShowDeleteModal = (id) => {
        setDeleteId(id)
        setShowDelete(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteId(null)
        setShowDelete(false);
    };

    const handleCloseDeleteModalSuccess = () => {
        setDeleteId(null)
        setShowDelete(false);
        getData()
    }


    const navigate = useNavigate();

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        console.log(searchData)
        setSearch(searchData.toLowerCase())
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setReadyFilter(false)
        setStatus(statusData.code)
    }

    const [filtered, setFiltered] = useState([])
    const handleFiltered = (rawData) => {

       
        let tmpBannerList = rawData.filter(banner_d => {
            console.log(banner_d.titulo?.toLowerCase(), banner_d.status.toLowerCase())
            return banner_d.titulo?.toLowerCase().includes(search) && banner_d.status.toLowerCase().includes(status)
        })
        console.log(tmpBannerList)

        setFiltered(tmpBannerList)
    }

    useEffect(() => {
        //if (readyFilter) {
            console.log(banners)
            console.log(search, status.name)
            handleFiltered(banners)
        //}
    }, [search, status, readyFilter])

    const { token } = useAuth();

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Veiculos">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="nimbus:marketing" ></Icon></i>
                                <h2>Banners <br />
                                    <span>Gerencie e edite os banners em destaque.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableVeiculos'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsVeiculos'>
                                        <Filter handleStatus={handleStatus} />
                                        <Button className='Btns New' onClick={() => navigate("/banners/novo")}>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='listVeiculos'>
                                        <Row>
                                            {
                                                filtered?.map(bannerInfo => (
                                                    <ItemBanner bannerInfo={bannerInfo} token={token} handleAlert={handleAlert} onDelete={handleShowDeleteModal} />
                                                ))
                                            }

                                            <Col xs={12} sm={12} md={3} lg={3}>
                                                <div className='AddVeiculo'>
                                                    <Button onClick={() => navigate("/banners/novo")} data-tooltip-id="my-tooltip-adicionar" className='Btns Add'><Icon icon="ic:baseline-plus"></Icon></Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <ReactTooltip id="my-tooltip-delete" place="bottom" content="Excluir banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                    <ReactTooltip id="my-tooltip-adicionar" place="bottom" content="Adicionar banner" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />

                    <ModalDelete deleteId={deleteId} show={showDelete} handleClose={handleCloseDeleteModal} handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess} handleAlert={handleAlert} />
                </Container>
            </div>
        </>
    );
}
export default Banners;