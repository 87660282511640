import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import Accordion from 'react-bootstrap/Accordion';
import api from "../../../services/apiIdz";
import { useNavigate } from 'react-router-dom';

import "./ViewLead.scss";

function ViewLead({ show, currentLead, token, getLeads, closeModalView, setVisualizado }) {

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [trackerData, setTrackerData] = useState([]);
    const [proposta, setProposta] = useState({ pedido: '', produtos: [], vl_final: 'R$ 0,00' });
    const [currentPage, setCurrentPage] = useState('PÁGINAS');
    const [numPages, setNumPages] = useState(0);
    const [numBanners, setNumBanners] = useState(0);
    const [numServicos, setNumServicos] = useState(0);



    useEffect(() => {
        if (show) {
            setShowModal(true);
            if (currentLead.id) {
                console.log("currentLead", currentLead)
                //updateToVisualizado(currentLead.id);
                getTracker(currentLead.id);
                if(currentLead.tipo === 3){
                    getPropostaEstetica(currentLead.id);
                }else{
                    getProposta(currentLead.id);
                }


                if (currentLead.status < 1) {
                    setVisualizado(currentLead.id)
                }
            }

        }
    }, [show, currentLead]);

    const handleClose = () => {
        setShowModal(false);
        closeModalView()
    };


    const getTracker = async (id) => {
        try {
            const res = await api.get(`/api/navigation-tracker?lead=${id}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY
                }
            });
            console.log("navigation:", res.data)
            setTrackerData(res.data);
        } catch (error) {
            console.error("Error fetching tracker:", error);
        }
    };

    const getProposta = async (id) => {
       
        try {
           
            const response = await api.get(`/api/orcamento-blindagem?lead=${id}&two_factor=1021`);
            if (response.data.length > 0) {
                const data = response.data[0];

                if (data.orcamento) {
                    console.log("data.orcamento: ", data.orcamento)
                    setProposta(data.orcamento);
                } else {
                    console.error('Dados do pedido ou da melhor escolha não estão disponíveis.');
                }
            } else {
                console.error('Nenhum dado retornado pela API.');
            }
        } catch (error) {
            console.error("Error fetching proposal:", error);
        }
    };

    const getPropostaEstetica = async (id) => {
       console.log("passou aqui")
        try {
           
            const response = await api.get(`/api/orcamento-estetica?lead=${id}&two_factor=1021`);
            if (response.data.orcamento.marcas.length > 0) {
                const data = response.data.orcamento.marcas[0];
                
                if (data) {
                    setProposta(data);
                } else {
                    console.error('Dados do pedido ou da melhor escolha não estão disponíveis.');
                }
            } else {
                console.error('Nenhum dado retornado pela API.');
            }
        } catch (error) {
            console.error("Error fetching proposal:", error);
        }
    };

    useEffect(() => {
        const pagesSet = new Set();

        trackerData.forEach(item => {
            const pages = item.paginas;
            if (typeof pages != "string") {
                pages?.forEach(page => {
                    pagesSet.add(page);
                });
            }
        });

        const pagesCount = pagesSet.size;

        setNumPages(pagesCount);

        const bannersCount = trackerData[0]?.banners ? 1 : 0;
        setNumBanners(bannersCount);

        const servicosCount = 0;
        setNumServicos(servicosCount);
    }, [trackerData]);

    const formatNumber = (number) => {
        return number < 10 ? `0${number}` : number;
    };

    if (!currentLead) return null;


    // Gerar Proposta


    const handleGerarProposta = () => {
        const dadosProposta = {
            nome: currentLead.nome,
            email: currentLead.email,
            telefone: currentLead.telefone,
            marcaId: proposta.marcaId,
            modeloId: proposta.modeloId,
            produtos: proposta.produtos,
            vl_final: proposta.vl_final,
        };

        navigate('/proposta/novo', { state: { dadosProposta } });
    };


    return (
        <Modal show={showModal} className="ModalLead">
            <Modal.Header>
                <Modal.Title><Icon icon="material-symbols:dashboard-customize"></Icon> Você tem um novo Lead!<span>novo lead</span></Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <i className='flutu'><Icon icon="mdi:bell"></Icon></i>
                <Form>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <div className='boxModalLead'>
                            
                                <h4>Contato</h4>
                                {currentLead.tipo === 1 &&
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.veiculo_interesse} disabled />
                                </Form.Group>
                                }
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.nome} disabled />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.email} disabled />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                    <Form.Control type="text" value={currentLead.telefone} disabled />
                                </Form.Group>
                                {currentLead.tipo === 1 || currentLead.tipo === 2  &&
                                <Form.Group className="mb-1">
                                    <Form.Label></Form.Label>
                                    <textarea placeholder="Proposta:" disabled
                                        value={
                                            currentLead.tipo === 1
                                                ? currentLead.proposta
                                                : currentLead.tipo === 4
                                                    ? currentLead.assunto
                                                    : "Interesse em blindar " + proposta.marca + " " + proposta.modelo
                                        }
                                    ></textarea>

                                </Form.Group>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className='navegacao'>
                            <div className='boxModalLead'>
                                <h4>Navegação</h4>
                                <ul className="Monitoramento origem">
                                    <span>ORIGEM:</span>
                                    <ul>
                                        <li>
                                            {trackerData[0]?.origem.includes("localhost") ? "Direto" :
                                                (trackerData[0]?.origem.includes("1021motors.com.br/detalhe/") ?
                                                    trackerData[0].origem.split("1021motors.com.br/detalhe/")[1] :
                                                    (trackerData[0]?.origem.includes("gad_source") ? "Google" :
                                                        (trackerData[0]?.origem.includes("1021motors.com.br") && trackerData[0]?.origem.includes("gad_source") ? "Google" :
                                                            trackerData[0]?.origem)))
                                            }
                                        </li>
                                    </ul>
                                </ul>
                                <Button className='btnFilter btnStart activo' onClick={() => setCurrentPage('PÁGINAS')}>
                                    PÁGINAS <span>{formatNumber(numPages)}</span>
                                </Button>
                                <Button className='btnFilter' onClick={() => setCurrentPage('BANNERS')}>
                                    BANNERS <span>{formatNumber(numBanners)}</span>
                                </Button>
                                <Button className='btnFilter btnEnd' onClick={() => setCurrentPage('SERVIÇOS')} disabled={numServicos === 0}>
                                    SERVIÇOS {numServicos === 0 ? null : <span>{formatNumber(numServicos)}</span>}
                                </Button>
                                {currentPage === 'PÁGINAS' && (
                                    <ul className="Monitoramento color">
                                        <span>Páginas Acessadas:</span>
                                        <ul>

                                            {trackerData.reduce((uniquePages, item) => {
                                                console.log("uniquePages", uniquePages)
                                                console.log("Paginas", item)
                                                const pages = item.paginas;
                                                if (typeof pages != "string") {
                                                    pages.forEach(pagina => {
                                                        const trimmedPagina = pagina.trim();
                                                        if (!uniquePages.includes(trimmedPagina)) {
                                                            uniquePages.push(trimmedPagina);
                                                        }
                                                    });
                                                }
                                                return uniquePages;
                                            }, []).map((pagina, index) => (<>
                                                <li key={index}>{pagina}</li><li className='setas'><Icon icon='mingcute:right-line' /></li> </>
                                            ))}
                                        </ul>
                                    </ul>
                                )}
                                {currentPage === 'BANNERS' && trackerData[0]?.banners && (
                                    <>
                                        {trackerData[0]?.banners && (
                                            <ul className="Monitoramento color">
                                                <span> BANNERS CLICADOS:</span>
                                                <ul>
                                                    <li>{trackerData[0].banners}</li><li className='setas'><Icon icon='mingcute:right-line' /></li>
                                                </ul>
                                            </ul>
                                        )}
                                    </>
                                )}
                                {currentPage === 'SERVIÇOS' && (

                                    <ul className="Monitoramento color">
                                        <span> Serviços Visitados:</span>
                                        <ul>
                                            <li></li><li className='setas'><Icon icon='mingcute:right-line' /></li>
                                        </ul>
                                    </ul>
                                )}
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            {currentLead.tipo === 1 &&
                                <>
                                    <div className='boxModalLead end'>
                                        <h4>Carro de entrada</h4>
                                        <Form.Group className="mb-2">
                                            <Row>
                                                <Col sm={4}>
                                                    <Form.Label>MODELO</Form.Label>
                                                    <Form.Control type="text" value={currentLead.veiculo_entrada} disabled />
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Label>KM</Form.Label>
                                                    <Form.Control type="text" value={currentLead.km} disabled />
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Label>ALGUNS DETALHES</Form.Label>
                                                    <Form.Control type="text" value={currentLead.detalhe} disabled />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </div>
                                </>
                            }
                            {currentLead.tipo === 2 &&
                                <>
                                    <div className='boxModalLead end'>
                                        <h4>interesse Blindagem</h4>
                                        <h2><span>Valor Final:</span>{proposta.vl_final}</h2>
                                        <ul className='boxResumo'>
                                            {proposta.produtos?.map((produto, index) => (
                                                <li key={index}>
                                                    <ul>
                                                        <li>{produto.produto}</li>
                                                        <li><span>{produto.preco}</span></li>
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            }
                            {currentLead.tipo === 3 &&
                                <>
                                    <div className='boxModalLead end'>
                                        <h4>interesse Estética</h4>
                                        <h2><span>Valor Final:</span>{proposta.vl_final}</h2>
                                        <ul className='boxResumo'>
                                            {proposta.produtos?.map((produto, index) => (
                                                <li key={index} style={{ width: proposta.produtos.length === 1 ? '100%' : 'auto' }}>
                                                    <ul>
                                                        <li>{produto.produto}</li>
                                                        <li><span>{produto.preco}</span></li>
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            }
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Group className="text-center">
                                <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                    Fechar
                                </Button>
                                <Button 
                                    variant="primary" 
                                    className="Btns ButtonPrimaryLight" 
                                    onClick={handleGerarProposta}  // Adiciona essa função
                                >
                                    Gerar Proposta
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ViewLead;