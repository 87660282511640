import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';

import { useAuth } from "../../../context/useAuth";
import api from "../../../services/api";
import { limitarString } from '../../../utils/formatFunctions';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alerts from "../../Alerts";

import 'react-tooltip/dist/react-tooltip.css'

function DepoimentosList({ paginas,showModalPaginasDelete }) {

    const navigate = useNavigate()


    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };



    function dateToLocaleStringCustom(date) {
        const options = {
            day: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return date.toLocaleString('pt-BR', options);
    }

    return (
        <>
            <div className="UsuariosList">
                <div className="card">
                    <DataTable
                        value={paginas}
                        paginatorClassName={"paginator"}
                        paginatorTemplate={paginatorTemplate}
                        sortField="createdAt"
                        sortOrder={-1}
                        paginator
                        rows={12}>

                        <Column field="titulo"
                            header="NOME DA PÁGINA"
                            bodyClassName="title-column"
                            style={{ width: '20%' }} >
                        </Column>

                        <Column field="data_publicacao"
                            header="CRIADA EM"
                            bodyClassName="title-column"
                            // body="08/11/2023"
                            style={{ width: '15%' }} >
                        </Column>

                        <Column field="autor"
                            header="CRIADA POR"
                            bodyClassName="title-column"
                            style={{ width: '20%' }} >
                        </Column>

                        <Column
                            field="url"
                            header="CAMINHO"
                            style={{ width: '14%' }}
                        // body=".../politica-privacid ade"
                        >
                        </Column>

                        <Column
                            field="status"
                            header="STATUS"
                            style={{ width: '14%' }}
                            bodyClassName="status-column" 
                            body={(rowData) => {
                                if (rowData.status === 0) {
                                    if (dateToLocaleStringCustom(rowData)) {
                                        return <span className="danger"><i></i> Novo</span>;
                                    } else {
                                        return <span className="warning"><i></i> Pendente</span>;
                                    }
                                } else if (rowData.status === 1) {
                                    return <span><i></i> Visualizado</span>;
                                }
                                return <span><i></i> Visualizado</span>;
                            }}>
                        </Column>

                        <Column
                            field="Actions"
                            header=""
                            style={{ width: '22%' }}
                            bodyClassName="actions-column"
                            body={(rowData) => {
                                return (
                                    <>
                                        <button className='Btns ButtonIcon' onClick={() => { showModalPaginasDelete(rowData.id) }}> <i><Icon className="icons" icon="mdi:trash" /></i></button>
                                        <button className='Btns View' onClick={()=>{navigate(`/paginas/editar/${rowData.id}`)}}>Editar <i><Icon className="icons" icon="ri:add-fill" /></i></button>
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <ReactTooltip id="my-tooltip-1" place="bottom" content="Publicar" />
            <ReactTooltip id="my-tooltip-2" place="bottom" content="Já publicado" />

        </>
    );
}
export default DepoimentosList;