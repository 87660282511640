import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import "./Especificacao.scss";


//Componentes
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';

function Especificacao({ show, id, EspecificacaoAlertas, getCategoria}) {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isEspecificacao, setIsEspecificacao] = useState(true);

    const [popupData, setPopupData] = useState({
        categoria: id,
        titulo: '',
        sku: '',
        status: isEspecificacao ? 'Ativo' : 'Desativado'

    })

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    const handleClose = () => {
        setShowModal(false);
        setPopupData("", "titulo");
        setPopupData("", "sku");
    };

    const EspecificacaoToggle = () => {
        setIsEspecificacao(!isEspecificacao);
    };

    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }

    const postEspecificacao = async () => {
        const dataToSend = {
            ...popupData,
            categoria: id
        };
       await api.post(`/api/especificacoes-blindagem`, dataToSend, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                EspecificacaoAlertas("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    EspecificacaoAlertas("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    getCategoria()
                    handleClose()
                }
            }
        })
    }

    useEffect(() => {
        console.log(popupData)
    }, [popupData])

    return (
        <>
            <Modal show={showModal} className="ModalEspecificacao">
                <Modal.Header>
                    <Modal.Title>CADASTRO DE ESPECIFICAÇÃO</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={12}>
                                <Form.Label className='Label'>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control className='Inputs' value={popupData["titulo"]} onChange={(e) => { handlePopupData(e.target.value, "titulo") }} type="text" placeholder="Título" />
                                </Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={12}>
                                <Form.Label className='Label'>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control className='Inputs' value={popupData["sku"]} onChange={(e) => { handlePopupData(e.target.value, "sku") }} type="text" placeholder="SKU" />
                                </Form.Label>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Check
                                        className='Label'
                                        type="switch"
                                        label={isEspecificacao ? "ativo" : "desativado"}
                                        name="status"
                                        onChange={(e) =>{EspecificacaoToggle(); handlePopupData(e.target.checked ? 'ativo' : 'desativado', "status")}}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="text-center">
                            <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={postEspecificacao}>
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Especificacao;
