import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import Accordion from 'react-bootstrap/Accordion';
import { MultiSelect } from "react-multi-select-component";
import { Dropdown } from 'primereact/dropdown';
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";


// Estilos
import "./Produto.scss";


// Componentes
import IdzFileUpload from '../../Veiculos/idzFileUpload';
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';
import ReaisInput from '../../../components/ReaisInput';


function Produto({ show, ProdutoAlertas, closeModalProduto, showModalProduto, produtoId, getCategoria }) {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isProduto, setIsProduto] = useState(false);
    const [isDatePickerVisible, setDatePickerVisible] = useState(true);
    const [isEscolha, setEscolhaVisible] = useState(false);
    const [isVariacao, setIsVariacao] = useState(false);

    const handleCheckboxChange = () => {
        setDatePickerVisible(!isDatePickerVisible);
    };

    const handleCheckboxEscolha = () => {
        setEscolhaVisible(!isEscolha);
        console.log("isEscolha: ", isEscolha);
    };

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])


    const variacaoToggle = () => {
        setIsVariacao(!isVariacao);
    };

    const [popupData, setPopupData] = useState({
        especificacao: null,
        titulo: '',
        garantia: '',
        descricao: "",
        preco: "",
        preco_promocional: "",
        promo_dtini: "",
        promo_dtfim: "",
        imagem: "",
        video_link: "",
        ordem: "",
        melhorescolha: isEscolha ? 1 : 0,
        status: isProduto ? 'Ativo' : 'Desativado'

    })


    const getProduto = async() => {
        await api.get(`/api/produtos/${produtoId}`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                'token': token,
            }
        }).then(res => {
            
            if (res.status === 200) {
                console.log("teste", res.data)
                setPopupData(res.data);
            }
        }).catch(error => {
            // Tratar erro, se necessário
            console.error("Erro ao buscar categorias:", error);
        });
    }

    
    useEffect(() => {
        if(produtoId){
            getProduto()
        }
    }, [produtoId])

    useEffect(() => {

    }, [popupData])

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    const clearData = () => {
        setPopupData({
            especificacao: null,
            titulo: '',
            garantia: '',
            descricao: "",
            preco: "",
            preco_promocional: "",
            promo_dtini: "",
            promo_dtfim: "",
            imagem: "",
            video_link: "",
            ordem: "",
            status: isProduto ? 'Ativo' : 'Desativado'

        })

    }

    const handleClose = () => {
        setShowModal(false);
        closeModalProduto()
        clearData()
    };

    const ProdutoToggle = () => {
        setIsProduto(!isProduto);
    };

    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }

    const handleImagem = (image) => {
        handlePopupData(image, 'imagem')
    }

    const postProduto = async () => {
        console.log("popupData", popupData.melhorescolha)
        console.log("popupData status", popupData.status)
        
        await api.put(`/api/produtos/${produtoId}`, popupData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)


            if (typeof r.data == 'string') {
                ProdutoAlertas("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    ProdutoAlertas("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    getCategoria()
                    handleClose()
                }
            }
        })
    }

    useEffect(() => {
        console.log(popupData)
    }, [popupData])

    const handlePrecoInput = (value) => {
        handlePopupData(value, "preco")
    }

    const garantia = [
        { value: 5, label: '5 anos' },
        { value: 10, label: '10 anos' }
      ];

    return (
        <>

            <Modal show={showModal} className="ModalProduto">
                <Modal.Header >
                    <Modal.Title>CADASTRO DE VARIAÇÃO</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="checkboxOne melhorescolha">
                            <Form.Check
                                className='Label'
                                type="checkbox"
                                name="melhorescolha"
                                label="Melhor escolha"
                                onChange={(e) => {
                                    handlePopupData(e.target.checked ? 1 : 0, "melhorescolha")
                                }}
                            ></Form.Check>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control className='Inputs' value={popupData["titulo"]} onChange={(e) => { handlePopupData(e.target.value, "titulo") }} type="text" placeholder="Título" />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={6}>
                            <Form.Label className='Label'>
                                <Form.Label className='Label'>
                                    <Form.Label>Garantia</Form.Label>
                                    <Dropdown value={popupData["garantia"]} onChange={(e) => handlePopupData(e.target.value, "garantia")} options={garantia} optionLabel="label" placeholder={popupData["garantia"] + " anos"} className='Dropdown' />
                                </Form.Label>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={6}>
                            <Form.Label className='Label'>
                                <Form.Label>Valor</Form.Label>
                                <ReaisInput className='Inputs' value={popupData["preco"]} handleChange={handlePrecoInput} placeholder="Valor:" />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label>Descrição</Form.Label>
                                <textarea className="" value={popupData["descricao"]} onChange={(e) => { handlePopupData(e.target.value, "descricao") }} placeholder='Descrição'></textarea>
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Group className="mb-4">
                                <Form.Check
                                    className='Label'
                                    type="switch"
                                    label={isVariacao ? "Ativo" : "Desativado"}
                                    name="status"
                                    onChange={(e) => {
                                        variacaoToggle();
                                        handlePopupData(e.target.checked ? 'Ativo' : 'Inativo', "status")
                                    }}
                                ></Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h4>Programação</h4>
                                    <i><Icon icon='ri:add-fill' /></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Col xs={12} sm={12} md={12} lg={12} className="checkboxOne">
                                        <Form.Check
                                            className='Label'
                                            type="checkbox"
                                            label="Indeterminado"
                                            onChange={handleCheckboxChange}
                                        ></Form.Check>
                                    </Col>
                                    {isDatePickerVisible && (
                                        <Col xs={12} sm={12} md={4} lg={12} className='DatePicker'>
                                            <DatePicker
                                                showIcon
                                                selected={popupData["promo_dtini"]}
                                                onChange={(date) => handlePopupData(date, "promo_dtini")}
                                                selectsStart
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <DatePicker
                                                showIcon
                                                selected={popupData["promo_dtfim"]}
                                                onChange={(date) => handlePopupData(date, "promo_dtfim")}
                                                selectsEnd
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <hr />
                                        </Col>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <h4>Links</h4>
                                    <i><Icon icon='ri:add-fill' /></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={12}>
                                            <Form.Label className='Label'>
                                                <Form.Label>link do vídeo</Form.Label>
                                                <div class="FormControl">
                                                    <Form.Control className='Inputs' value={popupData["video_link"]} onChange={(e) => { handlePopupData(e.target.value, "video_link") }} type="text" placeholder="Link de apresentação" />
                                                    <i><Icon icon="ic:baseline-link"></Icon></i>
                                                </div>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <h4>Imagens</h4>
                                    <i><Icon icon='ri:add-fill' /></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={12}>
                                            <Form.Group className='Label'>
                                                <Form.Label className='Label'>Foto ilustrativa</Form.Label>
                                                <IdzFileUpload currentFile={popupData["imagem"]} handleUpload={handleImagem} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Form.Group className="text-center">
                        <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={postProduto}>
                            Salvar
                        </Button>
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Form.Group>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default Produto;
