import React, { useState, Component, useEffect, useRef } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import { useNavigate, useParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

//components
import Navbar from "../../../components/Navbar"
import Alert from "../../../components/Alert"
import IdzFileUpload from "../../../components/Veiculos/idzFileUpload"
import ModalVariacoes from '../../../components/Estetica/Variacoes';
import ModalRegraPreco from '../../../components/Estetica/RegrasPreco';


//styles
import './ServicosNovo.scss';
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/apiIdz';
import ButtonLoadingRing from '../../../components/ButtonLoading';
import Alerts from '../../../components/Alerts';
import ModalDelete from '../../../components/Estetica/ModalDeletePopup';

function ServicosEdit() {

    const { token } = useAuth()
    const { id } = useParams()

    const [editing, setEditing] = useState(false);
    const [endpoint, setEndpoint] = useState("");

    const handleStartEditing = (id) => {
        setEditing(true)
        setSelectedId(id)
        showModalProduto()
    }
    const handleEndEditing = () => {
        setEditing(false)
        setSelectedId(null)
    }

    const [showDelete, setShowModalServicosDelete] = useState(false)

    const [selectedId, setSelectedId] = useState(null)

    const showModalServicosDelete = (id, c_end) => {
        setEndpoint(c_end)
        setShowModalServicosDelete(true);
        setSelectedId(id)
    };
    const closeModalServicosDelete = () => {
        setEndpoint('')
        setSelectedId(null)
        setShowModalServicosDelete(false);
    };

    useEffect(() => {
        console.log(selectedId)
    }, [selectedId])

    const getServico = async () => {
        try {
            const res = await api.get(`/api/servico-estetica/${id}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });

            if (res.status === 200) {
                console.log("Serviço", res.data)
                setImagem(res.data.criativo.imagem)
                setObjCampos(res.data)
            }
        } catch (error) {
            console.error('Erro ao buscar categorias:', error);
        }
    }

    const getRegraPreco = async () => {
        try {
            const res = await api.get(`/api/opcional-preco/${id}`, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });

            if (res.status === 200) {
                console.log("Serviço", res.data)
                setImagem(res.data.criativo.imagem)
                setObjCampos(res.data)
            }
        } catch (error) {
            console.error('Erro ao buscar categorias:', error);
        }
    }


    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalServicosDelete(false);
        getServico()
    }



    useEffect(() => {
        getServico()
    }, [])

    const navigate = useNavigate()

    const [showProduto, setshowProduto] = useState(false);
    const [showRegraPreco, setshowRegraPreco] = useState(false);

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    }, [isVisible])



    const [isCriativo, setIsCriativo] = useState(false)
    const toggleCheckIsCriativo = () => {
        setIsCriativo(!isCriativo)
    }


    const [categorias, setCategorias] = useState([]);

    const [objCampos, setObjCampos] = useState({
        titulo: '',
        chamada: '',
        descricao: '',
        categoria: '',
        criativo: {
            video: '',
            imagem: ''
        }
    });


    
    const handleCampos = (field, value) => {
        setObjCampos({
            ...objCampos,
            [field]: value
        })
    }
    const handleCampos2nd = (field, child, value) => {
        setObjCampos({
            ...objCampos,
            [field]: {
                ...objCampos[field],
                [child]: value
            }
        })
    }

    const [listaVariacao, setListaVariacao] = useState([])
    const [listaRegraPrecos, setRegraPrecos] = useState([])

    useEffect(() => {
        if (objCampos) {
            setListaVariacao(objCampos['opcionais'])
            setRegraPrecos(objCampos['precos'])
        }
    }, [objCampos])

    useEffect(() => {
        if (listaVariacao) {
            console.log(listaVariacao)
        }
        if (listaRegraPrecos) {
            console.log("Lista regra precos", listaRegraPrecos)
        }
    }, [listaVariacao])




    const [imagem, setImagem] = useState(null);

    useEffect(() => {
        const getCategoria = async () => {
            try {
                const res = await api.get(`/api/categoria-estetica`);
                if (res.status === 200) {
                    const categoriasFormatadas = res.data.map(categoria => ({
                        label: categoria.titulo,
                        value: categoria.id
                    }));
                    setCategorias(categoriasFormatadas);
                }
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };

        getCategoria();
    }, []);


    const handleImagemUpload = (file) => {
        setImagem(file);
    };

    useEffect(() => {
        handleCampos2nd("criativo", "imagem", imagem)
    }, [imagem])

    const putData = async () => {
        console.log("Enviando dados");

        const auxCampos = { ...objCampos };

        delete auxCampos['opcionais'];
        delete auxCampos['precos'];

        try {
            const res = await api.put(`/api/servico-estetica/${id}`, auxCampos, {
                headers: {
                    'Authorization': process.env.REACT_APP_API_KEY,
                    "Token": token
                }
            });

            if (typeof res.data === 'string') {
                handleAlert("Campos inválidos", res.data, "danger");
            } else if (res.status === 200) {
                handleAlert("Sucesso", "Os dados foram atualizados com êxito.", "success");
                // Resetar campos após o sucesso

            }
        } catch (error) {
            console.error('Erro ao enviar dados:', error);
            handleAlert("Erro", "Ocorreu um erro ao cadastrar os dados.", "danger");
        }
    };


    const showModalProduto = () => {
        setshowProduto(true);
    };
    const closeModalProduto = () => {
        setshowProduto(false);
        handleEndEditing();
    };

    const showModalRegraPreco = () => {
        setshowRegraPreco(true);
    };
    const closeModalRegraPreco = () => {
        setshowRegraPreco(false);
    };


    const [editingRegraPreco, setEditingRegraPreco] = useState(false);
    const [selectedRegraPrecoId, setSelectedRegraPrecoId] = useState(null)

    const handleStartRegraPreco = (id) => {
        setEditingRegraPreco(true)
        setSelectedRegraPrecoId(id)
        setshowRegraPreco(true)
    }
    const handleEndRegraPreco = () => {
        setEditingRegraPreco(false)
        setshowRegraPreco(null)
    }

    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <Navbar />
            <div className="Content EsteticaNovo">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='HeaderEstetica'>
                                <i><Icon icon="mdi:stars" ></Icon></i>
                                <h2>Estética<br />
                                    <span>Crie um novo serviço para o seu site.</span></h2>
                                <Alert />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='NovoEstetica'>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <h3>Adicionar novo </h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>Categoria</Form.Label>
                                                <Dropdown
                                                    value={objCampos["categoria"]}
                                                    options={categorias}
                                                    onChange={(e) => { handleCampos("categoria", e.value) }}
                                                    optionLabel="label"
                                                    placeholder="Selecione a categoria"
                                                    className='Dropdown'
                                                    filter
                                                    emptyMessage="Sem resultado"
                                                />
                                            </Col>
                                            <Col xs={4} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>Nome</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control
                                                        className='Inputs'
                                                        type="text"
                                                        placeholder="Digite o nome"
                                                        value={objCampos["titulo"]}
                                                        onChange={(e) => { handleCampos("titulo", e.target.value) }}
                                                    />
                                                </Form.Label>
                                            </Col>
                                            <Col xs={4} sm={12} md={4} lg={4}>
                                                <Form.Label className='Label'>Chamada</Form.Label>
                                                <Form.Label className='Label'>
                                                    <Form.Control
                                                        className='Inputs'
                                                        type="text"
                                                        placeholder="Digite uma chamada"
                                                        value={objCampos["chamada"]}
                                                        onChange={(e) => { handleCampos("chamada", e.target.value) }}
                                                    />
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={12}>
                                                <Form.Label className='Label'>Descrição</Form.Label>
                                                <textarea
                                                    placeholder="Descrição do Serviço"
                                                    value={objCampos["descricao"]}
                                                    onChange={(e) => { handleCampos("descricao", e.target.value) }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='mt-4'>
                                        <h3>Criativos</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={12}>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Form.Label className='Label'>
                                                <div class="FormControl">
                                                    <Form.Control
                                                        className='Inputs'
                                                        type="text"
                                                        placeholder="Link de redirecionamento"

                                                        value={objCampos?.criativo?.video}
                                                        onChange={(e) => { handleCampos2nd("criativo", "video", e.target.value) }}
                                                    />
                                                    <i><Icon icon="ic:baseline-link"></Icon></i>
                                                </div>
                                            </Form.Label>
                                        </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={12}>
                                        <Row className='mt-4 mb-4'>
                                            <Col xs={12} sm={12} md={12} lg={4}>
                                                <Form.Label className='Label'>Imagem</Form.Label>
                                                <div class="ImagesUploader">
                                                    <IdzFileUpload title='Upload de foto' currentFile={imagem} handleUpload={handleImagemUpload} />
                                                    <span>
                                                        <i><Icon className="icons" icon="ph:upload-fill" /></i>
                                                        <h6>Upload de Foto</h6>
                                                        <p>Arquivos permitidos: .jpeg, .png</p>
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4>Variações</h4>
                                                    <i><Icon icon='ri:add-fill' /></i>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='mt-4 mb-4'>
                                                        <Col xs={12} sm={12} md={6} lg={12}>
                                                            <div className='cadastroItem'>
                                                                {listaVariacao?.map((variacao) => (
                                                                    <div className='item' >
                                                                        <div className='infos'>
                                                                            <i><Icon icon='mdi:crown' /></i>
                                                                            <h3>{variacao?.titulo}</h3>
                                                                            <p>{variacao?.garantia}</p>
                                                                        </div>
                                                                        <div className='Acoes'>
                                                                            <button className='Btns ButtonIcon' onClick={() => { showModalServicosDelete(variacao?.id, "opcional-estetica") }}><Icon icon="iconamoon:close-duotone"></Icon></button>
                                                                            <button className='Btns ButtonIcon' onClick={() => { handleStartEditing(variacao?.id) }}><Icon icon="ri:edit-fill"></Icon></button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className='addVariacao'>
                                                                    <Button className='Btns Add' onClick={() => { showModalProduto() }}><Icon icon="ic:baseline-plus"></Icon></Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4>Regras de preço</h4>
                                                    <i><Icon icon='ri:add-fill' /></i>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='mt-4 mb-4'>
                                                        <Col xs={12} sm={12} md={6} lg={12}>
                                                            <div className='cadastroItem'>
                                                                {
                                                                    listaRegraPrecos?.map((preco) => (
                                                                        <div className='item' >
                                                                            <div className='infos'>
                                                                                <i><Icon icon='mdi:crown' /></i>
                                                                                <span>{preco.opcional}</span>
                                                                                <h3>{preco.tipo_veiculo}</h3>
                                                                                <h4>{preco.preco}</h4>
                                                                            </div>
                                                                            <div className='Acoes'>
                                                                                <button className='Btns ButtonIcon' onClick={() => { showModalServicosDelete(preco?.id, "opcional-preco") }}><Icon icon="iconamoon:close-duotone"></Icon></button>
                                                                                <button className='Btns ButtonIcon' onClick={() => { handleStartRegraPreco(preco?.id) }}><Icon icon="ri:edit-fill"></Icon></button>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }

                                                                <div className='addVariacao'>
                                                                    <Button className='Btns Add' onClick={() => { showModalRegraPreco() }}><Icon icon="ic:baseline-plus"></Icon></Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs={12} sm={12} md={12} lg={12} className='text-right'>
                                        <Form.Group className="FormNovo">
                                            <Button className='Btns ButtonSecondary'>Voltar</Button>
                                            <Button onClick={putData} className='Btns ButtonPrimaryLight' style={{ position: 'relative' }}>
                                                <ButtonLoadingRing />
                                                Salvar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ReactTooltip id="my-tooltip-diferenciais" place="bottom" content="Adicionar mais Diferenciais" style={{ backgroundColor: "#FFFFFF", color: "#000000" }} />
                </Container>
            </div>

            <ModalDelete
                deleteId={selectedId}
                show={showDelete}
                endpoint={endpoint}
                handleClose={closeModalServicosDelete}
                handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                handleAlert={handleAlert}
            />
            <ModalVariacoes editing={editing} selectedId={selectedId} show={showProduto} servicoId={id} showModalProduto={showModalProduto} getServico={getServico} closeModalProduto={closeModalProduto} ProdutoAlertas={handleAlert} />
            <ModalRegraPreco editingRegraPreco={editingRegraPreco} selectedRegraPrecoId={selectedRegraPrecoId} show={showRegraPreco} servicoId={id} getServico={getServico} getRegraPreco={getRegraPreco} objCampos={objCampos} showModalRegraPreco={showModalRegraPreco} handleEndRegraPreco={handleEndRegraPreco} closeModalRegraPreco={closeModalRegraPreco} RegraPrecoAlertas={handleAlert} />
        </>
    );
}
export default ServicosEdit;