import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { MultiSelect } from "react-multi-select-component";
import { Dropdown } from 'primereact/dropdown';
import "./Add.scss";
import { useAuth } from "../../../context/useAuth";
import api from "../../../services/apiIdz";

function AddLead({ show, handleAlert, veicleList,getLeads }) {

    const { token } = useAuth()

    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState("");

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showVeiculosField, setShowVeiculosField] = useState(false);
    const [selectedVeiculo, setSelectedVeiculo] = useState({});
    const [veicleOptions, setVeicleOptions] = useState([])

    useEffect(() => {
        if (veicleList) {
            let aux_veicles = []
            veicleList.forEach((item) => {
                aux_veicles.push({ label: item.titulo, value: item.id })
            })
            console.log("veicles in lead:", veicleList)
            console.log("veicles to dropdown:", aux_veicles)
            setVeicleOptions(aux_veicles)
        }
    }, [veicleList])

    const [configData, setConfigData] = useState({
        veiculo:{
            troca: 0
        }
      })
    const [wrongData, setWrongData] = useState({
        modal_add: {
            fields: {
                email: false,
                nome: false,
                categoria: false,
                telefone: false,
                veiculo_id: false
            },
            allow_to_send: true,
            is_veiculo: false,
        },
    })

    const handleInputValue = (value, field) => {
        setConfigData({
            ...configData,
            [field]: value,
        })
    }
    const handleInputValueChild1 = (value, father_field, child_field) => {
        setConfigData({
            ...configData,
            [father_field]: {
                ...configData[father_field],
                [child_field]: value,
            }
        })
    }

    const handleClose = () => {
        setShowModal(false);
    };


    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.value);
        handleInputValue(e.value, "categoria")
        if (e.value === 1) {
            setShowVeiculosField(true);
        } else {
            setShowVeiculosField(false);

            const auxConfigData = { ...configData }
            delete auxConfigData["veiculo_id"]
            setConfigData(auxConfigData)

        }
    };

    const placeholdersCategoria = [
        { label: 'Escolha uma categoria', value: null },
        { label: 'Veiculos', value: 1 },
        { label: 'Blindagem', value: 2 },
        { label: 'Estetica', value: 3 },
    ];

    const postLead = async () => {
        console.log("Enviando leads")
        await api.post(`/api/leads`, configData, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    setShowModal(false)
                    getLeads()
                }
            }
        })
    }

    const handleCheckMandatoryFields = (phase, method) => {

        console.log(phase, wrongData)
        const phaseData = wrongData[phase];

        if (!phaseData) {
            console.error(`Phase '${phase}' não encontrada em wrongData`);
            return;
        }

        const fields = phaseData.fields;

        let allAreFalse = false;
        let fieldSize = Object.keys(wrongData[phase].fields).length
        let currentAmountOfFalse = 0;

        Object.keys(wrongData[phase].fields).forEach((key, index) => {

            const field = fields[key];

            console.log(key, field)
            console.log(`[configData[${key}]]:${configData[key]}`, typeof configData[key] == 'undefined', configData[key] == '')

            if (typeof configData[key] == 'undefined' || configData[key] == '') {

                setWrongData(prevState => ({
                    ...prevState,
                    [phase]: {
                        ...prevState[phase],
                        fields: {
                            ...prevState[phase].fields,
                            [key]: true
                        }
                    }
                }
                ))

            } else {

                setWrongData(prevState => ({
                    ...prevState,
                    [phase]: {
                        ...prevState[phase],
                        fields: {
                            ...prevState[phase].fields,
                            [key]: false
                        }
                    }
                }
                ))

                currentAmountOfFalse++;

            }
        })

        if (wrongData[phase].is_veiculo) {
            console.log("É VEÍCULO")
            console.log(currentAmountOfFalse, fieldSize)

            if (currentAmountOfFalse === fieldSize) {
                console.log(wrongData[phase])
                //Phases(wrongData[phase].next)
                postLead()
            } else {
                handleAlert('Informações Insuficientes', `Favor verificar os campos obrigatórios`, 'danger')
            }
        } else {
            console.log("NÃO É VEÍCULO")
            console.log(currentAmountOfFalse, fieldSize - 1)
            if (currentAmountOfFalse === fieldSize - 1) {
                console.log(wrongData[phase])
                postLead()
            } else {
                handleAlert('Informações Insuficientes', `Favor verificar os campos obrigatórios`, 'danger')
            }
        }


    }



    useEffect(() => {

        if (selectedCategory == 1) {
            setWrongData({
                ...wrongData,
                modal_add: {
                    ...wrongData.modal_add,
                    is_veiculo: true,
                    fields: {
                        ...wrongData.modal_add.fields,
                        veiculo_id: false,
                    }
                }
            })

        } else {
            setWrongData({
                ...wrongData,
                modal_add: {
                    ...wrongData.modal_add,
                    is_veiculo: false,
                    fields: {
                        ...wrongData.modal_add.fields,
                        veiculo_id: false,
                    }
                }
            })

        }
    }, [selectedCategory])




    /* 
    {
        "email":"proposta@idzcar.com.br",
        "nome": "Lead Zero um",
        "telefone":"(11) 9 9999-9999",
        "veiculo_id": 1,
        "veiculo": 
        {
            "troca": 1,
            "marca_id": 1,
            "modelo_id": 1,
            "km": "125.000,00",
            "detalhe":"Falta 20 mil para quitar"
        },
        "proposta": "Gostaria de adquirir esse veículo dando 30% de entrada + meu carro e financiar o resto em 12x"
    }
    */



    // await api.post("/api/leads", configData, {
    //     headers: {
    //         "Token": token,
    //         "Authorization": process.env.REACT_APP_API_KEY
    //     }
    // }).then(res => {
    //     if (res) {
    //         console.log(res.data)
    //         // getUserList()
    //         // handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
    //         // handleClose()
    //     }
    // })




    useEffect(() => {
        console.log(configData)
    }, [configData])

    const handleSelectedVeiculo = (value) => {
        console.log(`[veicle id]:`, value)
        //setSelectedVeiculo(value)
        handleInputValue(value, "veiculo_id")
    }

    return (
        <>

            <Modal show={showModal} >
                <Modal.Header >
                    <Modal.Title>Cadastro de Lead</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>NOME</Form.Label>
                            <Form.Control type="text" value={configData["nome"]} onChange={(e) => { handleInputValue(e.target.value, "nome") }} className={wrongData["modal_add"]["fields"]["nome"] && !configData["nome"] ? "wrongFieldLead" : ""} placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>EMAIL</Form.Label>
                            <Form.Control type="text" value={configData["email"]} onChange={(e) => { handleInputValue(e.target.value, "email") }} className={wrongData["modal_add"]["fields"]["email"] && !configData["email"] ? "wrongFieldLead" : ""} placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>TELEFONE</Form.Label>
                            <Form.Control type="text" value={configData["telefone"]} onChange={(e) => { handleInputValue(e.target.value, "telefone") }} className={wrongData["modal_add"]["fields"]["telefone"] && !configData["telefone"] ? "wrongFieldLead" : ""} placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='Label'>QUAL CATEGORIA?</Form.Label>
                            <div className={wrongData["modal_add"]["fields"]["tipo"] && !configData["categoria"] || !selectedCategory ? "wrongFieldLead" : ""}>
                                <Dropdown
                                    className='Dropdown'
                                    options={placeholdersCategoria}
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    placeholder="Escolha uma categoria"
                                />
                            </div>
                        </Form.Group>
                        {showVeiculosField && (
                            <>
                            <Form.Group className="mb-3">
                                <Form.Label className='Label'>QUAL VEÍCULO?</Form.Label>
                                <div className={wrongData["modal_add"]["fields"]["veiculo_id"] ? "wrongFieldLead" : ""}>
                                    <Dropdown
                                        className='Dropdown'
                                        options={veicleOptions}
                                        value={configData["veiculo_id"]}
                                        onChange={(e) => { handleSelectedVeiculo(e.value) }}
                                        placeholder="Escolha um veículo"
                                    />
                                </div>

                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className='Label'>Proposta</Form.Label>
                                <textarea value={configData["proposta"]} onChange={(e) => { handleInputValue(e.target.value, "proposta") }} className={wrongData["modal_add"]["fields"]["proposta"] && !configData["proposta"] ? "wrongFieldLead" : ""} placeholder=""></textarea>
                            </Form.Group>
                            </>
                        )}
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={() => { handleCheckMandatoryFields("modal_add") }} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddLead;
