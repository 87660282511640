import React from 'react';
import './Filter.scss';
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';


function FilterArticles({ onFilterChange,handleStatus }) {

    const [selectedFilter, setSelectedFilter] = useState({ name: 'Todos', code: '' });
 
    const status = [
        { name: 'Todos', code: '' },
        { name: 'Ativos', code: 'ativo' },
        { name: 'Inativos', code: 'inativo' },
        { name: 'Novos', code: 'pending' },
    ];

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        handleStatus(filter);
    };

    return (
        <div className='filterLeads'>
            <Dropdown
                value={selectedFilter}
                onChange={(e) => handleFilterChange(e.value)}
                options={status}
                optionLabel="name"
                placeholder="Selecione filtro"
                className='filtro'
            />
        </div>
    );
}

export default FilterArticles;
