import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import "./Edit.scss";
import { useAuth } from "../../../context/useAuth";
import api from "../../../services/apiIdz";

function UsuariosEditModal({ handleAlert, getUserList, showEdit, data }) {

    const [wrong_name, setWrong_name] = useState(false)
    const [wrong_email, setWrong_email] = useState(false)
    const [wrong_type, setWrong_type] = useState(false)


    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState("");

    const { token } = useAuth()

    const [nome, setNome] = useState('')
    const handleNome = (e) => {
        setNome(e.target.value)
    }
    const [email, setEmail] = useState('')
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }


    const [tipo, setTipo] = useState(null)
    const placeholdersTipo = [
        { label: 'Administrador', value: 'Administrador' },
        { label: 'Consultor', value: 'Consultor' },
        { label: 'Editor', value: 'Editor' },
        { label: 'Super Admin', value: 'Super Admin' }
    ];


    const handleClose = () => {
        setWrong_name(false)
        setWrong_email(false)
        setWrong_type(false)

        setShowModal(false);
    };
    const handleShow = () => setShowModal(true);
    const handleChange = (e) => setUrl(e.target.value);
    const handleSubmit = () => {
        handleClose();
    };

    useEffect(() => {
        if (showEdit) {
            setShowModal(true);
            if (data) {
                console.log(data)

                setNome(data.nome)
                setEmail(data.email)
                setTipo(data.tipo_usuario)

                console.log(tipo)

                let active = data.status.toLowerCase() == "ativo" || data.status == true || data.status == 1 ? true : false
                console.log(active)
                setAtivo(active)

                console.log(data.nome, data.email, data.tipo_usuario, data.status, active)
            }
        }

    }, [showEdit, data])

    const submitEdit = async () => {

        if (nome == '') {
            handleAlert("Credenciais Insuficiêntes", "Porfavor insira um nome", "danger")
            setWrong_name(true)
        } else if (email == '') {
            handleAlert("Credenciais Insuficiêntes", "Porfavor insira um email válido", "danger")
            setWrong_name(false)
            setWrong_email(true)
        } else if (tipo == '') {
            handleAlert("Credenciais Insuficiêntes", "Porfavor informe o nível de acesso", "danger")
            setWrong_name(false)
            setWrong_email(false)
            setWrong_type(true)
        } else {
            setWrong_name(false)
            setWrong_email(false)
            setWrong_type(false)
            console.log({
                "nome": nome,
                "email": email,
                "senha": "12345678",
                "tipo_usuario": tipo,
                "status": ativo
            })

            await api.put(`/api/usuarios/${data.id}`, {
                "nome": nome,
                "email": email,
                "senha": "12345678",
                "tipo_usuario": tipo,
                "status": ativo
            }, {
                headers: {
                    "Token": token,
                    "Authorization": process.env.REACT_APP_API_KEY
                }
            }).then(res => {
                if (res) {
                    console.log(res.data)
                    getUserList()
                    handleAlert("Sucesso", "Os dados foram editados com êxito.", "success")
                    handleClose()
                }
            })
        }


    }

    const [ativo, setAtivo] = useState(true);

    // Manipulador de eventos para alternar o estado e o texto
    const handleSwitchToggle = () => {
        setAtivo(!ativo);
    };


    return (
        <>

            <Modal show={showModal} className="popup edit">
                <Modal.Header >
                    <Modal.Title>Alteração de usuário</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control className={wrong_name ? "wrongField" : ""} value={nome} onChange={handleNome} type="text" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control className={wrong_email ? "wrongField" : ""} value={email} onChange={handleEmail} type="text" placeholder="" />
                        </Form.Group>
                        <Form.Label className='Label'>
                            <Form.Label>Tipo</Form.Label>
                            <div className={`dropdownWrongField ${wrong_type ? "wrongField" : ""}`}>
                                <Dropdown
                                    value={tipo}
                                    onChange={(e) => setTipo(e.value)}
                                    options={placeholdersTipo}
                                    placeholder="Selecione"
                                    className='Dropdown'
                                />
                            </div>

                        </Form.Label>
                        <Form.Check
                            checked={ativo}
                            className='Label'
                            type="switch"
                            label={ativo ? "Usuário ativo" : "Usuário desativado"}
                            onChange={handleSwitchToggle}
                        ></Form.Check>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={submitEdit} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UsuariosEditModal;
