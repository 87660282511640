import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import "./KmInput.scss"

function KmInput({ value, handleChange }) {
    const [loading, setLoading] = useState(true)
    const [valueIn, setValueIn] = useState('');

    useEffect(() => {
        if (value && loading) {
            setValueIn(value)
            setLoading(false)
        }
    }, [value])

    useEffect(() => {
        if (valueIn != '') {
            handleChange(valueIn)
        }
    }, [valueIn])

    const handleChangeIn = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^\d]/g, '');

        // Formata para o formato de reais
        inputValue = formatToKilometers(inputValue);

        setValueIn(inputValue);
    };

    const formatToKilometers = (value) => {
        // Converte para inteiro e adiciona separadores de milhar
        value = Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        value = value.replace(/\u00a0/g, '');
        return value;
    };

    return (<>
        {/* <input 
            type="text"
            value={value}
            onChange={handleChange}
        /> */}
        <Form.Control value={valueIn} onChange={(e) => { handleChangeIn(e) }} className='Inputs PriceMarginBottom' type="text" placeholder="Por quanto quer vender?" ></Form.Control >
    </>

    );
}

export default KmInput;