import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import DatePicker from 'react-datepicker';

// CSS
import "./Depoimentos.scss";
import 'react-datepicker/dist/react-datepicker.css';
import api from "../../../services/apiIdz";
import IdzFileUpload from "../../Veiculos/idzFileUpload";

function DepoimentosModalEdit({ showDepoimentos, token, handleAlert, getDepoimentos, closeModalDepoimentosEdit, selectedId }) {
    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState("");

    const [depoimento, setDepoimento] = useState({
        dt_inclusao: new Date(),
        ordem: 1,
        status: "Ativo"
    })

    useEffect(() => {
        if (showDepoimentos) {
            setShowModal(true)
        }
    }, [showDepoimentos])

    const getCurrentData = async (selectedId) => {
        await api.get(`/api/depoimentos/${selectedId}`).then(res => {
            if (res.status == 200) {
                console.log(res.data)

                let aux = res.data

                if (typeof res.data.dt_inclusao == 'string') {
                    let date = aux.dt_inclusao.split("/")
                    let convert = new Date(`${date[2]}-${date[1]}-${date[0]}`)
                    aux.dt_inclusao = convert
                }

                setDepoimento(aux)
            }
        })
    }

    useEffect(() => {
        if (selectedId) {
            console.log("SELECTED ID", selectedId)
            getCurrentData(selectedId)
        }
    }, [selectedId])


    const [ativo, setAtivo] = useState(false);

    const handleDepoimentoData = (value, field) => {
        setDepoimento({
            ...depoimento,
            [field]: value
        })
    }

    const handleClose = () => {
        setShowModal(false);
        closeModalDepoimentosEdit()
    };

    useEffect(() => {
        if (showDepoimentos) {
            setShowModal(true);
        }
    }, [showDepoimentos])

    const handleSwitchToggle = () => {
        setAtivo(!ativo);
        console.log(!ativo)
        handleDepoimentoData(!ativo == 1 ? "Ativo" : "Inativo", "status")
    };

    const handleImagem = (image) => {
        handleDepoimentoData(image, 'imagem')
    }

    const putData = async () => {
        console.log("Enviando depoimentos")
        console.log(token)
        await api.put(`/api/depoimentos/${selectedId}`, depoimento, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    getDepoimentos()
                    handleClose()
                }
            }
        })
    }
    return (
        <>

            <Modal show={showModal} className="ModalDepoimentosNew">
                <Modal.Header >
                    <Modal.Title>adicionar depoimento</Modal.Title>
                    <Icon icon="nimbus:marketing"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        value={depoimento["nome"]}
                                        onChange={(e) => { handleDepoimentoData(e.target.value, "nome") }}

                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>cargo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Escreva o cargo"
                                        value={depoimento["cargo"]}
                                        onChange={(e) => { handleDepoimentoData(e.target.value, "cargo") }}

                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={12} className="DatePicker">
                                <Form.Group className="mb-3">
                                    <Form.Label className='Label'>Data</Form.Label>
                                    <DatePicker
                                        selected={depoimento["dt_inclusao"]}
                                        onChange={date => handleDepoimentoData(date, "dt_inclusao")}
                                        selectsStart
                                        className="form-control "
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={12}>
                                <Form.Group className='Label'>
                                    <Form.Label className='Label'>Depoimento</Form.Label>
                                    <textarea
                                        className=""
                                        value={depoimento["depoimento"]}
                                        onChange={(e) => { handleDepoimentoData(e.target.value, "depoimento") }}
                                    ></textarea>

                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={12}>
                                <Form.Group className='Label'>
                                    <Form.Label className='Label'>Foto</Form.Label>
                                    <IdzFileUpload currentFile={depoimento["imagem"]} handleUpload={handleImagem} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Check
                                className='Label'
                                type="switch"
                                label={ativo ? "ativo" : "desativado"}
                                checked={ativo}
                                onChange={handleSwitchToggle}
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={putData} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DepoimentosModalEdit;
