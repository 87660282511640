import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import DatePicker from 'react-datepicker';

// CSS
import "./Destaque.scss";
import 'react-datepicker/dist/react-datepicker.css';
import api from "../../../services/apiIdz";

function DestaqueModal({ showDestaque, veiculos, token, handleAlert,getData }) {
    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState("");

    const [destaque, setDestaque] = useState({
        veiculo_id: null,
        data_inicio: new Date(),
        data_final: new Date(),
        ordem: 1,
        status: "Ativo"
    })

    useEffect(()=>{
        console.log(destaque)
    },[destaque])

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [ativo, setAtivo] = useState(false);
    const [listaVeiculo, setListaVeiculo] = useState([])


    const handleDestaqueData = (value, field) => {
        setDestaque({
            ...destaque,
            [field]: value
        })
    }


    useEffect(() => {
        if (veiculos) {
            let aux = [];
            veiculos.forEach((veiculo) => {
                aux.push({ label: veiculo.modelo, value: veiculo.id })
            })
            setListaVeiculo(aux)
        }
    }, [veiculos])

    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (showDestaque) {
            setShowModal(true);
        }
    }, [showDestaque])

    const handleSwitchToggle = () => {
        setAtivo(!ativo);
        console.log(!ativo)
        handleDestaqueData(!ativo == 1 ? "Ativo" : "Inativo", "status")
    };

    const postData = async () => {
        console.log("Enviando destaque")
        console.log(token)
        await api.post(`/api/destaques`, destaque, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)

            if (typeof r.data == 'string') {
                handleAlert("Campos inválidos", r.data, "danger")
            } else {
                if (r.status == 200) {
                    handleAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                    getData()
                }
            }
        })
    }

    const [isContinuo, setIsContinuo] = useState(false)
    const toggleCheckIsContinuo = () => {
        setIsContinuo(!isContinuo)
    }
    return (
        <>

            <Modal show={showModal} className="ModalDestaqueNew">
                <Modal.Header >
                    <Modal.Title>Novo destaque</Modal.Title>
                    <Icon icon="nimbus:marketing"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={12} className="checkbox mb-2">
                                <Form.Check
                                    className='Label'
                                    type="checkbox"
                                    label="Datas contínuas"
                                    checked={isContinuo}
                                    onChange={toggleCheckIsContinuo}
                                />
                            </Col>
                            {!isContinuo && (
                                <>
                                    <Col xs={6} sm={6} md={6} lg={6} className="DatePicker">
                                        <Form.Group className="mb-3">
                                            <Form.Label className='Label'>Data de início</Form.Label>
                                            <DatePicker
                                            selected={destaque["data_inicio"]} 
                                            onChange={date => handleDestaqueData(date, "data_inicio")} 
                                            selectsStart
                                            className="form-control " 
                                            dateFormat="dd/MM/yyyy"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} className="DatePicker">
                                        <Form.Group className="mb-3" >
                                            <Form.Label className='Label'>Data de término</Form.Label>
                                            <DatePicker
                                            selected={destaque["data_final"]} 
                                            onChange={date => handleDestaqueData(date, "data_final")} 
                                            selectsEnd
                                            className="form-control" 
                                            dateFormat="dd/MM/yyyy"
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Form.Group className="mb-4">
                            <Form.Label className='Label'>qual veículo</Form.Label>
                            <Dropdown value={destaque["veiculo_id"]} onChange={(e) => { handleDestaqueData(e.target.value, "veiculo_id") }} options={listaVeiculo} optionLabel="label" placeholder="Selecione um veículo..." className='Dropdown' />
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Check
                                className='Label'
                                type="switch"
                                label={ativo ? "ativo" : "desativado"}
                                checked={ativo}
                                onChange={handleSwitchToggle}
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" onClick={postData} className="Btns ButtonPrimaryLight">
                                Salvar
                            </Button>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                                Fechar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DestaqueModal;
