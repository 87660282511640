import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useMediaQuery } from 'react-responsive';

import Searce from '../../components/Usuarios/Searce';
import Filter from '../../components/Usuarios/Filter';

import UsuariosList from '../../components/Usuarios/UsuariosList';

//components
import Navbar from "../../components/Navbar"
import Alert from "../../components/Alert"
import Alerts from "../../components/Alerts"
import UsuariosNovoModal from '../../components/Usuarios/Novo';
import UsuariosEditModal from '../../components/Usuarios/Edit';
import ModalDelete from '../../components/Usuarios/ModalDelete';

//styles
import './Usuarios.scss';
import { useAuth } from '../../context/useAuth';
import api from '../../services/apiIdz';

//Imagens 
import logo from '../Dashboard/img/logo-idzcar.png'

function MyArticles() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');

    const [readyFilter, setReadyFilter] = useState(false)

   

    //danger: red , warning: Yellow, success: green
    const [initType, setType] = useState('');

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title);
        setDescription(description);
        setType(type);
    }

    useEffect(()=>{
        if(isVisible){
            setTimeout(() => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200);
            }, 8000);
        }
    },[isVisible])

    const { user, loading } = useAuth()
    const [userList, setUserList] = useState([]);

    const handleUserList = (rawData) =>{
        setUserList(rawData);
        setReadyFilter(true)
    }

    const getUserList = async () => {
        try {
            await api.get(`/api/usuarios`).then((res) => {

                let list = [];
                res.data.forEach((userContent) => {
                    list.push(userContent);
                });

                handleUserList(list)
                handleFiltered(list)
            })
        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        if (user) {
            getUserList();
        }
    }, [loading])


    const [showNovo, setShowModalNovo] = useState(false);
    const [showEdit, setShowModalEdit] = useState(false);
    const [dataContent, setDataContent] = useState({});

    const [search, setSearch] = useState('')
    const handleSearch = (searchData) => {
        setSearch(searchData)
    }

    const [status, setStatus] = useState('')
    const handleStatus = (statusData) => {
        setStatus(statusData.code)
    }


    const [filtered, setFiltered] = useState([])
    const handleFiltered = (rawData) => {
  
        console.log(rawData)

        let tmpUserList = rawData.filter(user_d => user_d.nome.toLowerCase().includes(search) &&  user_d.status.toLowerCase().includes(status))

        setFiltered(tmpUserList)
    }

    useEffect(() => {
        if (readyFilter) {
            console.log(userList)
            console.log(search, status.name)
            handleFiltered(userList)
        }
    }, [search, status,readyFilter])


    const [filteredArticles, setFilteredArticles] = useState(null);
    const [searchName, setSearchName] = useState('');

    const handleFilterChange = (filter, nameFilter) => {
        setFilteredArticles(filter);
        setSearchName(nameFilter);
    };

    const showModalNovo = () => {
        setShowModalNovo(!showNovo);
    };


    const handleDataContent = (data) => {
        setDataContent(data);
    };

    const showModalEdit = (rowData) => {
        setShowModalEdit(!showEdit);
        handleDataContent(rowData)
    };

    // Deletar Usuario
    const [selectedId, setSelectedId] = useState(null);
    const [showDelete, setShowModalDelete] = useState(false);

    const showModalDelete = (id) => {
        setShowModalDelete(true);
        setSelectedId(id)
    };
    const closeModalDelete = () => {
        setSelectedId(null)
        setShowModalDelete(false);
    };

    const handleCloseDeleteModalSuccess = () => {
        setSelectedId(null)
        setShowModalDelete(false);
        getUserList();
    }

    const [menu, setMenu] = useState(false);

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Navbar menu={menu} handleMenuToggle={handleMenuToggle}/>
            <div className="Content Leads">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMobile ? <>
                            <div className='HeaderDashboard'>
                                <a href='/dashboard' className='logo'><img src={logo} /></a>
                                <a className='menu' onClick={handleMenuToggle}><Icon icon="jam:menu" /></a>
                            </div>
                            </>
                            : <>
                            <div className='HeaderLeads'>
                                <i><Icon icon="tabler:user-star" ></Icon></i>
                                <h2>Usúarios <br />
                                    <span>Acompanhe e gerencie informações de clientes em potencial.</span></h2>
                                <Alert />
                            </div>
                            </> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <div className='tableUsuarios'>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} >
                                    <Searce handleSearch={handleSearch} />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <div className='actionsUsuarios'>
                                        <Filter handleStatus={handleStatus} onFilterChange={handleFilterChange} />
                                        <Button onClick={showModalNovo} className='Btns New'>Novo <i><Icon icon='ri:add-fill' /></i></Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className='List'>
                                        <UsuariosList userList={filtered} filter={filteredArticles} showModalEdit={showModalEdit} showModalDelete={showModalDelete} nameFilter={searchName} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
                <UsuariosNovoModal handleAlert={handleAlert} getUserList={getUserList} showNovo={showNovo} />
                <UsuariosEditModal handleAlert={handleAlert} getUserList={getUserList} showEdit={showEdit} data={dataContent} />
                <ModalDelete
                    deleteId={selectedId}
                    show={showDelete}
                    handleClose={closeModalDelete}
                    handleCloseDeleteModalSuccess={handleCloseDeleteModalSuccess}
                    handleAlert={handleAlert}
                />
            </div>
        </>
    );
}
export default MyArticles;