import React, { useEffect } from 'react';
import './Searce.scss';
import { Icon } from '@iconify/react';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
 

function FilterArticles({handleSearch}) {

    const [searchName, setSearchName] = useState('');


    const handleSearchNameChange = (event) => {
        setSearchName(event.target.value);
    };

    useEffect(() => {
        handleSearch(searchName)
    }, [searchName])

    return (
        <div className='filterSearce'>
            <span className="p-input-icon-right">
                <i><Icon icon='lucide:search' className='filter-icon' /></i>
                <InputText
                    placeholder="Pesquisar"
                    className='filter-input'
                    value={searchName}
                    onChange={handleSearchNameChange}
                />
            </span>
        </div>
    );
}

export default FilterArticles;
