import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import Accordion from 'react-bootstrap/Accordion';
import { MultiSelect } from "react-multi-select-component";
import { Dropdown } from 'primereact/dropdown';
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";


// Componentes
import IdzFileUpload from '../../Veiculos/idzFileUpload';
import api from '../../../services/apiIdz';
import { useAuth } from '../../../context/useAuth';


function Imagem({ show, imagemAlert, idModal }) {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isDatePickerVisible, setDatePickerVisible] = useState(true);



    console.log("Id inicia", idModal)
    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])


    const [popupData, setPopupData] = useState({
        imagem: "",
        principal: 1,
        modelo: idModal

    })

    useEffect(() => {

    }, [popupData])

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show])

    const clearData = () => {
        setPopupData({
            imagem: "",
    
        })
        
    }

    const handleClose = () => {
        setShowModal(false);
        clearData()
    };



    const handlePopupData = (value, field) => {
        setPopupData({
            ...popupData,
            [field]: value
        })
    }

   

    const handleImagem = (image) => {
        handlePopupData(image, 'imagem')
    }

    const postProduto = async (idModal) => {
        const dadosParaEnviar = {
            ...popupData,
            principal: 1,
            modelo: idModal
        };
       await api.post(`/api/modelo-imagem/`, dadosParaEnviar, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY,
                "Token": token
            }
        }).then(r => {
            console.log(r)
            if (r.status == 200) {
                imagemAlert("Sucesso", "Os dados foram cadastrados com êxito.", "success")
                handleClose()
            }
        })
    }

    useEffect(() => {
        console.log(popupData)
    }, [popupData])

    const handlePrecoInput = (value) => {
        handlePopupData(value, "preco")
    }


    return (
        <>

            <Modal show={showModal} className="ModalProduto">
                <Modal.Header >
                    <Modal.Title>Cadastrar Imagem</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Group className='Label'>
                                <Form.Label className='Label'>Foto modelo</Form.Label>
                                <IdzFileUpload currentFile={popupData["imagem"]} handleUpload={handleImagem} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="text-center">
                        <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={() => postProduto(idModal)}>
                            Salvar
                        </Button>
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Form.Group>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default Imagem;
