import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import './LeadsList.scss';
import { useAuth } from "../../../context/useAuth";
import api from "../../../services/api";
import { limitarString } from '../../../utils/formatFunctions';
import { dateToLocaleStringCustom } from '../../../utils/dates';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alerts from "../../Alerts";

import Iconi7 from "./img/icon-i7.svg";
import Iconi7disable from "./img/icon-i7-disable.svg";


import 'react-tooltip/dist/react-tooltip.css'

function Tabela({ filter, nameFilter, onViewButtonClick, leads,isNew, showModalLeadsDelete }) {


    useEffect(() => {
        console.log(leads)
    }, [leads])

    const navigate = useNavigate()

    const { user, loading, token, isAdmin } = useAuth()


    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');


    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };



    function dateToLocaleStringCustom(date) {
        const options = {
            day: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return date.toLocaleString('pt-BR', options);
    }
    

    return (
        <>
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }
            <div className="LeadsList">
                <div className="card">
                    <DataTable
                        value={leads}
                        paginatorClassName={"paginator"}
                        paginatorTemplate={paginatorTemplate}
                        sortField="createdAt"
                        sortOrder={-1}
                        paginator
                        rows={12}>

                        <Column
                            field="status"
                            header="STATUS"
                            style={{ width: '14%' }}
                            bodyClassName="status-column"
                            body={(rowData) => {
                                if (rowData.status === 0) {
                                    if (isNew(rowData)) {
                                        return <span className="new"><i></i> <strong>Novo</strong></span>;
                                    } else {
                                        return <span className="waiting"><i></i> <strong>Pendente</strong></span>;
                                    }
                                } else if (rowData.status === 1) {
                                    return <span><i></i> <strong>Visualizado</strong></span>;
                                }
                                return <span><i></i> <strong>Visualizado</strong></span>;
                            }}>
                        </Column>
                        <Column field="data"
                            header="DATA"
                            style={{ width: '10%' }}
                            bodyClassName="title-column data-column">
                        </Column>

                        <Column field="hora"
                            header="HORA"
                            bodyClassName="title-column hour-column"
                            style={{ width: '10%' }} >
                        </Column>

                        <Column field="nome"
                            header="NOME"
                            bodyClassName="title-column name-column"
                            style={{ width: '20%' }} >
                        </Column>

                        <Column field="email"
                            header="EMAIL"
                            bodyClassName="title-column mail-column"
                            style={{ width: '15%' }} >
                        </Column>

                        <Column field="categoria"
                            header="CATEGORIA"
                            bodyClassName="status-categoria"
                            body={(rowData) => 
                                rowData.categoria == 1 ? (<span><i><Icon className="icons" icon="fa-solid:car" /></i><strong>SESSÃO VEÍCULOS</strong></span>):
                                rowData.categoria == 2 ? (<span><i><Icon className="icons" icon="wpf:security-checked" /></i><strong>SESSÃO BLINDAGEM</strong></span>):
                                rowData.categoria == 3 ? (<span><i><Icon className="icons" icon="mdi:stars" /></i><strong>SESSÃO ESTÉTICA</strong></span>):<></>
                            }
                            style={{ width: '15%' }} >
                        </Column>


                        <Column
                            field="Actions"
                            header=""
                            style={{ width: '30%' }}
                            bodyClassName="actions-column"
                            body={(rowData) => {
                                return (
                                    <>
                                        <button onClick={() => { showModalLeadsDelete(rowData.id) }} className='Btns ButtonIcon'> <i><Icon className="icons" icon="mdi:trash" /></i></button>
                                        <button onClick={()=>onViewButtonClick(rowData.id)} className='Btns View'><strong>Visualizar</strong> <i><Icon className="icons" icon="ri:add-fill" /></i></button>
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <ReactTooltip id="my-tooltip-1" place="bottom" content="Publicar" />
            <ReactTooltip id="my-tooltip-2" place="bottom" content="Já publicado" />
        </>
    );
}
export default Tabela;

