import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';

// Componentes
import IdzFileUpload from '../../Veiculos/idzFileUpload';

//styles
import './Categorias.scss';
import api from "../../../services/apiIdz";

function CategoriasEdit({ show, handlePopupDataEdit, popupDataEdit, putCategoria, closeModalCategoriasEdit, selectedId, token, setPopupData }) {

    
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        //if (show) {
            setShowModal(show);
        //}
    }, [show])

    const handleClose = () => {
        setShowModal(false);
        closeModalCategoriasEdit();
        handlePopupDataEdit("", "titulo");
        handlePopupDataEdit("", "descricao");
        handlePopupDataEdit(null, "categoriaVeiculos");
        setOpcionaisSelecionados([]);
        handlePopupDataEdit("", "imagem")
    };

    const handleUpload = (image) => {
        handlePopupDataEdit(image, "imagem")
    }

    // Configuracao

    const [opcionais, setOpcionais] = useState([])
    const [optionsDropdown, setOptionsDropdown] = useState([]);
    const [opcionaisSelecionados, setOpcionaisSelecionados] = useState([]);

    useEffect(() => {
        if (popupDataEdit["configuracao"]) {
            setOpcionaisSelecionados(popupDataEdit["configuracao"]);
        }
    }, [popupDataEdit["configuracao"]]);
        


    const getOpcionais = async () => {
        await api.get(`/api/modelos/categoria`, {
            headers: {
                'Authorization': process.env.REACT_APP_API_KEY
            }
        }).then(response => {       
            if (response.data) {
                console.log("[OPCIONAIS]:", response.data);
                const lista = response.data.map(opcional => ({
                    label: opcional.titulo,
                    value: opcional.id
                }));
                setOpcionais(lista);
    
                // Defina optionsDropdown apenas se não estiver definido
                if (!optionsDropdown.length) {
                    setOptionsDropdown(lista);
                }
            }
        }).catch(error => {
            console.error("Erro ao obter opcionais:", error);
            // Lidar com o erro, talvez exibindo uma mensagem de erro ao usuário
        });
    };

    useEffect(() => {
        if (opcionaisSelecionados) {
            getOpcionais(opcionaisSelecionados, "opcionais")
            
        }
    }, [opcionaisSelecionados])

    const handleDropdownChange = (event) => {
        const selectedOption = event.value;
    
        // Verificar se a opção já está presente e desabilitada
        const isOptionAlreadyDisabled = optionsDropdown.some(option =>
            option.value === selectedOption && option.disabled
        );
    
        if (!isOptionAlreadyDisabled) {
            const updatedOptions = optionsDropdown.map(option => {
                if (option.value === selectedOption) {
                    return { ...option, disabled: true };
                } else {
                    return option;
                }
            });
    
            console.log("Updated optionsDropdown:", updatedOptions);
            setOptionsDropdown(updatedOptions);
            const newOpcionaisSelecionados = [...opcionaisSelecionados, selectedOption];
            setOpcionaisSelecionados(newOpcionaisSelecionados);
    
            // Atualize o campo 'opcionais' em popupData
            handlePopupDataEdit(newOpcionaisSelecionados, "configuracao");
        }
    };

    const handleTagDelete = (tagIndex) => {
        const newTags = [...opcionaisSelecionados];
        newTags.splice(tagIndex, 1);
        setOpcionaisSelecionados(newTags);

        const deletedTag = opcionaisSelecionados[tagIndex];
        const updatedOptions = optionsDropdown.map(option =>
            option.value === deletedTag ? { ...option, disabled: false } : option
        );
        setOptionsDropdown(updatedOptions);
    };

    useEffect(() => {
        console.log("optionsDropdown:", optionsDropdown);
    }, [optionsDropdown]);




    return (
        <>

            <Modal show={showModal} className="ModalCategoria">
                <Modal.Header >
                    <Modal.Title>Editar CATEGORIA</Modal.Title>
                    <Icon icon="ri:edit-fill"></Icon>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label className='Label'>
                                <Form.Label>Nome da Categoria</Form.Label>
                                <Form.Control className='Inputs' value={popupDataEdit["titulo"]} onChange={(e) => { handlePopupDataEdit(e.target.value, "titulo") }} type="text" placeholder="Nome" />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={12}>
                            <Form.Label>Descrição</Form.Label>
                            <textarea className="" value={popupDataEdit["descricao"]} onChange={(e) => { handlePopupDataEdit(e.target.value, "descricao") }} placeholder='Descrição'></textarea>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={12}>
                            <Form.Label className='Label'>
                                <span>Selecione as categorias de veículos</span>
                                <Dropdown
                                    emptyMessage="Sem resultado"
                                    value={null}
                                    options={opcionais}
                                    onChange={handleDropdownChange}
                                    optionLabel="label"
                                    placeholder="Opcionais"
                                    className='Dropdown'
                                    filter
                                />
                            </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                             {opcionaisSelecionados.length > 0 && (
                                <div className='TagsInput'>
                                    {opcionaisSelecionados.map((id, index) => {
                                        const opcionaisSelecionadosObj = opcionais.find(opcional => opcional.value === id);
                                        if (opcionaisSelecionadosObj) {
                                            return (
                                                <span key={index}>
                                                    {opcionaisSelecionadosObj.label} 
                                                    <i onClick={() => handleTagDelete(index)}><Icon icon="iconamoon:close-duotone"></Icon></i>
                                                </span>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            )}
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={12} className="mb-4">
                            <Form.Label className='Label'>
                                <Form.Label>Foto</Form.Label>
                                <div class="ImagesUploader">
                                    <IdzFileUpload
                                        currentFile={popupDataEdit["imagem"]}
                                        handleUpload={handleUpload}
                                        title='Ilustração'
                                        subtitle='Arquivos permitidos: .jpeg, .png'
                                    />
                                </div>
                            </Form.Label>
                        </Col>
                    </Row>

                    <Form.Group className="text-center">
                        <Button variant="primary" className="Btns ButtonPrimaryLight" onClick={() => { putCategoria(selectedId);  }}>
                            Salvar
                        </Button>
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Button variant="secondary" className="Btns ButtonLight" onClick={handleClose}>
                            Fechar
                        </Button>
                    </Form.Group>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default CategoriasEdit;
